import React, { ChangeEvent, FC, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { AuthenticationService } from "../../../../services/authentication.service";
import { Admin } from "../../../../interfaces";
import {
  Alert,
  Box,
  Button,
  CssBaseline,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { AuthContext } from "../../../../utils/providers/AuthProvider";
import { useSnackbar } from "notistack";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      SOLS 24/7
    </Typography>
  );
}

const Form: FC = () => {
  const STRINGS = {
    SUCCESS_LOGIN: "Successfully logged in",
    FAILED_LOGIN: "Failed to login",
  };

  const [form, setForm] = useState<Admin>({} as Admin);
  const [alertMsg, setAlertMsg] = useState("");

  const { setRole, setRoleTitle } = useContext(AuthContext);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (e: any, input: string) => {
    e.preventDefault();
    const value = e.target.value;
    switch (input) {
      case "email":
        setForm({ ...form, email: value });
        break;
      case "password":
        setForm({ ...form, password: value });
        break;
    }
  };

  function showAdminUI() {
    history.push("/");
  }

  async function showRegularUI() {
    setAlertMsg("Only Teachers and Admins can login.");
    // await AuthenticationService.signOut();
    // await history.push('/login');
  }

  const handleSubmit = async (e: ChangeEvent) => {
    e.preventDefault();
    AuthenticationService.loginWithEmail(form)
      .then(async (myUser) => {
        myUser.user
          ?.getIdTokenResult(true)
          .then((idTokenResult) => {
            if (!!idTokenResult.claims.role) {
              setRole(idTokenResult.claims.role as string);
              if (idTokenResult.claims.roleTitle) {
                setRoleTitle(idTokenResult.claims.roleTitle as string);
              }
              enqueueSnackbar(STRINGS.SUCCESS_LOGIN, { variant: "success" });
              showAdminUI();
            } else {
              setRoleTitle("");
              setRole("");
              showRegularUI();
            }
          })
          .catch((error: any) => {
            console.error(error);
            enqueueSnackbar(error.message || STRINGS.FAILED_LOGIN, {
              variant: "error",
            });
          });
      })
      .catch((error: any) => {
        console.error(error);
        enqueueSnackbar(error.message || STRINGS.FAILED_LOGIN, {
          variant: "error",
        });
      });
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={5}
    >
      <CssBaseline />
      <Grid item xs={12} md={6}>
        <Box
          m={5}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            alt={"SOLS edu"}
            src="https://cdn.solssmart.org/static/assets/sols247-logo.svg"
            width={250}
            height={"auto"}
          />
          <Typography component="h1" variant="h5">
            Admin Login
          </Typography>

          {alertMsg !== "" ? <Alert severity="error">{alertMsg}</Alert> : null}
        </Box>

        <form noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={(e) => handleChange(e, "email")}
          />

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={(e) => handleChange(e, "password")}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={(e: any) => handleSubmit(e)}
          >
            Sign In
          </Button>
          {/* <Grid container>
            <Grid item xs>
              Forgot password?
            </Grid>

          </Grid> */}
          <Box mt={5}>
            <Copyright />
          </Box>
        </form>
      </Grid>
    </Grid>
  );
};

export default Form;

import React, { ChangeEvent, useState } from "react";
import {
  IconButton,
  Switch,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import GeneralTableComponent from "../../../../components/GeneralTableComponent";
import { Cell, SessionUser } from "../../../../interfaces";
import SessionService from "../../../../services/session.service";
import { useSnackbar } from "notistack";
import { Delete } from "@mui/icons-material";

export default function SessionUserTable(props: Props) {
  const STRINGS = {
    SUCCESS_REMOVE_USER: "Successfully Removed User",
    FAILED_REMOVE_USER: "Failed to remove user",
  };

  const cells: Cell[] = [
    { name: "User", align: "left" },
    { name: "Attendance", align: "center" },
    { name: "Actions", align: "center" },
  ];

  const { sessionId, sessionUsers, call, loading } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAttend = async (event: ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = event.target;
    console.log("event:", checked);
    try {
      await SessionService.markAttendance(name, sessionId, checked);
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const handleRemove = async (uid: string) => {
    console.log("uid", uid);
    console.log("sessionId", sessionId);
    try {
      await SessionService.deleteUserSession(uid, sessionId);
      enqueueSnackbar(STRINGS.SUCCESS_REMOVE_USER, { variant: "success" });
      call();
    } catch (e: any) {
      enqueueSnackbar(e.message || STRINGS.FAILED_REMOVE_USER, {
        variant: "error",
      });
    }
  };

  return (
    <GeneralTableComponent onRefresh={call} loading={loading}>
      <TableHead>
        <TableRow>
          {cells.map((cell) => (
            <TableCell align={cell.align} key={cell.name}>
              {cell.name}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {(rowsPerPage > 0
          ? sessionUsers.slice(
              page * rowsPerPage,
              page * rowsPerPage + rowsPerPage
            )
          : sessionUsers
        ).map(({ userId, userName, userEmail, userAttended }) => (
          <TableRow key={userId}>
            <TableCell align="left">
              <Typography variant="body1">{userName}</Typography>
              <Typography variant="caption">{userEmail}</Typography>
            </TableCell>
            <TableCell align="center">
              <Switch
                name={userId}
                onChange={handleAttend}
                defaultChecked={userAttended}
              />
            </TableCell>
            <TableCell align="center">
              <IconButton onClick={() => handleRemove(userId)}>
                <Delete />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            count={sessionUsers.length}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableRow>
      </TableFooter>
    </GeneralTableComponent>
  );
}

interface Props {
  sessionId: string;
  sessionUsers: SessionUser[];
  call: () => void;
  loading: boolean;
}

import React, { useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import BreadcrumbsComponent from '../../../components/Breadcrumbs'
import AppContainer from '../../../containers/AppContainer'
import SponsortDetailsForm from './components/SponsorDetailsForm'

const SponsorDetailsPage = () => {
	const { url } = useRouteMatch()

	return (
		<AppContainer headerTitle='Create Sponsor'>
			<BreadcrumbsComponent url={url} />
			<SponsortDetailsForm />
		</AppContainer>
	)
}

export default SponsorDetailsPage

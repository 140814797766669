import slugify from "slugify";

export const convertIdToTitle = (id: string) =>
  id
    ? id
        .toLowerCase()
        .split("-")
        .map((word) => word[0].toUpperCase() + word.substring(1))
        .join(" ")
    : id;

export const convertTitleToId = (title: string) =>
  title ? slugify(title.toLowerCase(), { trim: true }) : title;

export const formatTitle = (title: string) =>
  title
    ? title
        .toLowerCase()
        .split(" ")
        .map((word) => word[0].toUpperCase() + word.substring(1))
        .join(" ")
    : title;

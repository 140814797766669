import { Button, Chip, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import Moment from "react-moment";
import GeneralDialogComponent from "../../../components/GeneralDialogComponent";
import { Certificate } from "../../../interfaces";

const CertificateDetails = (props: Props) => {
  const STRINGS = {
    CERT_DETAILS: "Certificate Details",
    GRANT_CERT: "Grant Certificate",
    USER_NAME: "User Name",
    USER_EMAIL: "User Email",
    CERT_STATUS: "Status",
    REQUEST_AT: "Requested At",
    COURSE_GRADE: "Course Grade",
    COURSE_ID: "Course ID",
    COURSE_SCORE: "Course Score",
    LC_ID: "Live Class ID",
    LP_ID: "Live Class Parent ID",
  };

  const { onClose, certificate } = props;
  const {
    userEmail,
    userName,
    certStatus,
    createdAt,
    courseGrade,
    courseId,
    courseScore,
    lcId,
    lpId,
  } = certificate;

  return (
    <GeneralDialogComponent
      open={Boolean(certificate.certId)}
      onClose={onClose}
      title={STRINGS.CERT_DETAILS}
      actions={
        <Button variant="contained" color="success">
          {STRINGS.GRANT_CERT}
        </Button>
      }
    >
      <Stack>
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="body2">{STRINGS.CERT_STATUS}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Chip
              label={certStatus}
              color={certStatus === "pending" ? "warning" : "success"}
              variant="outlined"
              size="small"
              sx={{ textTransform: "capitalize" }}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="body2">{STRINGS.USER_NAME}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption">{userName}</Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="body2">{STRINGS.USER_EMAIL}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption">{userEmail}</Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="body2">{STRINGS.REQUEST_AT}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption">
              <Moment format="dddd, d MMM y HH:mm:ss A">{createdAt}</Moment>
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="body2">{STRINGS.COURSE_GRADE}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption">{courseGrade || "-"}</Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="body2">{STRINGS.COURSE_ID}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption">{courseId || "-"}</Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="body2">{STRINGS.COURSE_SCORE}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption">
              {courseScore?.toFixed(2) || "-"}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="body2">{STRINGS.LC_ID}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption">{lcId || "-"}</Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="body2">{STRINGS.LP_ID}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption">{lpId || "-"}</Typography>
          </Grid>
        </Grid>
      </Stack>
    </GeneralDialogComponent>
  );
};

interface Props {
  onClose: () => void;
  certificate: Certificate;
}

export default CertificateDetails;

import { RemoveRedEyeOutlined } from "@mui/icons-material";
import {
  Box,
  Chip,
  IconButton,
  Tab,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import GeneralTableComponent from "../../../components/GeneralTableComponent";
import { Cell, Certificate } from "../../../interfaces";
import { CertificateService } from "../../../services/certificate.service";
import { makeCell } from "../../../utils/makeCell";
import { BoxColumn } from "../../../utils/styledComponents";
import CertificateDetails from "./CertificateDetails";

const tableCells: Cell[] = [
  makeCell("Name", "left"),
  makeCell("Course Type", "center"),
  makeCell("Status", "center"),
  makeCell("View", "center"),
];

const CertificateList = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={tabIndex} onChange={handleChangeTab}>
          <Tab label="Course" value={0} />
          <Tab label="Live Class" value={1} />
        </Tabs>
      </Box>
      <Box display={tabIndex === 0 ? "block" : "none"}>
        <CourseCertTable />
      </Box>
      <Box display={tabIndex === 1 ? "block" : "none"}>
        <LCCertTable />
      </Box>
    </>
  );
};

const CourseCertTable = () => {
  const STRINGS = {
    COURSE: "Course",
    LIVE_CLASS: "Live Class",
  };

  const [certificates, setCertificates] = useState<Certificate[]>([]);
  const [selected, setSelected] = useState<Certificate>({} as Certificate);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(false);

  const call = async () => {
    setLoading(true);
    const certificates = await CertificateService.readAllCourseCertificates();
    setCertificates(certificates);
    console.log("course:", certificates);
    setLoading(false);
  };

  // select view details
  const handleSelect = (certId: string) => {
    const cert = certificates.find((cert) => cert.certId === certId);
    setSelected(cert || ({} as Certificate));
  };
  const handleCloseSelect = () => {
    setSelected({} as Certificate);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    call();
  }, []);

  return (
    <GeneralTableComponent onRefresh={call} loading={loading}>
      <TableHead>
        <TableRow>
          {tableCells.map(({ name, align }) => (
            <TableCell align={align} key={name}>
              {name}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {(rowsPerPage > 0
          ? certificates.slice(
              page * rowsPerPage,
              page * rowsPerPage + rowsPerPage
            )
          : certificates
        ).map(({ certId, userName, userEmail, courseId, certStatus }) => (
          <TableRow key={certId}>
            {/* name */}
            <TableCell sx={{ display: "flex", alignItems: "center" }}>
              <BoxColumn sx={{ pl: "1rem" }}>
                {userName}
                <Typography variant="caption">{userEmail}</Typography>
              </BoxColumn>
            </TableCell>

            {/* course type */}
            <TableCell align="center">
              <Chip label={courseId ? STRINGS.COURSE : STRINGS.LIVE_CLASS} />
            </TableCell>

            {/* course type */}
            <TableCell align="center">
              <Chip
                label={certStatus}
                color={certStatus === "pending" ? "warning" : "success"}
                variant="outlined"
                sx={{ textTransform: "capitalize" }}
              />
            </TableCell>

            <TableCell align="center">
              <IconButton onClick={() => handleSelect(certId)}>
                <RemoveRedEyeOutlined />
              </IconButton>
              <CertificateDetails
                onClose={handleCloseSelect}
                certificate={selected}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            count={certificates.length}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableRow>
      </TableFooter>
    </GeneralTableComponent>
  );
};

const LCCertTable = () => {
  const STRINGS = {
    COURSE: "Course",
    LIVE_CLASS: "Live Class",
  };

  const [certificates, setCertificates] = useState<Certificate[]>([]);
  const [selected, setSelected] = useState<Certificate>({} as Certificate);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(false);

  const call = async () => {
    setLoading(true);
    const lcCerts = await CertificateService.readAllLCCertificates();
    setCertificates(lcCerts);
    setLoading(false);
  };

  // select view details
  const handleSelect = (certId: string) => {
    const cert = certificates.find((cert) => cert.certId === certId);
    setSelected(cert || ({} as Certificate));
  };
  const handleCloseSelect = () => {
    setSelected({} as Certificate);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    call();
  }, []);

  return (
    <GeneralTableComponent onRefresh={call} loading={loading}>
      <TableHead>
        <TableRow>
          {tableCells.map(({ name, align }) => (
            <TableCell align={align} key={name}>
              {name}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {(rowsPerPage > 0
          ? certificates.slice(
              page * rowsPerPage,
              page * rowsPerPage + rowsPerPage
            )
          : certificates
        ).map(({ certId, userName, userEmail, courseId, certStatus }) => (
          <TableRow key={certId}>
            {/* name */}
            <TableCell sx={{ display: "flex", alignItems: "center" }}>
              <BoxColumn sx={{ pl: "1rem" }}>
                {userName}
                <Typography variant="caption">{userEmail}</Typography>
              </BoxColumn>
            </TableCell>

            {/* course type */}
            <TableCell align="center">
              <Chip label={courseId ? STRINGS.COURSE : STRINGS.LIVE_CLASS} />
            </TableCell>

            {/* course type */}
            <TableCell align="center">
              <Chip
                label={certStatus}
                color={certStatus === "pending" ? "warning" : "success"}
                variant="outlined"
                sx={{ textTransform: "capitalize" }}
              />
            </TableCell>

            <TableCell align="center">
              <IconButton onClick={() => handleSelect(certId)}>
                <RemoveRedEyeOutlined />
              </IconButton>
              <CertificateDetails
                onClose={handleCloseSelect}
                certificate={selected}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            count={certificates.length}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableRow>
      </TableFooter>
    </GeneralTableComponent>
  );
};
export default CertificateList;

import React from "react";

import {
  AddToQueue,
  Article,
  Category,
  Dvr,
  Group,
  Home,
  Logout,
  OndemandVideo,
  Paid,
  Person,
  School,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import { auth } from "../../services/firebaseApp";
import { grey } from "@mui/material/colors";
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { AuthenticationService } from "../../services/authentication.service";
import packageJSON from "../../../package.json";

const Contents = () => {
  async function handleLogout() {
    await AuthenticationService.signOut();
  }

  const list = [
    { name: "Dashboard", to: "/", icon: <Home /> },
    { name: "Live Classes", to: "/live-class", icon: <OndemandVideo /> },
    { name: "Parent Classes", to: "/parent-class", icon: <AddToQueue /> },
    { name: "Courses", to: "/course", icon: <Dvr /> },
    { name: "Categories", to: "/category", icon: <Category /> },
    { name: "Teachers", to: "/teacher", icon: <School /> },
    { name: "Users", to: "/user", icon: <Person /> },
    { name: "Groups", to: "/group", icon: <Group /> },
    { name: "Certificates", to: "/certificate", icon: <Article /> },
    { name: "Sponsors", to: "/sponsor", icon: <Paid /> },
  ];

  return (
    <Box sx={{ maxWidth: "300px", width: "250px", p: "1rem" }}>
      <List>
        {list.map((option) => (
          <Link
            to={option.to}
            style={{ textDecoration: "none" }}
            key={option.name}
          >
            <ListItem sx={styles.listItem}>
              <ListItemIcon>{option.icon}</ListItemIcon>
              <ListItemText primary={option.name} />
            </ListItem>
          </Link>
        ))}
        <ListItem sx={styles.listItem} onClick={handleLogout}>
          <ListItemIcon>
            <Logout />
          </ListItemIcon>
          <ListItemText primary={auth.currentUser ? "Log out" : ""} />
        </ListItem>
      </List>

      <Divider />
      <Typography variant={"caption"} color={grey[400]}>
        {"Build Version: " + packageJSON.version}
      </Typography>
    </Box>
  );
};

const styles = {
  listItem: {
    color: "common.black",
    "&:hover": {
      bgcolor: "grey.100",
      cursor: "pointer",
    },
  },
};

export default Contents;

import React, {useState} from "react";
import {Button, IconButton, List, ListItem, ListItemText, Tooltip, Typography,} from "@mui/material";
import GeneralDialogComponent from "../../../../../components/GeneralDialogComponent";
import {Delete, Edit, Visibility} from "@mui/icons-material";
import moment from "moment";
import {ParentLiveClass} from "../../../../../interfaces";
import ViewDraftDialog from "./ViewDraftDialog";

const DraftDialog = (props: Props) => {
  const STRINGS = {
    CANCEL: "Cancel",
    CREATE_FROM_START: "Create From Start",
    NO_DRAFT: "No drafts available.",
  };

  const {
    open,
    handleClose,
    handleReset,
    drafts,
    handleUseDraft,
    handleDeleteDraft,
  } = props;

  const [viewDraft, setViewDraft] = useState<ParentLiveClass | null>();

  const handleViewDraft = (draft: ParentLiveClass) => {
    setViewDraft(draft);
  };
  const handleCloseView = () => {
    setViewDraft(null);
  };

  return (
    <GeneralDialogComponent
      open={open}
      onClose={handleClose}
      title={"Drafts Available"}
      actions={
        <>
          <Button variant="outlined" color="inherit" onClick={handleClose}>
            {STRINGS.CANCEL}
          </Button>
          <Button variant="contained" onClick={handleReset}>
            {STRINGS.CREATE_FROM_START}
          </Button>
        </>
      }
    >
      <List>
        {drafts.length ? (
          drafts.map((draft) => (
            <ListItem
              key={draft.lpId}
              secondaryAction={
                <>
                  <Tooltip title="Use Draft">
                    <IconButton
                      edge="end"
                      onClick={() => handleUseDraft(draft.lpId)}
                    >
                      <Edit/>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <IconButton
                      edge="end"
                      onClick={() => handleDeleteDraft(draft.lpId)}
                    >
                      <Delete/>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="View">
                    <IconButton
                      edge="end"
                      onClick={() => handleViewDraft(draft)}
                    >
                      <Visibility/>
                    </IconButton>
                  </Tooltip>
                  <ViewDraftDialog
                    onClose={handleCloseView}
                    draft={viewDraft}
                  />
                </>
              }
            >
              <ListItemText
                primary={
                  <Typography variant="body1" color="textPrimary">
                    {draft.lpTitle}
                  </Typography>
                }
                secondary={`Updated At: ${moment(draft.createdAt).format(
                  "DD MMM y, HH:mm:ss A"
                )}, By: ${draft.updatedBy}`}
              />
            </ListItem>
          ))
        ) : (
          <Typography variant="body1" color="textSecondary">
            {STRINGS.NO_DRAFT}
          </Typography>
        )}
      </List>
    </GeneralDialogComponent>
  );
};

interface Props {
  open: boolean;
  handleClose: () => void;
  drafts: ParentLiveClass[];
  handleReset: () => void;
  handleUseDraft: (lcId: string) => void;
  handleDeleteDraft: (lcId: string) => void;
}

export default DraftDialog;

import React, {
  ChangeEvent,
  HTMLInputTypeAttribute,
  useEffect,
  useState,
} from "react";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import { useSnackbar } from "notistack";
import {
  Avatar,
  Box,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Image } from "@mui/icons-material";
import SponsorService from "../../../../services/sponsor.service";

import {
  EditSponsor,
  MakeTextField,
  MakeTextFields,
  Sponsor,
  SponsorErrors,
  SponsorKeys,
} from "../../../../interfaces";
import InfoComponent from "../../../../components/InfoComponent";
import RichTextEditor from "../../../../components/RichTextEditor";
import { REGEX } from "../../../../constants/regex.constant";
import { convertTitleToId } from "../../../../utils/convertString";
import VerticalDivider from "../../../../components/VerticalDivider";
import SponsorActionButtons from "./SponsorActionButtons";
import { checkTextFieldError } from "../../../../utils/checkTextField";
import cleanValues from "../../../../utils/cleanValues";

const SponsortDetailsForm = () => {
  const STRINGS = {
    MAIN_DETAILS: "Main Details",
    OTHER_DETAILS: "Other Details",
    ADD_PHOTO: "+",
    SUBMIT: "Submit",
    SUCCESS_UPDATE_SPONSOR: "Successfully updated Sponsor!",
    ERROR_UPDATE_SPONSOR: "Failed to update Sponsor!",
    IMAGE_UPLOADED: "Successfully uploaded image!",
    RICH_EDITOR_LABLE: "Sponsor HTML",
    SUCCESS_DELETE_SPONSOR: "Successfully deleted Sponsor!",
  };

  const history = useHistory();
  const { lcSupportId }: any = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [form, setForm] = useState<EditSponsor>({} as EditSponsor);
  const [errors, setErrors] = useState<SponsorErrors>({} as SponsorErrors);
  const [disableSubmit, setDisableSubmit] = useState(true);

  // rich text editor
  let [html, setHtml] = useState<string>("");
  let [htmlError, setHtmlError] = useState<boolean>(false);

  // to enable the update button onchange
  const setHtmlForm = (editorContent: string) => {
    setDisableSubmit(false);
    setHtml(editorContent);
  };

  const makeTextField = (
    label: string,
    type: HTMLInputTypeAttribute | "textarea" | "autocomplete",
    key: SponsorKeys,
    pattern: RegExp,
    required: boolean = false,
    disabled: boolean = false
  ): MakeTextField<EditSponsor> => ({
    label,
    type: type === "autocomplete" ? "autocomplete" : type,
    key,
    pattern,
    required,
    disabled: disabled,
    value:
      type === "datetime-local"
        ? // TODO: why do we need unknown here
          moment(form[key] as unknown as number).format("D MMM yyyy HH:mm:ss ")
        : form[key],
    error: errors[key],
    helperText: errors[key]
      ? required && !form[key]
        ? "This field is required"
        : `Invalid ${label} format`
      : "",
    name: key,
    multiline: type === "textarea",
    rows: 4,
  });

  const textFields: MakeTextFields<EditSponsor> = {
    lcSupportTitle: makeTextField(
      "Name",
      "text",
      "lcSupportTitle",
      REGEX.ANY,
      true
    ),
    lcSupportImage: makeTextField(
      "Sponsor Image",
      "text",
      "lcSupportImage",
      REGEX.IMAGE
    ),
    lcSupportLink: makeTextField(
      "Sponsor Link",
      "text",
      "lcSupportLink",
      REGEX.ANY
    ),
    lcSupportDesc: makeTextField(
      "Sponsor description",
      "text",
      "lcSupportDesc",
      REGEX.ANY
    ),
    lcSupportHtml: makeTextField(
      "Sponsor Html",
      "textarea",
      "lcSupportHtml",
      REGEX.ANY
    ),
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setDisableSubmit(false);
    const { value } = e.target;
    const name = e.target.name as SponsorKeys;
    setForm({ ...form, [name]: value });
  };

  const handleDelete = async () => {
    setDisableSubmit(true);
    try {
      await SponsorService.deleteSponsor(lcSupportId);

      enqueueSnackbar(STRINGS.SUCCESS_DELETE_SPONSOR, { variant: "success" });
      history.push("/sponsor");
    } catch (error) {}
  };

  const handleSubmit = async () => {
    setDisableSubmit(true);

    const massagedForm: Sponsor = cleanValues({
      ...form,
      lcSupportId: convertTitleToId(form.lcSupportTitle),
      lcSupportImage: form.lcSupportImage || "",
      status: "active",
      lcSupportHtml: html || "",
      lcSupportLink: form.lcSupportLink || "",
      lcSupportDesc: form.lcSupportDesc || "",
    });

    // revalidate form
    const hasError = Object.values(textFields).some((textField) => {
      const { name, label } = textField;
      const error: boolean = checkTextFieldError(textField, form);
      if (error) {
        setDisableSubmit(false);
        setErrors({ ...errors, [name]: error });
        enqueueSnackbar(`Invalid ${label} format`, { variant: "error" });
      }
      return error;
    });
    if (hasError) return;

    console.table(massagedForm);

    try {
      await SponsorService.updateSponsor(massagedForm, lcSupportId);
      enqueueSnackbar(STRINGS.SUCCESS_UPDATE_SPONSOR, { variant: "success" });

      history.push("/sponsor");
    } catch (error) {
      setDisableSubmit(false);
      console.error("error:", error);
      enqueueSnackbar(STRINGS.ERROR_UPDATE_SPONSOR, { variant: "error" });
    }
  };

  useEffect(() => {
    (async () => {
      const sponsor = await SponsorService.readOneSponsor(lcSupportId);
      console.log("lcSupportId: ", lcSupportId);
      console.log("sponsor: ", sponsor);

      setForm((curr) => {
        return sponsor;
      });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} lg={8}>
        {/* main data */}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ marginTop: "3rem" }}>
              {STRINGS.MAIN_DETAILS}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Box
              component="img"
              src={form.lcSupportImage}
              sx={{ maxHeight: 50 }}
            />
          </Grid>
          {Object.values(textFields)
            .slice(0, 4)
            .map((textField) => (
              <Grid item xs={12} key={textField.key}>
                <Grid container alignItems="center">
                  <Grid item xs={10}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      margin="normal"
                      onChange={handleChange}
                      InputLabelProps={{ shrink: true }}
                      {...textField}
                    />
                  </Grid>
                  <Grid item xs={2} justifySelf="flex-end">
                    <InfoComponent
                      section="sponsor"
                      textFieldName={textField.name}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ))}
          <Grid item xs={12}>
            <Grid container alignItems="center">
              <Grid item xs={10}>
                <RichTextEditor
                  fieldLabel={"Sponsor Html"}
                  html={form.lcSupportHtml}
                  setFormHtml={setHtmlForm}
                  setHtmlError={setHtmlError}
                />
              </Grid>

              <Grid item xs={2}>
                <InfoComponent
                  section="sponsor"
                  textFieldName="lcSupportHtml"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Divider sx={{ my: "2rem" }} />

        {/* submit button */}

        {/* <Box width='100%' mt='1rem' display='flex' justifyContent='flex-end'>
						<Button
							variant='contained'
							onClick={handleSubmit}
							// find if there is any errors
							disabled={Object.values(errors).find((error) => error === true) || disableSubmit}>
							{STRINGS.SUBMIT}
						</Button>
					</Box> */}
      </Grid>

      <VerticalDivider />
      <SponsorActionButtons
        disabled={disableSubmit}
        handleUpdate={handleSubmit}
        handleDelete={handleDelete}
      />
    </Grid>
  );
};

export default SponsortDetailsForm;

import React, { FC } from "react";
import { Button, Divider, Grid, Stack, Typography } from "@mui/material";
import { SideDetailsBox } from "../../../../../utils/styledComponents";
import { ParentLiveClass } from "../../../../../interfaces";

const EditParentClassButtons: FC<Props> = (props) => {
  const STRINGS = {
    ACTION_BUTTONS: "Action Buttons",
    UPDATE_CLASS: "Update Class",
    DELETE_CLASS: "Delete Class",
    BACK: "Back",
    SESSION: "Session",
    ADD_SESSION: "Add Session",
    VIEW_SESSION: "View Sessions",
    OK: "Okay",
    PLEASE_ADD_SESSION:
      "Please kindly add a new session for a freshly created live class.",
    HINT_UPDATE_CLASS: "Hint: Update the class",
  };

  const { handleUpdate, disableSubmit } = props;

  return (
    <Grid item xs={12} lg={3}>
      <Stack divider={<Divider />}>
        <SideDetailsBox>
          <Typography variant="h5" sx={{ my: "1rem" }}>
            {STRINGS.ACTION_BUTTONS}
          </Typography>
          <Stack spacing={3}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={handleUpdate}
              disabled={disableSubmit}
            >
              {STRINGS.UPDATE_CLASS}
            </Button>
            <Typography variant="caption">
              {STRINGS.HINT_UPDATE_CLASS}
            </Typography>
          </Stack>
        </SideDetailsBox>
      </Stack>
    </Grid>
  );
};

export default EditParentClassButtons;

interface Props {
  disableSubmit: boolean;
  handleUpdate: () => void;
  // handleDelete: () => void
  parentLiveClass: ParentLiveClass;
}

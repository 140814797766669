import { get, ref, update } from "firebase/database";
import { InfoField } from "../interfaces";
import { db } from "./firebaseApp";

const InfoFieldService = {
  async writeInfoField(
    section: string,
    textFieldName: string,
    body: InfoField
  ): Promise<void> {
    const infoRef = ref(db, `/infoFields/${section}/${textFieldName}`);
    await update(infoRef, body);
  },

  async readOneInfoField(
    section: string,
    textFieldName: string
  ): Promise<InfoField> {
    const infoRef = ref(db, `/infoFields/${section}/${textFieldName}`);
    return await (await get(infoRef)).val();
  },
};

export default InfoFieldService;

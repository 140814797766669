import React, { useEffect, useState } from 'react'
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { Link, useRouteMatch } from 'react-router-dom'
import { Cell, Sponsor } from '../../../interfaces'
import GeneralTableComponent from '../../../components/GeneralTableComponent'
import SponsorService from '../../../services/sponsor.service'

export default function SponsorTable() {
	const { path } = useRouteMatch()

	const [sponsors, setSponsors] = useState<Sponsor[]>([])
	const [loading, setLoading] = useState(false)

	const cells: Cell[] = [
		{ name: 'Image', align: 'left' },
		{ name: 'Name', align: 'left' },
	]

	const call = async () => {
		setLoading(true)
		const sponsors = await SponsorService.readSponsors()
		setSponsors(sponsors)
		console.log('sponsors: ', sponsors)
		setLoading(false)
	}

	useEffect(() => {
		call()
	}, [])

	return (
		<Box sx={{ mt: '1rem' }}>
			<GeneralTableComponent onRefresh={call} loading={loading}>
				<Table>
					<TableHead>
						<TableRow>
							{cells.map((cell) => (
								<TableCell sx={{ width: 300 }} align={cell.align} key={cell.name}>
									{cell.name}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{sponsors.map((sponsor) => (
							<TableRow
								key={sponsor.lcSupportId}
								hover
								component={Link}
								to={`${path}/${sponsor.lcSupportId}`}>
								<TableCell>
									<Box>
										<Box
										//  component={Link} to={`${path}/${sponsor.lcSupportId}`}
										>
											<Box component='img' src={sponsor.lcSupportImage} sx={{ maxHeight: 35 }} />
										</Box>
									</Box>
								</TableCell>
								<TableCell>
									<Box display='flex'>
										<Box display='flex' flexDirection='column' justifyContent='center'>
											<Typography
												variant='subtitle2'
												// component={Link}
												// to={`${path}/${sponsor.lcSupportId}`}
												sx={{ textDecoration: 'none' }}
												color='primary.main'>
												{sponsor.lcSupportTitle}
											</Typography>
											<Typography variant='caption' color='textSecondary'>
												{sponsor.lcSupportDesc}
											</Typography>
										</Box>
									</Box>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</GeneralTableComponent>
		</Box>
	)
}

import {Backdrop, CircularProgress} from "@mui/material";
import React from "react";

export default function BackdropComponent() {
  return (
    <Backdrop open>
      <CircularProgress color="inherit"/>
    </Backdrop>
  );
}

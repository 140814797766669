import React from "react";
import { Button } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { useRouteMatch } from "react-router-dom";

const SolsSnackbarProvider = ({ children }: any) => {
  const notistackRef = React.createRef<any>();
  const router = useRouteMatch();

  const onClickDismiss = (key: React.ReactText) => () => {
    notistackRef.current.closeSnackbar(key);
  };
  const onClickDismissAll = () => notistackRef.current.closeSnackbar();

  React.useEffect(() => {
    onClickDismissAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  return (
    <SnackbarProvider
      ref={notistackRef}
      maxSnack={3}
      autoHideDuration={30000}
      action={(key) => (
        <Button color="inherit" onClick={onClickDismiss(key)}>
          Ok
        </Button>
      )}
    >
      {children}
    </SnackbarProvider>
  );
};

export default SolsSnackbarProvider;

import moment from "moment";
import { Session } from "../interfaces";

export const stringifySessionDates = (sessions: Session[]): string => {
  return sessions
    .map((session, index) => {
      const sessionLength = sessions.length;
      const sessionDate = moment(session.sessionStartDateTs);
      const formatedDate = sessionDate.format("D MMM");
      if (index === 0) return formatedDate;
      if (index !== sessionLength - 1) return `, ${formatedDate}`;
      return ` and ${formatedDate}`;
    })
    .join("");
};

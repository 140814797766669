import {Autocomplete, Grid, ListItem, ListItemText, TextField, Typography,} from "@mui/material";
import React, {ChangeEvent, FC, HTMLInputTypeAttribute, SyntheticEvent, useEffect, useState,} from "react";
import moment from "moment";
import {REGEX} from "../../../../../constants/regex.constant";
import LiveClassService from "../../../../../services/liveclass.service";
import LpService from "../../../../../services/lp.service";
import {useAuth} from "../../../../../utils/providers/AuthProvider";
import {useHistory} from "react-router-dom";
import {AutoCompleteOption, MakeTextField, MakeTextFields,} from "../../../../../interfaces/TextField.interface";
import VerticalDivider from "../../../../../components/VerticalDivider";
import EditClassButtons from "./EditClassButtons";
import {make} from "../../../../../utils/makeAutoCompleteOption";
import {convertTitleToId} from "../../../../../utils/convertString";
import {checkTextFieldError} from "../../../../../utils/checkTextField";
import {useSnackbar} from "notistack";
import {
  EditParentClassForm,
  EditParentClassFormKeys,
  ParentLiveClass,
} from "../../../../../interfaces/ParentLiveClass.interface";
import {EditParentClassFormTemplate} from "./EditParentClassFormTemplate";
import InfoComponent from "../../../../../components/InfoComponent";
import RichtextEditor from "../../../../../components/RichTextEditor";
import cleanValues from "../../../../../utils/cleanValues";
import GeneralAvatarComponent from "../../../../../components/GeneralAvatarComponent";
import {LIVE_CLASS_STATUS} from "../../../../../constants/LIVE_CLASS_STATUS";

const EditParentClassFormComponent: FC<Props> = ({lpId}) => {
  const STRINGS = {
    RICH_EDITOR_LABLE: "Parent Live Class HTML",
    START_MUST_EARLIER: "Start Datetime must be earlier than End Datetime",
    NO_CATEGORY: "Category selected is not found or has been deleted",
  };

  let history = useHistory();
  const [form, setForm] = useState<EditParentClassForm>(
    EditParentClassFormTemplate
  );
  const {enqueueSnackbar} = useSnackbar();

  const [errors, setErrors] = useState<FormErrors>({} as FormErrors);
  const [options, setOptions] =
    useState<AutoCompleteOptions>(autoCompleteOptions);
  const {user} = useAuth();
  const [currentLpList, setCurrentLpList] = useState<ParentLiveClass>(
    {} as ParentLiveClass
  );
  const [disableSubmit, setDisableSubmit] = useState(true);

  const [html, setHtml] = useState<string>("");
  const [htmlError, setHtmlError] = useState<boolean>(false);

  const makeTextField = (
    label: string,
    type: HTMLInputTypeAttribute | "textarea" | "autocomplete",
    key: EditParentClassFormKeys,
    pattern: RegExp,
    required: boolean = false,
    disabled: boolean = false
  ): MakeTextField<EditParentClassForm> => ({
    label,
    type: type === "autocomplete" ? "autocomplete" : type,
    key,
    pattern,
    disabled,
    value: form[key],
    error: errors[key],
    helperText: errors[key]
      ? required && !form[key]
        ? "This field is required"
        : `Invalid ${label}`
      : "",
    required,
    name: key,
    multiline: type === "textarea",
    rows: 4,
  });

  const textFields: MakeTextFields<EditParentClassForm> = {
    lpTitle: makeTextField(
      "Parent Live Class Title",
      "text",
      "lpTitle",
      REGEX.ANY,
      true
    ),
    lpCategoryId: makeTextField(
      "Parent Live Class Category",
      "autocomplete",
      "lpCategoryId",
      REGEX.ANY,
      true
    ),
    lpImage: makeTextField(
      "Parent Live Class Image",
      "text",
      "lpImage",
      REGEX.IMAGE
    ),
    lpDesc: makeTextField(
      "Parent Live Class Description",
      "textarea",
      "lpDesc",
      REGEX.ANY,
      false
    ),
    lcDescHtml: makeTextField(
      "Parent Live Class HTML",
      "textarea",
      "lcDescHtml",
      REGEX.ANY
    ),
    lpStartDateTs: makeTextField(
      "Parent Live Class Start Date",
      "datetime-local",
      "lpStartDateTs",
      REGEX.ANY,
      true,
      true
    ),
    lpEndDateTs: makeTextField(
      "Live Class End Date",
      "datetime-local",
      "lpEndDateTs",
      REGEX.ANY,
      true,
      true
    ),
    createdAt: makeTextField(
      "Parent Live Class Created At",
      "datetime-local",
      "createdAt",
      REGEX.ANY,
      true,
      true
    ),
    updatedAt: makeTextField(
      "Parent Live Class Updated At",
      "datetime-local",
      "updatedAt",
      REGEX.ANY,
      true,
      true
    ),
    updatedBy: makeTextField(
      "Parent Live Class Updated By",
      "text",
      "updatedBy",
      REGEX.ANY,
      false,
      true
    ),
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const {value} = e.target;
    const name = e.target.name as EditParentClassFormKeys;
    setForm({...form, [name]: value});
    setDisableSubmit(false);
  };

  const handleAutoCompleteChange = (
    e: SyntheticEvent<Element, Event>,
    newValue: AutoCompleteOption | null,
    name: EditParentClassFormKeys
  ) => {
    e.preventDefault();

    setForm({...form, [name]: newValue?.value});
    setDisableSubmit(false);
  };

  const handleUpdate = async () => {
    const lpCategoryId = options.lpCategoryId.find(
      (category) => category.value === form.lpCategoryId
    );
    if (!lpCategoryId) {
      return enqueueSnackbar(STRINGS.NO_CATEGORY, {variant: "error"});
    }

    // validate form
    setDisableSubmit(true);
    const hasError = Object.values(textFields).some((textField) => {
      const {name, label, value} = textField;
      const error: boolean = checkTextFieldError(textField, form);
      if (error) {
        console.log(name, value);
        setErrors({...errors, [name]: error});
        enqueueSnackbar(`Invalid ${label}`, {variant: "error"});
      }
      return error;
    });

    if (hasError) return;
    if (htmlError) {
      enqueueSnackbar("Please enter HTML", {variant: "error"});
      return;
    }

    if (
      moment(form.lpStartDateTs).valueOf() > moment(form.lpEndDateTs).valueOf()
    ) {
      return enqueueSnackbar(STRINGS.START_MUST_EARLIER, {
        variant: "error",
      });
    }

    const massagedForm: ParentLiveClass = cleanValues({
      ...form,
      lpId: convertTitleToId(form.lpTitle),
      lpCategory:
        options.lpCategoryId.find(
          (category) => category.value === form.lpCategoryId
        )?.title || "",
      lpCategoryId:
        options.lpCategoryId.find(
          (category) => category.value === form.lpCategoryId
        )?.value || "",
      lpStartDateTs: (moment(form.lpStartDateTs).valueOf() as number) || "",
      lpEndDateTs: (moment(form.lpEndDateTs).valueOf() as number) || "",
      createdAt: moment(form.createdAt).valueOf(),
      updatedAt: moment().valueOf(),
      updatedBy: user?.email || "",
      lcDescHtml: html || "",
      lpStatus: currentLpList.lpStatus,
      lcIndex: currentLpList.lcIndex,
      lpIndexAsc: currentLpList.lpIndexAsc,
    });

    try {
      await LpService.updateParentLiveClass(massagedForm, currentLpList.lpId);

      history.replace(`${massagedForm.lpId}`);

      // updates the props without refreshing the page
      lpId = massagedForm.lpId;
      setCurrentLpList(massagedForm);
      setForm({
        ...form,
        lpStartDateTs: moment(massagedForm.lpStartDateTs).format(
          "YYYY-MM-DDTHH:mm"
        ),
        lpEndDateTs: moment(massagedForm.lpEndDateTs).format(
          "YYYY-MM-DDTHH:mm"
        ),
        createdAt: moment(massagedForm.createdAt).format("YYYY-MM-DDTHH:mm"),
        updatedAt: moment(massagedForm.updatedAt).format("YYYY-MM-DDTHH:mm"),
      });

      enqueueSnackbar("Parent Live Class Updated", {variant: "success"});
    } catch (error) {
      console.error("error: ", error);
    }
  };

  // const handleDelete = async () => {
  // 	try {
  // 		// await LpService.deleteLiveClass(lpId)
  // 		history.replace(`/parent-class`)
  // 	} catch (error) {
  // 		console.error('error: ', error)
  // 	}
  // }

  useEffect(() => {
    (async () => {
      const lpRes = await LpService.readOneParentLiveClass(lpId);
      const parentClassHtml = await LpService.readParentLiveClassHtml(lpId);
      // used to check if the lpId changed after the form is submitted
      setCurrentLpList(lpRes);
      setHtml(parentClassHtml?.lcDescHtml);

      setForm({
        ...lpRes,
        lpStartDateTs: moment(lpRes.lpStartDateTs).format("YYYY-MM-DDTHH:mm"),
        lpEndDateTs: moment(lpRes.lpEndDateTs).format("YYYY-MM-DDTHH:mm"),
        createdAt: moment(lpRes.createdAt).format("YYYY-MM-DDTHH:mm"),
        updatedAt: moment(lpRes.updatedAt).format("YYYY-MM-DDTHH:mm"),
        lcDescHtml: parentClassHtml?.lcDescHtml,
        updatedBy: lpRes.updatedBy || user?.email || "",
      });

      const categories = await LiveClassService.readCategories();

      setOptions({
        ...options,
        lpCategoryId: categories.map((cat) => ({
          title: cat.lcCategoryName,
          value: cat.lcCategoryId,
          subtitle: cat.lcCategoryDesc,
        })),
      });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} lg={8}>
        <Grid item xs={12}>
          <Typography variant="h5" sx={{mt: "3rem"}}>
            Main Details
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <GeneralAvatarComponent src={form.lpImage} large/>
        </Grid>
        <Grid container spacing={3}>
          {Object.values(textFields)
            .slice(0, 10)
            .map((textField) => (
              <Grid item xs={12} key={textField.key}>
                <Grid container spacing={3} alignItems="center">
                  <Grid item xs={10}>
                    {textField.type === "autocomplete" ? (
                      <Autocomplete
                        disablePortal
                        fullWidth
                        disabled={textField.disabled}
                        options={
                          options[textField.key as AutoCompleteOptionKeys]
                        }
                        getOptionLabel={(option) => option.title}
                        renderOption={(props, option) => (
                          <ListItem {...props} title={option.title}>
                            <ListItemText
                              primary={option.title}
                              secondary={option.subtitle || ""}
                            />
                          </ListItem>
                        )}
                        isOptionEqualToValue={(option) =>
                          option.value === form[textField.key]
                        }
                        value={options[
                          textField.key as AutoCompleteOptionKeys
                          ]?.find(
                          (option) => option.value === form[textField.key]
                        )}
                        onChange={(e, newValue) =>
                          handleAutoCompleteChange(e, newValue, textField.name)
                        }
                        // random key needs to be used in order to rerender the autocomplete field after async event
                        key={`autocomplete-${textField.key}-${
                          Math.random() * 1000
                        }`}
                        renderInput={(params) => (
                          <TextField
                            margin="normal"
                            {...params}
                            {...textField}
                          />
                        )}
                      />
                    ) : textField.name === "lcDescHtml" ? (
                      <RichtextEditor
                        fieldLabel="Parent Live Class HTML"
                        html={form.lcDescHtml}
                        setFormHtml={setHtml}
                        setHtmlError={setHtmlError}
                      />
                    ) : (
                      <TextField
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        onChange={handleChange}
                        InputLabelProps={{shrink: true}}
                        {...textField}
                      />
                    )}
                  </Grid>
                  <Grid item xs={2}>
                    <InfoComponent
                      section="parent-class"
                      textFieldName={textField.name}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ))}
        </Grid>
      </Grid>
      <VerticalDivider/>
      <EditClassButtons
        disableSubmit={disableSubmit}
        handleUpdate={handleUpdate}
        // handleDelete={handleDelete}
        parentLiveClass={form as ParentLiveClass}
      />
    </Grid>
  );
};

export default EditParentClassFormComponent;

interface Props {
  lpId: string;
}

interface AutoCompleteOptions {
  lpStatus: AutoCompleteOption[];
  lpCategoryId: AutoCompleteOption[];
}

type AutoCompleteOptionKeys = keyof AutoCompleteOptions;

const autoCompleteOptions: AutoCompleteOptions = {
  lpStatus: [
    make(LIVE_CLASS_STATUS.PUBLISHED),
    make(LIVE_CLASS_STATUS.UNPUBLISHED),
  ],
  lpCategoryId: [],
};

type FormErrors = {
  [F in EditParentClassFormKeys]: boolean;
};

import { Image } from "@mui/icons-material";
import { Avatar, AvatarProps } from "@mui/material";
import React from "react";

interface Props extends AvatarProps {
  large?: boolean;
}
export default function GeneralAvatarComponent(props: Props) {
  const { large } = props;
  return large ? (
    <Avatar
      variant="rounded"
      sx={{
        width: 150,
        height: 150,
        m: "2rem 1rem",
      }}
      {...props}
    >
      <Image fontSize="large" />
    </Avatar>
  ) : (
    <Avatar
      variant="rounded"
      sx={{
        width: 45,
        height: 45,
        borderRadius: "5px",
        marginRight: "1rem",
      }}
      {...props}
    >
      <Image />
    </Avatar>
  );
}

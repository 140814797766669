import {get, ref, remove, set} from "firebase/database";
import {Course, UserProductCourse} from "../interfaces";
import {db} from "./firebaseApp";
import {UserProductListCourse} from "../interfaces/Course.interface";

export const CourseService = {
  async addUserToCourse(data: UserProductCourse, uid: string) {
    const lcRef = ref(db, `/userProductList/course/${uid}/${data.courseId}`);
    return await set(lcRef, data);
  },

  async readAllCourse(): Promise<Course[]> {
    const courseRef = ref(db, `/sepContent/course/courseList/english`);
    const snapshot = await get(courseRef);
    const courses: Course[] = [];
    snapshot.forEach((course) => {
      courses.push(course.val());
    });
    return courses;
  },

  async readAllUserProductCourse(
    courseId: string
  ): Promise<UserProductListCourse[]> {
    const productRef = ref(db, `/userProductList/course`);
    const snapshot = await get(productRef);
    const users: UserProductListCourse[] = [];

    snapshot.forEach((user) => {
      if (user.child(courseId).exists()) {
        const merged = {
          ...user.child(courseId).val(),
          userId: user.key,
        };
        users.push(merged);
      }
    });

    return users;
  },

  async getUserIdPerCourse(
    courseId: string
  ): Promise<string[]> {
    const productRef = ref(db, `/userProductList/course`);
    const snapshot = await get(productRef);
    const users: string[] = [];

    snapshot.forEach((user) => {
      if (user.child(courseId).exists()) {

        users.push(user.key as string);
      }
    });

    return users;
  },

  async readOneUserAllProductCourse(uid: string): Promise<UserProductCourse[]> {
    const productRef = ref(db, `/userProductList/course/${uid}`);
    const snapshot = await get(productRef);
    const products: UserProductCourse[] = [];

    snapshot.forEach((product) => {
      products.push(product.val());
    });

    return products;
  },

  async deleteUserCourse(uid: string, courseId: string) {
    const lcRef = ref(db, `/userProductList/course/${uid}/${courseId}`);
    return await remove(lcRef);
  },
};

import {Box, Typography} from '@mui/material'
import React from 'react'

export default function FooterComponent() {
  return (
    <Box
      sx={{
        width: "100%",
        height: "5rem",
        backgroundColor: "secondary.main",
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography
        variant="caption"
        sx={{
          color: "common.white",
        }}
      >
        © 2022 SOLS 24/7. All Rights Reserved.
      </Typography>
    </Box>
  )
}

import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import PrivateRoute from "../../../layouts/Router/PrivateRoute";
import ParentLiveClassPage from "./components";
import CreateParentLiveClass from "./createParentLiveClass";
import EditParentLiveClass from "./EditParentLiveClass";
// import ParentLiveClassPage from './components'

const ParentLiveClass = () => {
  const { path } = useRouteMatch();
  console.log("first ", path);
  return (
    <Switch>
      <PrivateRoute exact path={path} component={ParentLiveClassPage} />
      <PrivateRoute
        path={`${path}/create-parent-class`}
        component={CreateParentLiveClass}
      />
      <PrivateRoute path={`${path}/:lpId`} component={EditParentLiveClass} />
    </Switch>
  );
};

export default ParentLiveClass;

import React, { ChangeEvent, HTMLInputTypeAttribute, useState } from "react";
import { Button, Grid, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import GeneralDialogComponent from "../../../components/GeneralDialogComponent";
import {
  AddSponsor,
  AddSponsorErrors,
  AddSponsorKeys,
  MakeTextField,
  MakeTextFields,
  Sponsor,
} from "../../../interfaces";
import SponsorService from "../../../services/sponsor.service";
import { REGEX } from "../../../constants/regex.constant";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { convertTitleToId } from "../../../utils/convertString";
import InfoComponent from "../../../components/InfoComponent";
import cleanValues from "../../../utils/cleanValues";
import { checkTextFieldError } from "../../../utils/checkTextField";
import GeneralAvatarComponent from "../../../components/GeneralAvatarComponent";

const AddSponsorDialog = (props: Props) => {
  const { open, handleClose, STRINGS } = props;
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [form, setForm] = useState<AddSponsor>({} as AddSponsor);
  const [errors, setErrors] = useState<AddSponsorErrors>(
    {} as AddSponsorErrors
  );
  const [disableSubmit, setDisableSubmit] = useState(true);

  const makeTextField = (
    label: string,
    type: HTMLInputTypeAttribute | "textarea" | "autocomplete",
    key: AddSponsorKeys,
    pattern: RegExp,
    required: boolean = false,
    disabled: boolean = false
  ): MakeTextField<AddSponsor> => ({
    label,
    type: type === "autocomplete" ? "autocomplete" : type,
    key,
    pattern,
    required,
    disabled: disabled,
    value:
      type === "datetime-local"
        ? // TODO: why do we need unknown here
          moment(form[key] as unknown as number).format("D MMM yyyy HH:mm:ss ")
        : form[key],
    error: errors[key],
    helperText: errors[key]
      ? required && !form[key]
        ? "This field is required"
        : `Invalid ${label} format`
      : "",
    name: key,
    multiline: type === "textarea",
    rows: 4,
  });

  const textFields: MakeTextFields<AddSponsor> = {
    lcSupportTitle: makeTextField(
      "Name",
      "text",
      "lcSupportTitle",
      REGEX.ANY,
      true
    ),
    lcSupportImage: makeTextField(
      "Sponsor Image",
      "text",
      "lcSupportImage",
      REGEX.IMAGE
    ),
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setDisableSubmit(false);
    const { required, value } = e.target;
    const name = e.target.name as AddSponsorKeys;
    setForm({ ...form, [name]: value });

    // this is to check if the text field has error
    // 1. if field is '', return TRUE if field is required
    // 2. if field has value, return TRUE if pattern is wrong
    const pattern = textFields[name].pattern;
    const error: boolean = !value
      ? required
      : !new RegExp(pattern)?.test(value);
    setErrors({ ...errors, [name]: error });
  };

  const handleSubmit = async () => {
    setDisableSubmit(true);

    // validate form
    const hasError = Object.values(textFields).some((textField) => {
      const { name, label } = textField;
      const error: boolean = checkTextFieldError(textField, form);
      if (error) {
        setDisableSubmit(false);
        setErrors({ ...errors, [name]: error });
        enqueueSnackbar(`Invalid ${label} format`, { variant: "error" });
      }
      return error;
    });

    if (hasError) return;

    const massagedForm: Sponsor = cleanValues({
      ...form,
      lcSupportId: convertTitleToId(form.lcSupportTitle),
      status: "active",
      lcSupportHtml: "",
      lcSupportLink: "",
      lcSupportDesc: "",
    });
    try {
      await SponsorService.createSponsor(massagedForm);

      // setTimeout(async () => {
      handleClose();
      enqueueSnackbar(STRINGS.SUCCESS_ADD_USER, { variant: "success" });
      history.push(`/sponsor/${massagedForm.lcSupportId}`);
      // }, 2000)
    } catch (error: any) {
      setDisableSubmit(false);
      console.error("error:", error);
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  return (
    <GeneralDialogComponent
      open={open}
      onClose={() => {
        handleClose();
        setErrors({} as AddSponsorErrors);
      }}
      title={STRINGS.ADD_SPONSOR}
      actions={
        <Button
          variant="contained"
          onClick={handleSubmit}
          // find if there is any errors
          disabled={
            Object.values(errors).find((error) => error === true) ||
            disableSubmit
          }
        >
          {STRINGS.SUBMIT}
        </Button>
      }
    >
      <GeneralAvatarComponent src={form.lcSupportImage} large />
      {Object.values(textFields).map((textField) => (
        <Grid container alignItems="center">
          <Grid item xs={10}>
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
              {...textField}
            />
          </Grid>

          <Grid item xs={2}>
            <InfoComponent section="sponsor" textFieldName={textField.name} />
          </Grid>
        </Grid>
      ))}
    </GeneralDialogComponent>
  );
};

interface Props {
  open: boolean;
  handleClose: () => void;
  STRINGS: any;
}

export default AddSponsorDialog;

import { createTheme } from "@mui/material/styles";
import { brown, grey } from "@mui/material/colors";
// When using TypeScript 4.x and above
import type {} from "@mui/x-data-grid/themeAugmentation";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#F8BE15",
      contrastText: brown["900"],
    },
    secondary: {
      main: grey[900],
      contrastText: grey["50"],
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          marginTop: "1rem",
          padding: "1rem",
          borderRadius: "1rem",
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        secondary: {
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        },
      },
    },
  },
});

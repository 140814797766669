import { useEffect, useState } from "react";
// import { EditorState } from 'react-draft-wysiwyg'

import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Box, Typography } from "@mui/material";

const RichtextEditor = (props: Props) => {
  const { html, setFormHtml, fieldLabel } = props;
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty()
  );

  useEffect(() => {
    if (html) {
      const contentBlock = htmlToDraft(html);
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const _editorState = EditorState.createWithContent(contentState);

      setEditorState(_editorState);
    }
  }, [html]);
  return (
    <Box>
      <Typography color="GrayText">{fieldLabel}</Typography>
      <Box
        sx={{
          p: 1,
          border: "1px solid grey",
          borderRadius: "4px",
          overflowY: "auto",
          maxHeight: "400px",
          minHeight: "350px",
        }}
      >
        <Editor
          placeholder="Type something here..."
          editorState={editorState}
          onEditorStateChange={(newState) => {
            setEditorState(newState);
            const contentState = newState.getCurrentContent();
            const html = draftToHtml(convertToRaw(contentState));
            setFormHtml(html);
          }}
          toolbar={{
            options: [
              "inline",
              "blockType",
              "fontSize",
              "list",
              "textAlign",
              "history",
              "embedded",
              "emoji",
              "image",
            ],
            inline: { inDropdown: true },
            list: { inDropdown: true },
            textAlign: { inDropdown: true },
            link: { inDropdown: true },
            // history: { inDropdown: true },
          }}
        />
      </Box>
    </Box>
  );
};

export default RichtextEditor;

interface Props {
  fieldLabel: string;
  html: string;
  setFormHtml: (html: string) => void;
  setHtmlError?: (error: boolean) => void;
}

import * as React from "react";
import { useContext } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Avatar,
  Box,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { theme } from "../constants/theme.constant";
import { getAuth } from "firebase/auth";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../utils/providers/AuthProvider";

export default function AppBarComponent(props: Props) {
  const { title, handleDrawerToggle } = props;
  let history = useHistory();
  const auth = getAuth();
  const user = auth.currentUser;

  const { roleTitle } = useContext(AuthContext);

  const userInfo = {
    name: user?.displayName,
    email: user?.email,
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" color={"inherit"}>
        <Box
          sx={{
            height: "4px",
            width: "100%",
            backgroundColor: theme.palette.primary.main,
          }}
        />

        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
          <Box
            component="img"
            sx={{
              width: 200,
              height: "auto",
              mr: 2,
              "&:hover": {
                cursor: "pointer",
              },
            }}
            src="https://cdn.solssmart.org/static/assets/sols247-logo.svg"
            onClick={() => history.push("/")}
          />
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {title}
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography variant="subtitle2" align="right">
              {userInfo.name}
            </Typography>
            <Typography variant="caption" align="right">
              {userInfo.email}
            </Typography>
            <Typography variant="caption" align="right">
              {roleTitle}
            </Typography>
          </Box>
          <Avatar sx={{ ml: "1rem" }}>
            {userInfo.name?.split(" ").map((word) => word[0])}
          </Avatar>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

interface Props {
  title: string;
  handleDrawerToggle: () => void;
}

import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { BoxRow } from "../../../../utils/styledComponents";
import { Cell, ParentLiveClass } from "../../../../interfaces";
import LpService from "../../../../services/lp.service";
import GeneralTableComponent from "../../../../components/GeneralTableComponent";
import GeneralAvatarComponent from "../../../../components/GeneralAvatarComponent";
import moment from "moment";

export default function ParentLiveClassTable() {
  const STRINGS = {
    TITLE: "Parent Live Classes",
    ADD_PARENT_CLASS: "Add Parent Class",
  };

  const [liveClasses, setLiveClasses] = useState<ParentLiveClass[]>([]);
  const [loading, setLoading] = useState(false);

  const cells: Cell[] = [
    { name: "Class", align: "left" },
    // { name: 'View Session', align: 'center' },
  ];

  const call = async () => {
    setLoading(true);
    const parentliveClasses = await LpService.readParentLiveClasses();
    setLiveClasses(parentliveClasses);
    setLoading(false);
  };

  useEffect(() => {
    call();
  }, []);

  return (
    <Box sx={{ mt: "1rem" }}>
      <BoxRow sx={{ justifyContent: "flex-end" }}>
        {/* 
        <Typography variant={"h5"} gutterBottom>
          {STRINGS.TITLE}
        </Typography>
        */}
        <Link to="/parent-class/create-parent-class">
          <Button
            variant="contained"
            sx={{ textDecoration: "none" }}
            startIcon={<Add />}
          >
            {STRINGS.ADD_PARENT_CLASS}
          </Button>
        </Link>
      </BoxRow>

      <GeneralTableComponent onRefresh={call} loading={loading}>
        <Table>
          <TableHead>
            <TableRow>
              {cells.map((cell) => (
                <TableCell align={cell.align} key={cell.name}>
                  {cell.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {liveClasses.map((row) => (
              <TableRow key={row.lpId}>
                <TableCell>
                  <Box display="flex">
                    <GeneralAvatarComponent src={row.lpImage} />
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                    >
                      <Typography
                        variant="subtitle2"
                        component={Link}
                        to={`/parent-class/${row.lpId}`}
                        sx={{ textDecoration: "none" }}
                        color="primary.main"
                      >
                        {row.lpTitle} (
                        {moment(row.lpStartDateTs).format(
                          "DD MMM y, HH:mm:ss A"
                        )}
                        {" - "}
                        {moment(row.lpEndDateTs).format("DD MMM y, HH:mm:ss A")}
                        )
                      </Typography>
                      <Typography variant="caption" color="textSecondary">
                        {row.lpDesc}
                      </Typography>
                      <Typography variant="caption" color="textSecondary">
                        {row.lpId}
                      </Typography>
                    </Box>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </GeneralTableComponent>
    </Box>
  );
}

import React, { useEffect, useState } from 'react'
import { Button, Divider, Grid, Stack, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { SideDetailsBox } from '../../../../utils/styledComponents'
import BackdropComponent from '../../../../components/BackDropComponent'

const CategoryActionButtons = (props: Props) => {
	const STRINGS = {
		// ACTION_BUTTONS: 'Action Buttons',
		UPDATE_CATEGORY: 'update category',
	}

	const { uid }: any = useParams()
	const { handleUpdate, disabled } = props
	const { enqueueSnackbar } = useSnackbar()
	const [showBackdrop, setShowBackDrop] = useState<boolean>(false)

	async function reloadPage() {
		if (window) {
			await window.location.reload()
		}
	}

	useEffect(() => {}, [])

	return (
		<Grid item xs={12} lg={3}>
			<Stack divider={<Divider />}>
				<SideDetailsBox>
					<Stack spacing={3}>
						<Button
							disabled={disabled}
							variant='contained'
							color={'secondary'}
							fullWidth
							onClick={handleUpdate}>
							{STRINGS.UPDATE_CATEGORY}
						</Button>
					</Stack>
				</SideDetailsBox>
			</Stack>

			{showBackdrop && <BackdropComponent />}
		</Grid>
	)
}

interface Props {
	handleUpdate: () => void
	disabled: boolean
}

export default CategoryActionButtons

import { get, orderByChild, query, ref, startAfter } from "firebase/database";
import { Certificate } from "../interfaces";
import { db } from "./firebaseApp";
import moment from "moment";

export const CertificateService = {
  async readAllCourseCertificates(): Promise<Certificate[]> {
    const certRef = ref(db, "/certRequestList/course");
    const certQuery = query(
      certRef,
      orderByChild("createdAt"),
      startAfter(moment().subtract(30, "days").valueOf())
    );
    const snapshot = await get(certQuery);
    const certificates: Certificate[] = [];
    snapshot.forEach((cert) => {
      certificates.push(cert.val());
    });
    return certificates;
  },

  async readAllLCCertificates(): Promise<Certificate[]> {
    const certRef = ref(db, "/certRequestList/liveClass");
    const certQuery = query(
      certRef,
      orderByChild("createdAt"),
      startAfter(moment().subtract(30, "days").valueOf())
    );
    const snapshot = await get(certQuery);
    const certificates: Certificate[] = [];
    snapshot.forEach((cert) => {
      certificates.push(cert.val());
    });
    return certificates;
  },
};

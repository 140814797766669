import React, {
  ChangeEvent,
  HTMLInputTypeAttribute,
  SyntheticEvent,
  useEffect,
  useState,
} from "react";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import { useSnackbar } from "notistack";
import {
  Autocomplete,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { REGEX } from "../../../../constants/regex.constant";
import { MakeTextField, MakeTextFields } from "../../../../interfaces";
import { AutoCompleteOption } from "../../../../interfaces/TextField.interface";
import TeacherService from "../../../../services/teacher.service";
import { make } from "../../../../utils/makeAutoCompleteOption";
import {
  Teacher,
  TeacherDetail,
  TeacherErrors,
  TeacherKeys,
} from "../../../../interfaces/Teacher.interface";
import { convertTitleToId } from "../../../../utils/convertString";
import VerticalDivider from "../../../../components/VerticalDivider";
import TeacherActionButtons from "./TeacherActionButtons";
import InfoComponent from "../../../../components/InfoComponent";
import RichTextEditor from "../../../../components/RichTextEditor";
import { checkTextFieldError } from "../../../../utils/checkTextField";
import cleanValues from "../../../../utils/cleanValues";
import GeneralAvatarComponent from "../../../../components/GeneralAvatarComponent";

const TeacherDetailsForm = () => {
  const STRINGS = {
    MAIN_DETAILS: "Main Details",
    OTHER_DETAILS: "Other Details",
    ADD_PHOTO: "+",
    SUBMIT: "Submit",
    SUCCESS_UPDATE_USER: "Successfully updated User!",
    ERROR_UPDATE_USER: "Failed to update User!",
    IMAGE_UPLOADED: "Successfully uploaded image!",
    RICH_EDITOR_LABLE: "Teacher HTML",
  };

  const history = useHistory();
  const { teacherId }: any = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [form, setForm] = useState<TeacherDetail>({} as TeacherDetail);
  const [options] = useState<AutoCompleteOptions>(autoCompleteOptions);
  const [errors, setErrors] = useState<TeacherErrors>({} as TeacherErrors);
  const [disableSubmit, setDisableSubmit] = useState(true);

  // rich text editor
  let [html, setHtml] = useState<string>("");
  let [htmlError, setHtmlError] = useState<boolean>(false);

  // to enable the update button onchange
  const setHtmlForm = (editorContent: string) => {
    setDisableSubmit(false);
    setHtml(editorContent);
  };

  const makeTextField = (
    label: string,
    type: HTMLInputTypeAttribute | "textarea" | "autocomplete",
    key: TeacherKeys,
    pattern: RegExp,
    required: boolean = false,
    disabled: boolean = false
  ): MakeTextField<TeacherDetail> => ({
    label,
    type: type === "autocomplete" ? "autocomplete" : type,
    key,
    pattern,
    required,
    disabled: disabled,
    value:
      type === "datetime-local"
        ? // TODO: why do we need unknown here
          moment(form[key] as unknown as number).format("D MMM yyyy HH:mm:ss ")
        : form[key],
    error: errors[key],
    helperText: errors[key]
      ? required && !form[key]
        ? "This field is required"
        : `Invalid ${label} format`
      : "",
    name: key,
    multiline: type === "textarea",
    rows: 4,
  });

  const textFields: MakeTextFields<TeacherDetail> = {
    teacherId: makeTextField("teacherId.", "text", "teacherId", REGEX.ANY),
    teacherEmail: makeTextField(
      "Email Address",
      "email",
      "teacherEmail",
      REGEX.EMAIL,
      true,
      true
    ),
    teacherName: makeTextField("Name", "text", "teacherName", REGEX.NAME, true),
    teacherImage: makeTextField(
      "Teacher Photo",
      "text",
      "teacherImage",
      REGEX.IMAGE,
      true
    ),
    teacherPhone: makeTextField(
      "Teacher Phone No.",
      "text",
      "teacherPhone",
      REGEX.MALAYSIAN_PHONE
    ),
    teacherLang: makeTextField(
      "Teacher Language",
      "autocomplete",
      "teacherLang",
      REGEX.WORDS
    ),
    teacherHtml: makeTextField(
      "Teacher Html",
      "textarea",
      "teacherHtml",
      REGEX.ANY
    ),
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setDisableSubmit(false);
    const { value, required } = e.target;
    const name = e.target.name as TeacherKeys;
    setForm({ ...form, [name]: value });

    // this is to check if the text field has error
    // 1. if field is '', return TRUE if field is required
    // 2. if field has value, return TRUE if pattern is wrong
    const pattern = textFields[name].pattern;
    const error: boolean = !value
      ? required
      : !new RegExp(pattern)?.test(value);
    setErrors({ ...errors, [name]: error });
    if (error) {
      setDisableSubmit(true);
    }
  };

  const handleAutoCompleteChange = (
    e: SyntheticEvent<Element, Event>,
    newValue: AutoCompleteOption | null,
    name: TeacherKeys
  ) => {
    e.preventDefault();
    setDisableSubmit(false);
    console.log("form", form.teacherLang);
    setForm({ ...form, [name]: newValue?.value });
  };

  const handleSubmit = async () => {
    setDisableSubmit(true);

    const massagedForm: Teacher = cleanValues({
      ...form,
      teacherName: form.teacherName || "",
      teacherSlug: convertTitleToId(form.teacherName),
      teacherId: convertTitleToId(form.teacherName),
      teacherBio: "",
      teacherRole: "teacher",
      teacherRoleAccess: 20,
      upComingClasses: 0,
      teacherUid: "",
      teacherStatus: "active",
      teacherHtml: html,
    });

    // revalidate form
    const hasError = Object.values(textFields).some((textField) => {
      const { name, label } = textField;
      const error: boolean = checkTextFieldError(textField, form);
      if (error) {
        setDisableSubmit(false);
        setErrors({ ...errors, [name]: error });
        enqueueSnackbar(`Invalid ${label} format`, { variant: "error" });
      }
      return error;
    });
    if (hasError) return;

    try {
      await TeacherService.updateTeacher(massagedForm, teacherId);
      history.push("/teacher");
    } catch (error) {
      setDisableSubmit(false);
      console.error("error:", error);
      enqueueSnackbar(STRINGS.ERROR_UPDATE_USER, { variant: "error" });
    }
  };

  useEffect(() => {
    (async () => {
      const teacher = await TeacherService.readOneTeacher(teacherId);
      const mergedData: TeacherDetail = {
        ...teacher,
      };
      setForm((curr) => {
        return mergedData;
      });

      console.table(mergedData);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} lg={8}>
        {/* main data */}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ marginTop: "3rem" }}>
              {STRINGS.MAIN_DETAILS}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <GeneralAvatarComponent src={form.teacherImage} large />
          </Grid>

          {Object.values(textFields)
            .slice(1, 6)
            .map((textField) => (
              <Grid item xs={12} key={textField.key}>
                <Grid container alignItems="center">
                  <Grid item xs={10}>
                    {textField.type === "autocomplete" ? (
                      <Autocomplete
                        disablePortal
                        fullWidth
                        // clearText='s'
                        disableClearable={true}
                        disabled={textField.disabled}
                        options={
                          options[textField.key as AutoCompleteOptionKeys]
                        }
                        getOptionLabel={(option) => option.title}
                        isOptionEqualToValue={(option) =>
                          option.value === form[textField.key]
                        }
                        value={options[
                          textField.key as AutoCompleteOptionKeys
                        ]?.find(
                          (option) => option.value === form[textField.key]
                        )}
                        onChange={(e, newValue) =>
                          handleAutoCompleteChange(e, newValue, textField.name)
                        }
                        // random key needs to be used in order to rerender the autocomplete field after async event
                        key={`autocomplete-${textField.key}-${
                          Math.random() * 1000
                        }`}
                        renderInput={(params) => (
                          <TextField
                            margin="normal"
                            {...params}
                            {...textField}
                          />
                        )}
                      />
                    ) : (
                      <TextField
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        onChange={handleChange}
                        InputLabelProps={{ shrink: true }}
                        {...textField}
                      />
                    )}
                  </Grid>
                  <Grid item xs={2} justifySelf="flex-end">
                    <InfoComponent
                      section="teacher"
                      textFieldName={textField.name}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ))}
          <Grid item xs={12}>
            <Grid container alignItems="center">
              <Grid item xs={10}>
                <RichTextEditor
                  fieldLabel="Teacher HTML"
                  html={form.teacherHtml}
                  setFormHtml={setHtmlForm}
                  setHtmlError={setHtmlError}
                />
              </Grid>

              <Grid item xs={2}>
                <InfoComponent section="teacher" textFieldName="teacherHtml" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Divider sx={{ my: "2rem" }} />

        {/* submit button */}

        {/* <Box width='100%' mt='1rem' display='flex' justifyContent='flex-end'>
						<Button
							variant='contained'
							onClick={handleSubmit}
							// find if there is any errors
							disabled={Object.values(errors).find((error) => error === true) || disableSubmit}>
							{STRINGS.SUBMIT}
						</Button>
					</Box> */}
      </Grid>

      <VerticalDivider />
      <TeacherActionButtons
        disabled={disableSubmit}
        handleUpdate={handleSubmit}
      />
    </Grid>
  );
};

interface AutoCompleteOptions {
  teacherLang: AutoCompleteOption[];
}

type AutoCompleteOptionKeys = keyof AutoCompleteOptions;

const autoCompleteOptions: AutoCompleteOptions = {
  teacherLang: [make("English", "EN"), make("Malay", "MY")],
};

export default TeacherDetailsForm;

import { Button } from "@mui/material";
import React from "react";
import GeneralDialogComponent from "../../../../components/GeneralDialogComponent";
import { Session } from "../../../../interfaces";
import SessionDetailsSection from "./SessionDetailsSection";

interface Props {
  session: Session;
  handleClose: () => void;
  refreshCallback: () => Promise<void>;
}
const ViewSessionDialog = (props: Props) => {
  const { session, handleClose, refreshCallback } = props;
  const STRINGS = {
    DONE: "Done",
  };
  return (
    <GeneralDialogComponent
      open
      title={session.sessionTitle}
      onClose={handleClose}
      actions={
        <>
          <Button onClick={handleClose}>{STRINGS.DONE}</Button>
        </>
      }
    >
      <SessionDetailsSection
        session={session}
        refreshCallback={refreshCallback}
      />
    </GeneralDialogComponent>
  );
};

export default ViewSessionDialog;

import moment from 'moment'
import { ParentLiveClassForm } from '../../../../../interfaces'

export const CreateClassFormTemplate: ParentLiveClassForm = {
	lpEndDateTs: moment().valueOf(),
	lpStartDateTs: moment().valueOf(),
	lpImage: '',
	lpTitle: '',
	lpDesc: '',
	lpCategoryId: '',
	lcDescHtml: '',
}

import React, { useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { Grid } from '@mui/material'
import BreadcrumbsComponent from '../../../components/Breadcrumbs'
import AppContainer from '../../../containers/AppContainer'
import TeacherActionButtons from './components/TeacherActionButtons'
import TeachertDetailsForm from './components/TeacherDetailsForm'
import VerticalDivider from '../../../components/VerticalDivider'

const TeacherDetailsPage = () => {
	const { url } = useRouteMatch()
	const [updating, setUpdating] = useState(false)

	return (
		<AppContainer headerTitle='Create Teacher'>
			<BreadcrumbsComponent url={url} />
			<TeachertDetailsForm />
		</AppContainer>
	)
}

export default TeacherDetailsPage

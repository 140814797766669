import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { ChangeEvent, HTMLInputTypeAttribute, useState } from "react";
import { useHistory } from "react-router-dom";
import { REGEX } from "../../../../constants/regex.constant";
import {
  Group,
  GroupErrors,
  GroupKeys,
  MakeTextField,
  MakeTextFields,
} from "../../../../interfaces";
import GroupService from "../../../../services/group.service";
import { checkTextFieldError } from "../../../../utils/checkTextField";

const GroupDetailsForm = (props: Props) => {
  const STRINGS = {
    TITLE: "Group Details",
    UPDATE: "Update",
    UPDATE_GROUP_SUCCESS: "Updated Group Successfully",
    UPDATE_GROUP_FAILED: "Failed to Update Group",
  };

  const { group, updating } = props;
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [form, setForm] = useState<Group>(group);
  const [errors, setErrors] = useState<GroupErrors>({} as GroupErrors);
  const [disableSubmit, setDisableSubmit] = useState(true);

  const makeTextField = (
    label: string,
    type: HTMLInputTypeAttribute | "textarea" | "autocomplete",
    key: GroupKeys,
    pattern: RegExp,
    required: boolean = false,
    disabled: boolean = false
  ): MakeTextField<Group> => ({
    label,
    type: type === "autocomplete" ? "autocomplete" : type,
    key,
    pattern,
    required,
    disabled: disabled || !updating,
    value: form[key],
    error: errors[key],
    helperText: errors[key]
      ? required && !form[key]
        ? "This field is required"
        : `Invalid ${label} format`
      : "",
    name: key,
    multiline: type === "textarea",
    rows: 4,
  });

  const textFields: MakeTextFields<Group> = {
    groupId: makeTextField(
      "Group ID",
      "text",
      "groupId",
      REGEX.ANY,
      true,
      true
    ),
    groupTitle: makeTextField(
      "Group Title",
      "text",
      "groupTitle",
      REGEX.ANY,
      true
    ),
    totalSlots: makeTextField(
      "Total Slots",
      "number",
      "totalSlots",
      REGEX.NUMBER,
      true
    ),
    slotsAvailable: makeTextField(
      "Slots Available",
      "number",
      "slotsAvailable",
      REGEX.NUMBER,
      false,
      true
    ),
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setDisableSubmit(false);

    const { name, value } = e.target;
    switch (name) {
      case "groupId":
        break;
      case "slotsAvailable":
        break;
      case "totalSlots":
        setForm({ ...form, [name]: Math.abs(+value) });
        break;
      default:
        setForm({ ...form, [name]: value });
        break;
    }
  };

  const handleSubmit = async () => {
    setDisableSubmit(true);

    // validate form
    const hasError = Object.values(textFields).some((textField) => {
      const { name, label } = textField;
      const error: boolean = checkTextFieldError(textField, form);
      if (error) {
        setDisableSubmit(false);
        setErrors({ ...errors, [name]: error });
        enqueueSnackbar(`Invalid ${label} format`, { variant: "error" });
      }
      return error;
    });

    if (hasError) return;

    // check slots
    const users = +group.totalSlots - group.slotsAvailable;
    if (form.totalSlots < users) {
      setDisableSubmit(false);
      enqueueSnackbar(`Total Slots must be at least ${users}`, {
        variant: "error",
      });
      return;
    }

    try {
      await GroupService.updateGroup(form);
      enqueueSnackbar(STRINGS.UPDATE_GROUP_SUCCESS, { variant: "success" });
      history.push("/group");
    } catch (e: any) {
      enqueueSnackbar(e.message || STRINGS.UPDATE_GROUP_FAILED, {
        variant: "error",
      });
    }
  };

  return (
    <Grid item xs={12} lg={8}>
      <Typography variant="h5" sx={{ m: "2rem 0" }}>
        {STRINGS.TITLE}
      </Typography>

      <Grid container spacing={3}>
        {Object.values(textFields).map((textField) => (
          <Grid item xs={12} lg={6} key={textField.key}>
            <TextField
              {...textField}
              fullWidth
              variant="outlined"
              margin="normal"
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                inputProps: { min: 0 },
              }}
            />
          </Grid>
        ))}
      </Grid>

      <Box width="100%" display="flex" justifyContent="flex-end" mt={3}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={!updating || disableSubmit}
        >
          {STRINGS.UPDATE}
        </Button>
      </Box>
    </Grid>
  );
};

interface Props {
  group: Group;
  updating: boolean;
}

export default GroupDetailsForm;

import { get, ref, remove, set, update } from "firebase/database";
import { Teacher } from "../interfaces";
import { db } from "./firebaseApp";

const TeacherService = {
  async readTeachers(): Promise<Teacher[]> {
    const lcRef = ref(db, `/lcTeachers`);
    const snapshot = await get(lcRef);
    const teachers: Teacher[] = [];
    snapshot.forEach((snap) => {
      teachers.push(snap.val());
    });
    return teachers;
  },

  async readOneTeacher(id: string): Promise<Teacher> {
    const teacherRef = ref(db, `/lcTeachers/${id}`);
    const snapshot = await get(teacherRef);
    return snapshot.val();
  },

  async updateTeacher(teacher: Teacher, teacherId: string): Promise<void> {
    const newTeacherRef = ref(db, `/lcTeachers/${teacher.teacherId}`);

    if (teacher.teacherId !== teacherId) {
      const currentTeacherRef = ref(db, `/lcTeachers/${teacherId}`);
      await set(newTeacherRef, teacher);
      await remove(currentTeacherRef);
    } else {
      await update(newTeacherRef, teacher);
    }
  },
  async createTeacher(teacher: Teacher): Promise<void> {
    const teacherRef = ref(db, `/lcTeachers/${teacher.teacherId}`);
    await set(teacherRef, teacher);
  },
};

export default TeacherService;

import { Divider, Grid } from '@mui/material'
import React from 'react'

const VerticalDivider = () => {
  return (
    <Grid item lg={1}>
      <Divider orientation="vertical" />
    </Grid>
  )
}

export default VerticalDivider

import React, { useState } from "react";
import { useLocation, useRouteMatch } from "react-router-dom";
import BreadcrumbsComponent from "../../../components/Breadcrumbs";
import AppContainer from "../../../containers/AppContainer";
import AddTeacherDialog from "./AddTeacherDialog";
import AddTeacherrBox from "./AddTeacherBox";
import TeacherTable from "./TeacherTable";

const TeacherPage = () => {
  console.log("teacher page");
  const STRINGS = {
    TITLE: "Teachers",
    ADD_TEACHER: "Add Teacher",
    SUBMIT: "Submit",
    SUCCESS_ADD_USER: "Successfully added Teacher!",
    ERROR_ADD_USER: "Failed to add Teacher!",
    RICH_EDITOR_LABLE: "Teacher HTML",
  };

  const { url } = useRouteMatch();
  const location = useLocation();
  const [open, setOpen] = useState(location.hash === "#add");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <AppContainer headerTitle={STRINGS.TITLE}>
      <BreadcrumbsComponent url={url} />
      <AddTeacherrBox handleOpen={handleOpen} STRINGS={STRINGS} />
      <AddTeacherDialog
        open={open}
        handleClose={handleClose}
        STRINGS={STRINGS}
      />
      <TeacherTable />
    </AppContainer>
  );
};

export default TeacherPage;

import { Delete, Refresh } from "@mui/icons-material";
import {
  Box,
  Card,
  CircularProgress,
  IconButton,
  Table,
  TableContainer,
  Tooltip,
} from "@mui/material";
import React, { ReactNode } from "react";

export default function GeneralTableComponent(props: Props) {
  const STRINGS = {
    SEARCH: "Search",
    REFRESH: "Refresh",
    SEARCH_DISABLED: "Search is disabled and not implemented",
    REFRESH_DISABLED: "Refresh is disabled",
    REMOVE: "Remove",
    REMOVE_DISABLED: "Remove is disabled",
  };

  const { children, onRefresh, loading, onRemove, disableRemove } = props;

  return (
    <Box>
      <Card>
        <TableContainer>
          <Box display="flex" justifyContent="flex-end">
            {/* <Tooltip title={STRINGS.SEARCH_DISABLED}>
              <Paper
                component="form"
                elevation={0}
                sx={{
                  flex: 1,
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "grey.100",
                }}
              >
                <IconButton sx={{ p: "0.75rem" }} disabled>
                  <Search />
                </IconButton>
                <InputBase
                  disabled
                  sx={{ ml: 1, flex: 1 }}
                  placeholder={STRINGS.SEARCH}
                />
              </Paper>
            </Tooltip> */}

            <Tooltip
              title={onRefresh ? STRINGS.REFRESH : STRINGS.REFRESH_DISABLED}
            >
              <IconButton
                sx={{ ml: "1rem", padding: "1rem" }}
                onClick={onRefresh}
                disabled={!onRefresh}
              >
                <Refresh />
              </IconButton>
            </Tooltip>

            {onRemove ? (
              <Tooltip title={STRINGS.REMOVE}>
                <IconButton
                  sx={{ ml: "1rem", padding: "1rem" }}
                  onClick={onRemove}
                  disabled={disableRemove}
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            ) : null}
          </Box>

          {loading ? (
            <Box
              width="100%"
              height="300px"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress />
            </Box>
          ) : (
            <Table>{children}</Table>
          )}
        </TableContainer>
      </Card>
    </Box>
  );
}

interface Props {
  children: ReactNode;
  onRefresh?: () => void;
  loading?: boolean;
  onRemove?: () => void;
  disableRemove?: boolean;
}

import { get, ref, remove, set, update } from 'firebase/database'
import { Category } from '../interfaces'
import { db } from './firebaseApp'

const CategoryService = {
	async readCategories(): Promise<Category[]> {
		const categoryRef = ref(db, '/sepContent/liveClass/lcCategoryList')
		const snapshot = await get(categoryRef)
		const categories: Category[] = []
		snapshot.forEach((snap) => {
			categories.push(snap.val())
		})
		return categories
	},
	async createCategory(category: Category) {
		const categoryRef = ref(db, `/sepContent/liveClass/lcCategoryList/${category.lcCategoryId}`)
		await set(categoryRef, category)
	},
	async updateCategory(category: Category, categoryId: string) {
		const newCategoryRef = ref(db, `/sepContent/liveClass/lcCategoryList/${category.lcCategoryId}`)
		if (category.lcCategoryId !== categoryId) {
			const currentCategoryRef = ref(db, `/sepContent/liveClass/lcCategoryList/${categoryId}`)
			await set(newCategoryRef, category)
			await remove(currentCategoryRef)
		} else {
			await update(newCategoryRef, category)
		}
	},
	async readOneCategory(categoryId: string): Promise<Category> {
		const categoryRef = ref(db, `/sepContent/liveClass/lcCategoryList/${categoryId}`)
		const snapshot = await get(categoryRef)
		return snapshot.val()
	},
}

export default CategoryService

import React, { useEffect, useState } from "react";
import { Button, Divider, Grid, Stack, Typography } from "@mui/material";

import { useParams } from "react-router-dom";
import UserService from "../../../../services/user.service";
import { useSnackbar } from "notistack";
import { User, UserMeta } from "../../../../interfaces";
import AddUserToClassDialog from "./AddUserToClassDialog";
import InactivateUserDialog from "./InactivateUserDialog";
import AddUserToCourseDialog from "./AddUserToCourseDialog";
import SendWelcomeEmailDialog from "./SendWelcomeEmailDialog";
import { SideDetailsBox } from "../../../../utils/styledComponents";
import AddUserToGroupDialog from "./AddUserToGroupDialog";
import ViewUserClassDialog from "./ViewUserClassDialog";
import ViewUserCourseDialog from "./ViewUserCourseDialog";
import ViewUserGroupDialog from "./ViewUserGroupDialog";
import BackdropComponent from "../../../../components/BackDropComponent";
import ActivateUserDialog from "./ActivateUserDialog";

const UserActionButtons = (props: Props) => {
  const STRINGS = {
    ACTION_BUTTONS: "Action Buttons",
    UPDATE_USER: "update user",
    ADD: "Add",
    CANCEL: "cancel",
    INACTIVATE_USER: "Inactivate User",
    ACTIVATE_USER: "Activate User",
    SEND_WELCOME_USER: "Send Welcome User Email",
    SEND_WELCOME_USER_FORCE: "Force Send Welcome User Email",
    SEND_RESET_PASSWORD_LINK: "Send Reset Password Link",
    SEND_RESET_PASSWORD_LINK_FORCE: "Force Reset Password Link",
    SUCCESS_SEND_RESET_PASSWORD_LINK: "Succesfully Sent Reset Password Link",
    FAILED_SEND_RESET_PASSWORD_LINK: "Failed to Send Reset Password Link",
    LIVE_CLASSES: "Live Classes",
    ADD_USER_TO_LIVE_CLASS: "Add User to Live Class",
    VIEW_LIVE_CLASS: "View User Live Classes",
    COURSES: "Courses",
    ADD_USER_TO_COURSE: "Add User to Course",
    VIEW_COURSE: "View User Courses",
    GROUPS: "Groups",
    ADD_USER_TO_GROUP: "Add User To Group",
    VIEW_GROUP: "View User Groups",
    MSG_DUPLICATE_PWD_SEND: "User already has a reset password link sent",
    MSG_DUPLICATE_WLC_SEND: "User already has a welcome email sent",
    SUMMARY_TITLE: "Summary",
  };

  const { uid }: any = useParams();
  const { updating, handleUpdate } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [user, setUser] = useState<User>({} as User);
  const [userMeta, setUserMeta] = useState<UserMeta>({} as UserMeta);
  const [prompt, setPrompt] = useState<DialogTypes>("");
  const [showBackdrop, setShowBackDrop] = useState<boolean>(false);
  const [pwdResetCountVaState, setPwdResetCountVal] = useState(0);
  const [welcomeEmailSentCount, setWelcomeEmailSentCount] = useState(0);

  const handleClose = () => setPrompt("");

  async function reloadPage() {
    if (window) {
      await window.location.reload();
    }
  }

  async function handleResetPasswordForce() {
    const res: any = await UserService.createResetPasswordLink(user.email);
    console.log("res:", res);
    if (res.statusCode === 202) {
      enqueueSnackbar(STRINGS.SUCCESS_SEND_RESET_PASSWORD_LINK, {
        variant: "success",
      });
      await UserService.setUserPwdResetCount(uid);
      // history.push("/user");
      await reloadPage();
    } else {
      enqueueSnackbar(STRINGS.FAILED_SEND_RESET_PASSWORD_LINK, {
        variant: "error",
      });
    }
    setShowBackDrop(false);
  }

  // RESET PASSWORD
  const handleSendResetPasswordLink = async (force: boolean) => {
    setShowBackDrop(true);

    // first check if user has already used this feature
    const pwdResetCountVal = await UserService.getUserPwdResetCount(uid);
    if (force || !pwdResetCountVal || pwdResetCountVal === 0)
      return handleResetPasswordForce();
    enqueueSnackbar(STRINGS.MSG_DUPLICATE_PWD_SEND, {
      variant: "info",
    });
    setShowBackDrop(false);
  };

  useEffect(() => {
    (async () => {
      setShowBackDrop(true);
      const res = await UserService.readOneUser(uid);
      setUser(res);

      const metaRes = await UserService.readOneUserMeta(uid);
      setUserMeta(metaRes);
      const pwdResetCountVal = await UserService.getUserPwdResetCount(uid);

      if (pwdResetCountVal > 0) {
        enqueueSnackbar(STRINGS.MSG_DUPLICATE_PWD_SEND, {
          variant: "info",
        });
        setPwdResetCountVal(pwdResetCountVal);
      }

      const welcomeEmailSentCount = await UserService.getWelcomeEmailCount(uid);

      if (welcomeEmailSentCount > 0) {
        enqueueSnackbar(STRINGS.MSG_DUPLICATE_WLC_SEND, {
          variant: "info",
        });
        setWelcomeEmailSentCount(welcomeEmailSentCount);
      }
      setShowBackDrop(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const DialogsComponent = () => {
    switch (prompt) {
      case "send welcome":
        return (
          <SendWelcomeEmailDialog
            user={user}
            handleClose={handleClose}
            setShowBackDrop={setShowBackDrop}
          />
        );
      case "inactivate user":
        return <InactivateUserDialog user={user} handleClose={handleClose} />;
      case "activate user":
        return <ActivateUserDialog user={user} handleClose={handleClose} />;
      case "add user to class":
        return <AddUserToClassDialog user={user} handleClose={handleClose} />;
      case "view class":
        return <ViewUserClassDialog user={user} handleClose={handleClose} />;
      case "add user to course":
        return <AddUserToCourseDialog user={user} handleClose={handleClose} />;
      case "view course":
        return <ViewUserCourseDialog user={user} handleClose={handleClose} />;
      case "add user to group":
        return <AddUserToGroupDialog user={user} handleClose={handleClose} />;
      case "view group":
        return <ViewUserGroupDialog user={user} handleClose={handleClose} />;
      default:
        return null;
    }
  };

  async function handleSendWelcomeEmail(force: boolean) {
    // check if already sent
    const welcomeEmailCount = await UserService.getWelcomeEmailCount(uid);
    if (force || !welcomeEmailCount || welcomeEmailCount === 0)
      return setPrompt("send welcome");
    enqueueSnackbar(STRINGS.MSG_DUPLICATE_WLC_SEND, {
      variant: "info",
    });
  }

  return (
    <Grid item xs={12} lg={3}>
      <Stack divider={<Divider />}>
        <SideDetailsBox>
          <Typography
            variant="caption"
            sx={{ m: "1rem 0", textAlign: "center" }}
          >
            {"Password Reset Count: " + pwdResetCountVaState}
          </Typography>
          <Divider />
          <Typography
            variant="caption"
            sx={{ m: "1rem 0", textAlign: "center" }}
          >
            {"Welcome email count: " + welcomeEmailSentCount}
          </Typography>
        </SideDetailsBox>
      </Stack>

      <Stack divider={<Divider />}>
        <SideDetailsBox>
          <Stack spacing={3}>
            <Button
              variant="contained"
              color={updating ? "error" : "secondary"}
              fullWidth
              onClick={handleUpdate}
            >
              {updating ? STRINGS.CANCEL : STRINGS.UPDATE_USER}
            </Button>

            {pwdResetCountVaState > 0 ? (
              <Button
                color="secondary"
                variant="outlined"
                fullWidth
                onClick={() => handleSendResetPasswordLink(true)}
              >
                {STRINGS.SEND_RESET_PASSWORD_LINK_FORCE}
              </Button>
            ) : (
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={() => handleSendResetPasswordLink(false)}
              >
                {STRINGS.SEND_RESET_PASSWORD_LINK}
              </Button>
            )}

            {welcomeEmailSentCount > 0 ? (
              <Button
                variant="text"
                color="primary"
                fullWidth
                onClick={() => handleSendWelcomeEmail(true)}
              >
                {STRINGS.SEND_WELCOME_USER_FORCE}
              </Button>
            ) : (
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={() => handleSendWelcomeEmail(false)}
              >
                {" "}
                {STRINGS.SEND_WELCOME_USER}
              </Button>
            )}

            {userMeta?.userStatus?.toLowerCase() === "active" ? (
              <Button
                variant="outlined"
                color="error"
                fullWidth
                onClick={() => setPrompt("inactivate user")}
              >
                {STRINGS.INACTIVATE_USER}
              </Button>
            ) : (
              <Button
                variant="outlined"
                color="error"
                fullWidth
                onClick={() => setPrompt("activate user")}
              >
                {STRINGS.ACTIVATE_USER}
              </Button>
            )}
          </Stack>
        </SideDetailsBox>

        <SideDetailsBox>
          <Typography variant="h5" sx={{ mb: "1rem" }}>
            {STRINGS.LIVE_CLASSES}
          </Typography>
          <Stack spacing={3}>
            <Button
              variant="contained"
              fullWidth
              onClick={() => setPrompt("add user to class")}
            >
              {STRINGS.ADD_USER_TO_LIVE_CLASS}
            </Button>
            <Button
              color="secondary"
              variant="outlined"
              fullWidth
              onClick={() => setPrompt("view class")}
            >
              {STRINGS.VIEW_LIVE_CLASS}
            </Button>
          </Stack>
        </SideDetailsBox>

        <SideDetailsBox>
          <Typography variant="h5" sx={{ mb: "1rem" }}>
            {STRINGS.COURSES}
          </Typography>
          <Stack spacing={3}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => setPrompt("add user to course")}
            >
              {STRINGS.ADD_USER_TO_COURSE}
            </Button>
            <Button
              color="secondary"
              variant="outlined"
              fullWidth
              onClick={() => setPrompt("view course")}
            >
              {STRINGS.VIEW_COURSE}
            </Button>
          </Stack>
        </SideDetailsBox>

        <SideDetailsBox>
          <Typography variant="h5" sx={{ mb: "1rem" }}>
            {STRINGS.GROUPS}
          </Typography>
          <Stack spacing={3}>
            <Button
              variant="contained"
              fullWidth
              onClick={() => setPrompt("add user to group")}
            >
              {STRINGS.ADD_USER_TO_GROUP}
            </Button>
            <Button
              color="secondary"
              variant="outlined"
              fullWidth
              onClick={() => setPrompt("view group")}
            >
              {STRINGS.VIEW_GROUP}
            </Button>
          </Stack>
        </SideDetailsBox>
      </Stack>

      <DialogsComponent />
      {showBackdrop && <BackdropComponent />}
    </Grid>
  );
};

interface Props {
  updating: boolean;
  handleUpdate: () => void;
}

type DialogTypes =
  | "inactivate user"
  | "activate user"
  | "send welcome"
  | "add user to class"
  | "view class"
  | "add user to course"
  | "view course"
  | "add user to group"
  | "view group"
  | "";

export default UserActionButtons;

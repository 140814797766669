import React, { ChangeEvent, HTMLInputTypeAttribute, useState } from "react";
import { Button, Grid, TextField } from "@mui/material";
import { useSnackbar } from "notistack";

import moment from "moment";
import {
  AddCategory,
  AddCategoryErrors,
  AddCategoryKeys,
  Category,
} from "../../../../interfaces";
import { MakeTextField } from "../../../../interfaces";
import { MakeTextFields } from "../../../../interfaces/TextField.interface";
import InfoComponent from "../../../../components/InfoComponent";
import GeneralDialogComponent from "../../../../components/GeneralDialogComponent";
import { REGEX } from "../../../../constants/regex.constant";
import { convertTitleToId } from "../../../../utils/convertString";
import CategoryService from "../../../../services/category.service";
import { checkTextFieldError } from "../../../../utils/checkTextField";
import cleanValues from "../../../../utils/cleanValues";
import GeneralAvatarComponent from "../../../../components/GeneralAvatarComponent";

const CreateCategoryForm = (props: Props) => {
  const { open, handleClose, STRINGS, loadOptions } = props;

  const { enqueueSnackbar } = useSnackbar();
  const [form, setForm] = useState<AddCategory>({} as AddCategory);
  const [errors, setErrors] = useState<AddCategoryErrors>(
    {} as AddCategoryErrors
  );
  const [disableSubmit, setDisableSubmit] = useState(true);

  const makeTextField = (
    label: string,
    type: HTMLInputTypeAttribute | "textarea" | "autocomplete",
    key: AddCategoryKeys,
    pattern: RegExp,
    required: boolean = false,
    disabled: boolean = false
  ): MakeTextField<AddCategory> => ({
    label,
    type: type === "autocomplete" ? "autocomplete" : type,
    key,
    pattern,
    required,
    disabled: disabled,
    value:
      type === "datetime-local"
        ? // TODO: why do we need unknown here
          moment(form[key] as unknown as number).format("D MMM yyyy HH:mm:ss ")
        : form[key],
    error: errors[key],
    helperText: errors[key]
      ? required && !form[key]
        ? "This field is required"
        : `Invalid ${label} format`
      : "",
    name: key,
    multiline: type === "textarea",
    rows: 4,
  });

  const textFields: MakeTextFields<AddCategory> = {
    lcCategoryName: makeTextField(
      "Name",
      "text",
      "lcCategoryName",
      REGEX.ANY,
      true
    ),
    lcCategoryImage: makeTextField(
      "Image",
      "text",
      "lcCategoryImage",
      REGEX.IMAGE,
      true
    ),
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setDisableSubmit(false);
    const { required, value } = e.target;
    const name = e.target.name as AddCategoryKeys;
    setForm({ ...form, [name]: value });

    // this is to check if the text field has error
    // 1. if field is '', return TRUE if field is required
    // 2. if field has value, return TRUE if pattern is wrong
    const pattern = textFields[name].pattern;
    const error: boolean = !value
      ? required
      : !new RegExp(pattern)?.test(value);
    setErrors({ ...errors, [name]: error });
  };

  const handleSubmit = async () => {
    setDisableSubmit(true);

    // validate form
    const hasError = Object.values(textFields).some((textField) => {
      const { name, label } = textField;
      const error: boolean = checkTextFieldError(textField, form);
      if (error) {
        setDisableSubmit(false);
        setErrors({ ...errors, [name]: error });
        enqueueSnackbar(`Invalid ${label} format`, { variant: "error" });
      }
      return error;
    });

    if (hasError) return;

    const massagedForm: Category = cleanValues({
      ...form,
      lcCategoryId: convertTitleToId(form.lcCategoryName),
      lcCategoryImage: form.lcCategoryImage || "",
      lcCategoryHtml: "",
      lcCategoryColor: "",
      lcCategoryDesc: "",
    });

    try {
      await CategoryService.createCategory(massagedForm);

      handleClose();

      enqueueSnackbar(STRINGS.SUCCESS_ADD_CATEGORY, { variant: "success" });
      // TODO: redirect to category page or category list page or close dialog
      // refetch categories if the form is in a popup
      loadOptions && loadOptions();
    } catch (error: any) {
      setDisableSubmit(false);
      console.error("error:", error);
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  return (
    <GeneralDialogComponent
      open={open}
      onClose={() => {
        handleClose();
        setErrors({} as AddCategoryErrors);
      }}
      title={STRINGS.ADD_CATEGORY}
      actions={
        <Button
          variant="contained"
          onClick={handleSubmit}
          // find if there is any errors
          disabled={
            Object.values(errors).find((error) => error === true) ||
            disableSubmit
          }
        >
          {STRINGS.SUBMIT}
        </Button>
      }
    >
      <Grid item xs={12}>
        <GeneralAvatarComponent src={form.lcCategoryImage} large />
      </Grid>
      {Object.values(textFields).map((textField) => (
        <Grid container alignItems="center">
          <Grid item xs={10}>
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
              {...textField}
            />
          </Grid>

          <Grid item xs={2}>
            <InfoComponent section="category" textFieldName={textField.name} />
          </Grid>
        </Grid>
      ))}
    </GeneralDialogComponent>
  );
};

interface Props {
  open: boolean;
  handleClose: () => void;
  STRINGS: any;
  loadOptions?: () => void;
}

export default CreateCategoryForm;

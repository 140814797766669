import { Delete, Image } from "@mui/icons-material";
import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import GeneralAvatarComponent from "../../../../components/GeneralAvatarComponent";
import GeneralDialogComponent from "../../../../components/GeneralDialogComponent";
import { Group, LiveClass } from "../../../../interfaces";
import LiveClassService from "../../../../services/liveclass.service";

export default function ViewGroupClassDialog(props: Props) {
  const STRINGS = {
    LIVE_CLASSES: "Live Classes",
    DISMISS: "Dismiss",
    GROUP_HAS_NO_LIVE_CLASS: "Group Has No Live Classes",
    CANCEL: "Cancel",
  };

  const { group, handleClose } = props;

  const [liveClasses, setLiveClasses] = useState<LiveClass[]>([]);
  const [loading, setLoading] = useState(false);

  const call = async () => {
    setLoading(true);
    const liveClasses = await LiveClassService.readGroupClasses(group.groupId);
    setLiveClasses(liveClasses);
    setLoading(false);
  };

  useEffect(() => {
    call();
  }, []);

  return (
    <GeneralDialogComponent
      open
      onClose={handleClose}
      title={`${group.groupTitle} ${STRINGS.LIVE_CLASSES}`}
      actions={<Button onClick={handleClose}>{STRINGS.DISMISS}</Button>}
    >
      <List>
        {!loading ? (
          liveClasses.length ? (
            liveClasses.map((liveClass) => (
              <ListItem key={liveClass.lcId}>
                <GeneralAvatarComponent src={liveClass.lcImage} />
                <ListItemText
                  primary={
                    <Typography>
                      {liveClass.lcTitle} (
                      {moment(liveClass.lcStartDateTs).format(
                        "DD MMM y, HH:mm:ss A"
                      )}
                      {" - "}
                      {moment(liveClass.lcEndDateTs).format(
                        "DD MMM y, HH:mm:ss A"
                      )}
                      )
                    </Typography>
                  }
                  secondary={liveClass.lcDesc}
                />
              </ListItem>
            ))
          ) : (
            <Typography variant="body1" color="textSecondary">
              {STRINGS.GROUP_HAS_NO_LIVE_CLASS}
            </Typography>
          )
        ) : (
          <ListItem
            secondaryAction={
              <IconButton edge="end" disabled>
                <Delete />
              </IconButton>
            }
          >
            <GeneralAvatarComponent />
            <ListItemText
              primary={<Skeleton variant="text" height={16} width={200} />}
              secondary={<Skeleton variant="text" height={14} width={150} />}
            />
          </ListItem>
        )}
      </List>
    </GeneralDialogComponent>
  );
}

interface Props {
  group: Group;
  handleClose: () => void;
}

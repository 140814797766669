import { Button, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { ChangeEvent, useEffect, useState } from "react";
import GeneralDialogComponent from "../../../../components/GeneralDialogComponent";
import { LiveClassExtrasService } from "../../../../services/liveClassExtras.service";

interface Props {
  handleClose: () => void;
  lcId: string;
}
const ViewExternalLinkDialog = (props: Props) => {
  const STRINGS = {
    EXTERNAL_LINK: "External Link",
    ADD_EXTERNAL_LINK: "Add External Link",
    CANCEL: "Cancel",
    UPDATE: "Update",
    SUCCESS_UPDATE_LINK: "Successfully updated external link",
    FAILED_UPDATE_LINK: "Failed to update external link",
  };

  const { handleClose, lcId } = props;
  const [externalLink, setExternalLink] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setExternalLink(e.target.value);
  };

  const handleSubmit = async () => {
    const massagedLink = externalLink.trim();
    try {
      await LiveClassExtrasService.updateExternalLink(massagedLink, lcId);
      enqueueSnackbar(STRINGS.SUCCESS_UPDATE_LINK, {
        variant: "success",
      });
      handleClose();
    } catch (error: any) {
      enqueueSnackbar(error.message || STRINGS.FAILED_UPDATE_LINK, {
        variant: "error",
      });
    }
  };

  const call = async () => {
    const extras = await LiveClassExtrasService.readExtras(lcId);
    setExternalLink(extras?.externalLink);
  };

  useEffect(() => {
    call();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GeneralDialogComponent
      open
      onClose={handleClose}
      title={STRINGS.ADD_EXTERNAL_LINK}
      actions={
        <>
          <Button variant="outlined" onClick={handleClose}>
            {STRINGS.CANCEL}
          </Button>
          <Button variant="contained" onClick={handleSubmit}>
            {STRINGS.UPDATE}
          </Button>
        </>
      }
    >
      <TextField
        label={STRINGS.EXTERNAL_LINK}
        onChange={handleChange}
        value={externalLink}
        fullWidth
      />
    </GeneralDialogComponent>
  );
};

export default ViewExternalLinkDialog;

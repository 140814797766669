import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./constants/theme.constant";
import Routes from "./layouts/Router/Routes";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "./utils/providers/AuthProvider";
import "./App.css";
import SolsSnackbarProvider from "./utils/providers/SolsSnackbarProvider";

const App: React.FC = () => {
  return (
    <AuthProvider>
      <Router>
        <ThemeProvider theme={theme}>
          <SolsSnackbarProvider>
            <Routes />
          </SolsSnackbarProvider>
        </ThemeProvider>
      </Router>
    </AuthProvider>
  );
};

export default App;

import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import PrivateRoute from "../../layouts/Router/PrivateRoute";
import CreateLiveClass from "./CreateLiveClass";
import LiveClassPage from "./components";
import LiveClassDetailsPage from "./LiveClassDetails";
import LiveClassSessionsPage from "./Sessions";
import EditLiveClass from "./EditLiveClass";
import LiveClassProgress from "./LiveClassProgress";
import CreateParentLiveClass from './ParentLiveClass/createParentLiveClass'
import ParentLiveClass from './ParentLiveClass/components'
import EditParentLiveClass from './ParentLiveClass/EditParentLiveClass'

const LiveClass = () => {
	const { path } = useRouteMatch()

	return (
		<Switch>
			<PrivateRoute exact path={path} component={LiveClassPage} />
			<PrivateRoute path={`${path}/create-class`} component={CreateLiveClass} />
			<PrivateRoute path={`${path}/create-class/:lcId`} component={undefined} />
			<PrivateRoute path={`${path}/create-parent-class`} component={CreateParentLiveClass} />
			<PrivateRoute path={`${path}/parent-class`} component={ParentLiveClass} />

			<PrivateRoute path={`${path}/:lcId/session`} component={LiveClassSessionsPage} />
			<PrivateRoute exact path={`${path}/:lcId`} component={LiveClassDetailsPage} />
			<PrivateRoute path={`${path}/edit-class/:lcId`} component={EditLiveClass} />
			<PrivateRoute path={`${path}/progress/:lcId`} component={LiveClassProgress} />
		</Switch>
	)
}

export default LiveClass;

import { Refresh } from "@mui/icons-material";
import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import React, { FC } from "react";
import Moment from "react-moment";
import { DUMMY_IMG } from "../../../../constants/dummy-data.constant";
import { LiveClass } from "../../../../interfaces";
import {
  BoldText,
  BoxColumn,
  BoxRow,
} from "../../../../utils/styledComponents";

const Details: FC<Props> = (props: Props) => {
  const { liveClass, onRefresh } = props;

  return (
    <BoxRow sx={{ pt: "1rem", pb: "1rem", flexGrow: 1 }}>
      <Box
        component="img"
        src={liveClass.lcImage || DUMMY_IMG}
        sx={{ width: "150px", height: "150px", borderRadius: "1rem" }}
      />
      <BoxColumn sx={{ pl: "1rem", justifyContent: "center", flexGrow: 1 }}>
        <BoxRow>
          <BoldText variant="h5" mr="0.3rem">
            {liveClass.lcTitle || "-"}
          </BoldText>
        </BoxRow>
        <BoxRow sx={{ pt: "1rem" }}>
          <BoldText>Start Date:</BoldText>
          <Typography sx={{ pl: "0.5rem" }}>
            {liveClass.lcStartDateTs ? (
              <Moment format="D MMM y">{liveClass.lcStartDateTs}</Moment>
            ) : (
              "-"
            )}
          </Typography>
        </BoxRow>
        <BoxRow>
          <BoldText>Linked Course:</BoldText>
          <Typography sx={{ pl: "0.5rem" }}>{liveClass.lpId}</Typography>
        </BoxRow>
      </BoxColumn>
      <BoxColumn sx={{ justifyContent: "flex-end" }}>
        <BoxRow>
          <Tooltip title="Refresh">
            <IconButton onClick={onRefresh}>
              <Refresh />
            </IconButton>
          </Tooltip>
        </BoxRow>
      </BoxColumn>
    </BoxRow>
  );
};

export default Details;

interface Props {
  liveClass: LiveClass;
  onRefresh: () => void;
}

import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import { ReportSummary, ReportSummaryKeys } from "../../../../interfaces";
import Moment from "react-moment";
import LiveClassService from "../../../../services/liveclass.service";
import moment from "moment";
import UserService from "../../../../services/user.service";

const Statistics = () => {
  const STRINGS = {
    CLICK_TO_REFRESH: "Click to Refresh",
    LAST_UPDATED: "Last updated: ",
  };

  const stats = [
    {
      title: "Live Classes",
      key: "liveClasses",
      bgcolor: "rgb(200, 250, 205)",
      color: "rgb(0, 82, 73)",
    },
    {
      title: "Registrations",
      key: "registrations",
      bgcolor: "rgb(208, 242, 255)",
      color: "rgb(4, 41, 122)",
    },
    {
      title: "Users",
      key: "users",
      bgcolor: "rgb(255, 247, 205)",
      color: "rgb(122, 79, 1)",
    },
  ];

  const [reportSummary, setReportSummary] = useState<ReportSummary>({
    liveClasses: { count: 0, dateTs: 0 },
    registrations: { count: 0, dateTs: 0 },
    users: { count: 0, dateTs: 0 },
  });

  const [loading, setLoading] = useState({
    liveClasses: false,
    registrations: false,
    users: false,
  });

  const handleRefresh = async (data: ReportSummaryKeys) => {
    setLoading({ ...loading, [data]: true });
    let count = 0;
    switch (data) {
      case "liveClasses":
        count = await LiveClassService.readLiveClassCount();
        break;
      case "registrations":
        count = await UserService.readUserProductCount();
        break;
      case "users":
        count = await UserService.readAllUserCount();
        break;
    }
    // const counts: ReportCount = await ReportService.readCount(`/${data}`);

    const report = {
      ...reportSummary,
      [data]: { count, dateTs: moment().valueOf() },
    };
    setReportSummary(report);
    setLoading({ ...loading, [data]: false });
    localStorage.setItem("reportSummary", JSON.stringify(report));
  };

  useEffect(() => {
    const reportFromStorage = localStorage.getItem("reportSummary");
    if (!reportFromStorage) return;
    const report = JSON.parse(reportFromStorage);
    setReportSummary(report);
  }, []);

  return (
    <Grid container spacing={3} sx={{ mb: "2rem" }}>
      {stats.map((stat) => (
        <Grid item xs={12} md={4} key={stat.key}>
          <Tooltip title={STRINGS.CLICK_TO_REFRESH}>
            <Card
              elevation={1}
              sx={{
                bgcolor: stat.bgcolor,
                cursor: "pointer",
                position: "relative",
              }}
              onClick={() => handleRefresh(stat.key as ReportSummaryKeys)}
            >
              <CardContent>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography variant="h2" color={stat.color}>
                    {reportSummary[stat.key as ReportSummaryKeys].count}
                  </Typography>
                  <Typography variant="h6" color={stat.color}>
                    {stat.title}
                  </Typography>
                  <Typography variant="caption" color={stat.color}>
                    {STRINGS.LAST_UPDATED}{" "}
                    <Moment format="MMM D, yyyy HH:mm:ss">
                      {reportSummary[stat.key as ReportSummaryKeys].dateTs}
                    </Moment>
                  </Typography>
                </Box>
              </CardContent>
              {loading[stat.key as ReportSummaryKeys] ? (
                <CircularProgress
                  sx={{
                    color: stat.color,
                    position: "absolute",
                    left: 0,
                    right: 0,
                    top: "45%",
                    mx: "auto",
                  }}
                />
              ) : null}
            </Card>
          </Tooltip>
        </Grid>
      ))}
    </Grid>
  );
};

export default Statistics;

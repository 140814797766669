import { get, ref, remove, set, update } from 'firebase/database'
import { Sponsor } from '../interfaces'
import { db } from './firebaseApp'
const sponsorRefPath: string = '/lcSupportList/english'
const SponsorService = {
	async readSponsors(): Promise<Sponsor[]> {
		const sponsorRef = ref(db, sponsorRefPath)
		const snapshot = await get(sponsorRef)
		const sponsors: Sponsor[] = []
		snapshot.forEach((snap) => {
			sponsors.push(snap.val())
		})
		return sponsors
	},
	async createSponsor(sponsor: Sponsor) {
		const sponsorRef = ref(db, `${sponsorRefPath}/${sponsor.lcSupportId}`)
		await set(sponsorRef, sponsor)
	},
	async updateSponsor(sponsor: Sponsor, lcSupportId: string) {
		const newSponsorRef = ref(db, `${sponsorRefPath}/${sponsor.lcSupportId}`)
		if (sponsor.lcSupportId !== lcSupportId) {
			const currentSponsorRef = ref(db, `${sponsorRefPath}/${lcSupportId}`)
			await set(newSponsorRef, sponsor)
			await remove(currentSponsorRef)
		} else {
			await update(newSponsorRef, sponsor)
		}
	},
	async readOneSponsor(lcSupportId: string): Promise<Sponsor> {
		const sponsorRef = ref(db, `${sponsorRefPath}/${lcSupportId}`)
		const snapshot = await get(sponsorRef)
		return snapshot.val()
	},
	async deleteSponsor(lcSupportId: string) {
		const sponsorRef = ref(db, `${sponsorRefPath}/${lcSupportId}`)
		await remove(sponsorRef)
	},
}

export default SponsorService

import {
  Button,
  Stack,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import GeneralTableComponent from "../../../../components/GeneralTableComponent";
import { Cell, Session } from "../../../../interfaces";
import SessionService from "../../../../services/session.service";
import ViewSessionDialog from "./ViewSessionDialog";
import ViewUsersDialog from "./ViewUsersDialog";

export default function SessionsTable() {
  const STRINGS = {
    VIEW_USERS: "View Users",
    VIEW_SESSION: "View Session",
  };

  const cells: Cell[] = [
    { name: "Title", align: "left" },
    { name: "Actions", align: "right" },
  ];

  const { lcId }: any = useParams();

  // data
  const [sessions, setSessions] = useState<Session[]>([]);
  const [loading, setLoading] = useState(true);
  const call = async () => {
    setLoading(true);
    const sessions = await SessionService.readAllSessions(lcId);
    setSessions(sessions);
    setLoading(false);
  };

  // page
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // view session details
  const [viewSession, setViewSession] = useState<Session | null>();
  const handleOpenSession = (index: number) => {
    const session = sessions[index];
    setViewSession(session);
  };
  const handleCloseSession = () => setViewSession(null);

  // view users
  const [viewSessionUsers, setViewSessionUsers] = useState("");
  const handleOpenViewSessionUsers = (index: number) => {
    const session = sessions[index];
    setViewSessionUsers(session.sessionId);
  };
  const handleCloseViewSessionUsers = () => setViewSessionUsers("");

  useEffect(() => {
    call();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GeneralTableComponent onRefresh={call} loading={loading}>
      <TableHead>
        <TableRow>
          {cells.map((cell) => (
            <TableCell align={cell.align} key={cell.name}>
              {cell.name}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {(rowsPerPage > 0
          ? sessions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          : sessions
        ).map(({ sessionTitle, sessionId }, index) => (
          <TableRow key={sessionId}>
            <TableCell align="left">{sessionTitle}</TableCell>
            <TableCell align="right">
              <Stack spacing={2} direction="row" justifyContent="flex-end">
                <Button
                  color="secondary"
                  onClick={() => handleOpenViewSessionUsers(index)}
                >
                  {STRINGS.VIEW_USERS}
                </Button>
                <Button
                  color="secondary"
                  variant="outlined"
                  onClick={() => handleOpenSession(index)}
                  // component={Link}
                  // to={`${url}/${sessionId}`}
                  // sx={{ textDecoration: "none" }}
                >
                  {STRINGS.VIEW_SESSION}
                </Button>
              </Stack>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      {viewSessionUsers ? (
        <ViewUsersDialog
          sessionId={viewSessionUsers}
          handleClose={handleCloseViewSessionUsers}
        />
      ) : null}
      {viewSession ? (
        <ViewSessionDialog
          session={viewSession}
          handleClose={handleCloseSession}
          refreshCallback={call}
        />
      ) : null}

      <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            count={sessions.length}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableRow>
      </TableFooter>
    </GeneralTableComponent>
  );
}

import { Add, Remove, Search } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  Stack,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import GeneralDialogComponent from "../../../../components/GeneralDialogComponent";
import { REGEX } from "../../../../constants/regex.constant";
import { Group, GroupUser, User } from "../../../../interfaces";
import GroupService from "../../../../services/group.service";
import UserService from "../../../../services/user.service";
import { makeAvatarName } from "../../../../utils/makeAvatarName";

export default function AddUserToGroupDialog(props: Props) {
  const STRINGS = {
    ADD_USER_TO_GROUP: "Add User To Group",
    ADD: "Add",
    SELECTED_USERS: "Selected Users",
    RESULTS: "Results",
    NO_RESULTS: "No Results",
    SUCCESS_ADD_USERS: "Successfully Added Users To Group",
    USER_EXIST: "User Was Added To This Group",
    INSUFFICIENT_SLOTS: "Insufficient Slots Available",
    INVALID_EMAIL: "Invalid Email Format",
    USER_SELECTED: "User Was Selected",
  };

  const { group, handleClose } = props;
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const [input, setInput] = useState("");
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [disableSubmit, setDisableSubmit] = useState(true);

  const handleSubmit = async () => {
    if (group.slotsAvailable < users.length) {
      enqueueSnackbar(STRINGS.INSUFFICIENT_SLOTS, { variant: "error" });
      return;
    }

    try {
      setDisableSubmit(true);
      const groupUsers: GroupUser[] = selectedUsers.map((user) => ({
        userName: user.full_name || user.userName,
        userEmail: user.email,
        userUid: user.userUid,
        groupId: group.groupId,
        groupName: group.groupTitle,
      }));

      await GroupService.addManyUserToGroup(groupUsers);
      enqueueSnackbar(STRINGS.SUCCESS_ADD_USERS, { variant: "success" });
      handleClose();
      history.go(0);
    } catch (error: any) {
      setDisableSubmit(false);
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const handleSearch = async (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!input.match(REGEX.EMAIL))
      return enqueueSnackbar(STRINGS.INVALID_EMAIL, { variant: "error" });

    const users = await UserService.readAllUserBySearching(input);
    if (!users.length)
      return enqueueSnackbar(STRINGS.NO_RESULTS, { variant: "warning" });

    // check user existence in the group
    const usersInGroup = await GroupService.readAllGroupUser(group.groupId);
    const userExists = usersInGroup.findIndex(
      (user) => user.userUid === users[0].userUid
    );
    if (userExists !== -1)
      return enqueueSnackbar(STRINGS.USER_EXIST, { variant: "warning" });

    const userSelected = selectedUsers.findIndex(
      (user) => user.userUid === users[0].userUid
    );
    if (userSelected !== -1)
      return enqueueSnackbar(STRINGS.USER_SELECTED, { variant: "warning" });

    setUsers(users);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setDisableSubmit(false);
    setInput(e.target.value);
  };

  const handleAdd = (uid: string) => {
    // find the user's index to remove from 'users'
    const userIndex = users.findIndex((user) => user.userUid === uid);
    if (userIndex === -1) return;

    // add into selected users
    setSelectedUsers([...selectedUsers, users[userIndex]]);

    // remove from results' users
    const newUsers = [
      ...users.slice(0, userIndex),
      ...users.slice(userIndex + 1),
    ];
    setUsers(newUsers);
  };

  const handleRemove = (uid: string) => {
    // find the user's index to remove from 'users'
    const userIndex = selectedUsers.findIndex((user) => user.userUid === uid);
    if (userIndex === -1) return;

    // add into selected users
    setUsers([...users, selectedUsers[userIndex]]);

    // remove from results' users
    const newUsers = [
      ...selectedUsers.slice(0, userIndex),
      ...selectedUsers.slice(userIndex + 1),
    ];
    setSelectedUsers(newUsers);
  };

  useEffect(() => {
    if (group.slotsAvailable <= selectedUsers.length) {
      enqueueSnackbar(STRINGS.INSUFFICIENT_SLOTS, { variant: "warning" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUsers]);

  return (
    <GeneralDialogComponent
      open
      onClose={handleClose}
      title={STRINGS.ADD_USER_TO_GROUP}
      actions={
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={
            !selectedUsers.length ||
            disableSubmit ||
            group.slotsAvailable < selectedUsers.length
          }
        >
          {STRINGS.ADD}
        </Button>
      }
    >
      <Box
        component="form"
        onSubmit={handleSearch}
        width="100%"
        display="flex"
        alignItems="center"
      >
        <TextField
          type="email"
          label="User Email"
          placeholder="Enter user email"
          fullWidth
          onChange={handleChange}
        />

        {/* seach icon */}
        <InputAdornment position="end">
          <IconButton size="small" type="submit">
            <Search fontSize="small" />
          </IconButton>
        </InputAdornment>
      </Box>

      <Stack>
        <ListSubheader>{STRINGS.SELECTED_USERS}</ListSubheader>
        <List dense>
          {selectedUsers.map(
            ({ userUid, userPhoto, full_name, userName, email }) => (
              <ListItem
                key={userUid}
                secondaryAction={
                  <IconButton edge="end" onClick={() => handleRemove(userUid)}>
                    <Remove />
                  </IconButton>
                }
              >
                <ListItemAvatar>
                  <Avatar src={userPhoto || ""}>
                    {makeAvatarName(full_name)}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={full_name || userName}
                  secondary={email}
                />
              </ListItem>
            )
          )}
        </List>

        <Divider />

        <ListSubheader>{STRINGS.RESULTS}</ListSubheader>
        <List dense>
          {users.map(({ userUid, userPhoto, full_name, userName, email }) => (
            <ListItem
              key={userUid}
              secondaryAction={
                <IconButton edge="end" onClick={() => handleAdd(userUid)}>
                  <Add />
                </IconButton>
              }
            >
              <ListItemAvatar>
                <Avatar src={userPhoto || ""}>
                  {makeAvatarName(full_name)}
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={full_name || userName} secondary={email} />
            </ListItem>
          ))}
        </List>
      </Stack>
    </GeneralDialogComponent>
  );
}

interface Props {
  group: Group;
  handleClose: () => void;
}

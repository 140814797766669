import React, { useState } from "react";
import AppContainer from "../../../containers/AppContainer";
import { useRouteMatch, useParams } from "react-router-dom";
import BreadcrumbsComponent from "../../../components/Breadcrumbs";
import Table from "./components/UserTable";
import Details from "./components/LiveClassDetail";

export default function LiveClassDetailsPage() {
  const { url } = useRouteMatch();
  const { lcId }: any = useParams();
  const [refresh, setRefresh] = useState(false);

  return (
    <AppContainer headerTitle={"Class Details"}>
      <BreadcrumbsComponent url={url} />
      <Details lcId={lcId} refresh={refresh} setRefresh={setRefresh} />
      <Table url={url} lcId={lcId} refresh={refresh} />
    </AppContainer>
  );
}

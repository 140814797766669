import { Info } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { InfoField } from "../interfaces";
import InfoFieldService from "../services/infoField.service";
import cleanValues from "../utils/cleanValues";
import { convertTitleToId } from "../utils/convertString";
import GeneralDialogComponent from "./GeneralDialogComponent";
import RichtextEditor from "./RichTextEditor";

const InfoComponent = (props: Props) => {
  const STRINGS = {
    NO_TITLE: "Untitled",
    NO_HTML: "No description added. Click 'Edit' button to add a description.",
    EDIT: "Edit",
    DONE: "Done",
    CLOSE: "Close",
  };
  const { section, textFieldName } = props;

  const [infoField, setInfoField] = useState<InfoField>();
  const [form, setForm] = useState({
    infoFieldHtml: "",
    infoFieldId: convertTitleToId(`${section} ${textFieldName}`),
    infoFieldTitle: "",
  });
  const [state, setState] = useState({
    open: false,
    loading: true,
    editing: false,
    submitting: false,
  });

  const handleOpen = () => setState({ ...state, open: true });
  const handleClose = () => setState({ ...state, open: false });
  const handleEditing = () => setState({ ...state, editing: true });
  const handleChangeTitle = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setForm({
      ...form,
      infoFieldTitle: e.target.value,
    });
  };
  const handleChangeHtml = (value: string) => {
    setForm({
      ...form,
      infoFieldHtml: value,
    });
  };

  const handleEditHtml = async () => {
    setState({ ...state, submitting: true });
    const massagedBody: InfoField = cleanValues(form);
    await InfoFieldService.writeInfoField(section, textFieldName, massagedBody);
    setState({ ...state, submitting: false, editing: false });
    setInfoField(massagedBody);
  };

  const call = async () => {
    const infoField = await InfoFieldService.readOneInfoField(
      section,
      textFieldName
    );
    setInfoField(infoField);
    if (infoField) setForm(infoField);
    setState({ ...state, loading: false });
  };

  useEffect(() => {
    call();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <IconButton onClick={handleOpen}>
        <Info />
      </IconButton>
      <GeneralDialogComponent
        open={state.open}
        actions={
          <>
            {state.editing ? (
              <Button onClick={handleEditHtml} disabled={state.submitting}>
                {STRINGS.DONE}
              </Button>
            ) : (
              <Button onClick={handleEditing}>{STRINGS.EDIT}</Button>
            )}
            <Button onClick={handleClose}>{STRINGS.CLOSE}</Button>
          </>
        }
        title={infoField?.infoFieldTitle || STRINGS.NO_TITLE}
        onClose={handleClose}
      >
        {state.loading ? (
          <CircularProgress />
        ) : state.editing ? (
          <Stack spacing={2}>
            <TextField
              fullWidth
              type="text"
              label="Title"
              name="infoFieldTitle"
              variant="outlined"
              margin="normal"
              value={form.infoFieldTitle}
              disabled={state.submitting}
              InputLabelProps={{ shrink: true }}
              onChange={handleChangeTitle}
            />
            <RichtextEditor
              fieldLabel="Content"
              html={infoField?.infoFieldHtml || ""}
              setFormHtml={handleChangeHtml}
            />
          </Stack>
        ) : infoField ? (
          <Box
            component="div"
            dangerouslySetInnerHTML={{ __html: infoField.infoFieldHtml }}
          />
        ) : (
          <Typography variant="body1">{STRINGS.NO_HTML}</Typography>
        )}
      </GeneralDialogComponent>
    </>
  );
};

interface Props {
  section: string;
  textFieldName: string;
}

export default InfoComponent;

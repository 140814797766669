import React, {
  ChangeEvent,
  HTMLInputTypeAttribute,
  useEffect,
  useState,
} from "react";
import { Divider, Grid, TextField, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useHistory, useParams } from "react-router-dom";

import moment from "moment";
import {
  Category,
  CategoryErrors,
  CategoryKeys,
  EditCategory,
  MakeTextFields,
} from "../../../../interfaces";
import { MakeTextField } from "../../../../interfaces";
import InfoComponent from "../../../../components/InfoComponent";
import { REGEX } from "../../../../constants/regex.constant";
import { convertTitleToId } from "../../../../utils/convertString";
import CategoryService from "../../../../services/category.service";
import RichTextEditor from "../../../../components/RichTextEditor";
import VerticalDivider from "../../../../components/VerticalDivider";
import CategoryActionButtons from "./CategoryActionButtons";
import { checkTextFieldError } from "../../../../utils/checkTextField";
import cleanValues from "../../../../utils/cleanValues";
import GeneralAvatarComponent from "../../../../components/GeneralAvatarComponent";

const CategoryDetailsForm = () => {
  const STRINGS = {
    CATECGORY_UPDATE_SUCCESS: "Category updated successfully",
    MAIN_DETAILS: "Main Details",
    RICH_EDITOR_LABLE: "Category HTML",
  };
  const { lcCategoryId }: any = useParams();

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [form, setForm] = useState<EditCategory>({} as EditCategory);
  const [errors, setErrors] = useState<CategoryErrors>({} as CategoryErrors);
  const [disableSubmit, setDisableSubmit] = useState(true);

  // rich text editor
  const [html, setHtml] = useState<string>("");
  const [htmlError, setHtmlError] = useState<boolean>(false);

  // to enable the update button onchange
  const setHtmlForm = (editorContent: string) => {
    setDisableSubmit(false);
    setHtml(editorContent);
  };

  const makeTextField = (
    label: string,
    type: HTMLInputTypeAttribute | "textarea" | "autocomplete",
    key: CategoryKeys,
    pattern: RegExp,
    required: boolean = false,
    disabled: boolean = false
  ): MakeTextField<EditCategory> => ({
    label,
    type: type === "autocomplete" ? "autocomplete" : type,
    key,
    pattern,
    required,
    disabled: disabled,
    value:
      type === "datetime-local"
        ? // TODO: why do we need unknown here
          moment(form[key] as unknown as number).format("D MMM yyyy HH:mm:ss ")
        : form[key],
    error: errors[key],
    helperText: errors[key]
      ? required && !form[key]
        ? "This field is required"
        : `Invalid ${label} format`
      : "",
    name: key,
    multiline: type === "textarea",
    rows: 4,
  });

  const textFields: MakeTextFields<EditCategory> = {
    lcCategoryName: makeTextField(
      "Name",
      "text",
      "lcCategoryName",
      REGEX.ANY,
      true
    ),
    lcCategoryDesc: makeTextField(
      "Description",
      "textarea",
      "lcCategoryDesc",
      REGEX.ANY
    ),
    lcCategoryImage: makeTextField(
      "Image",
      "text",
      "lcCategoryImage",
      REGEX.IMAGE,
      true
    ),
    lcCategoryHtml: makeTextField(
      "Category Html",
      "textarea",
      "lcCategoryHtml",
      REGEX.ANY
    ),
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setDisableSubmit(false);
    const { required, value } = e.target;
    const name = e.target.name as CategoryKeys;
    setForm({ ...form, [name]: value });

    // this is to check if the text field has error
    // 1. if field is '', return TRUE if field is required
    // 2. if field has value, return TRUE if pattern is wrong
    const pattern = textFields[name].pattern;
    const error: boolean = !value
      ? required
      : !new RegExp(pattern)?.test(value);
    setErrors({ ...errors, [name]: error });
  };

  const handleSubmit = async () => {
    setDisableSubmit(true);

    // validate form
    const hasError = Object.values(textFields).some((textField) => {
      const { name, label } = textField;
      const error: boolean = checkTextFieldError(textField, form);
      if (error) {
        setDisableSubmit(false);
        setErrors({ ...errors, [name]: error });
        enqueueSnackbar(`Invalid ${label} format`, { variant: "error" });
      }
      return error;
    });

    if (hasError) return;

    const massagedForm: Category = cleanValues({
      ...form,
      lcCategoryId: convertTitleToId(form.lcCategoryName),
      lcCategoryImage: form.lcCategoryImage || "",
      lcCategoryHtml: html || "",
      lcCategoryColor: "",
      lcCategoryDesc: form.lcCategoryDesc || "",
    });
    try {
      await CategoryService.updateCategory(massagedForm, lcCategoryId);
      history.push("/category");
    } catch (error: any) {
      setDisableSubmit(false);
      console.error("error:", error);
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };
  useEffect(() => {
    (async () => {
      const category = await CategoryService.readOneCategory(lcCategoryId);
      const mergedData: EditCategory = {
        ...category,
      };
      setForm((curr) => {
        return mergedData;
      });

      console.table(mergedData);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Grid container>
      <Grid item xs={12} lg={8}>
        {/* main data */}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ marginTop: "3rem" }}>
              {STRINGS.MAIN_DETAILS}
            </Typography>
          </Grid>

          {/* profile photo */}
          <Grid item xs={12}>
            <GeneralAvatarComponent src={form.lcCategoryImage} large />
          </Grid>

          {Object.values(textFields)
            .slice(0, 3)
            .map((textField) => (
              <Grid item xs={12} key={textField.key}>
                <Grid container alignItems="center">
                  <Grid item xs={10}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      margin="normal"
                      onChange={handleChange}
                      InputLabelProps={{ shrink: true }}
                      {...textField}
                    />
                  </Grid>
                  <Grid item xs={2} justifySelf="flex-end">
                    <InfoComponent
                      section="category"
                      textFieldName={textField.name}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ))}
          <Grid item xs={12}>
            <Grid container alignItems="center">
              <Grid item xs={10}>
                <RichTextEditor
                  fieldLabel="Category HTML"
                  html={form.lcCategoryHtml}
                  setFormHtml={setHtmlForm}
                  setHtmlError={setHtmlError}
                />
              </Grid>

              <Grid item xs={2}>
                <InfoComponent
                  section="category"
                  textFieldName="lcCategoryHtml"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Divider sx={{ my: "2rem" }} />

        {/* submit button */}

        {/* <Box width='100%' mt='1rem' display='flex' justifyContent='flex-end'>
          <Button
            variant='contained'
            onClick={handleSubmit}
            // find if there is any errors
            disabled={Object.values(errors).find((error) => error === true) || disableSubmit}>
            {STRINGS.SUBMIT}
          </Button>
        </Box> */}
      </Grid>

      <VerticalDivider />
      <CategoryActionButtons
        disabled={disableSubmit}
        handleUpdate={handleSubmit}
      />
    </Grid>
  );
};

export default CategoryDetailsForm;

import { Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import GeneralDialogComponent from "../../../../components/GeneralDialogComponent";
import { GroupUser } from "../../../../interfaces";
import LiveClassService from "../../../../services/liveclass.service";

interface Props {
  handleClose: () => void;
}
const ViewEmailsDialog = (props: Props) => {
  const { handleClose } = props;
  const STRINGS = {
    TITLE: "View Emails",
    TOTAL_STUDENTS: "Total Students",
    LOADING: "Loading...",
    NO_USERS: "No Users",
    DONE: "Done",
  };
  const { lcId }: any = useParams();

  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<GroupUser[]>([]);

  const call = async () => {
    setLoading(true);
    const results = await LiveClassService.readClassUsers(lcId);
    setUsers(results);
    setLoading(false);
  };

  useEffect(() => {
    call();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GeneralDialogComponent
      open
      title={STRINGS.TITLE}
      onClose={handleClose}
      actions={
        <>
          <Button onClick={handleClose}>{STRINGS.DONE}</Button>
        </>
      }
    >
      <Typography variant="body1">
        {STRINGS.TOTAL_STUDENTS}: {users.length}
      </Typography>
      <Typography variant="body2">
        {loading
          ? STRINGS.LOADING
          : users.length
          ? users.reduce(
              (prev, curr, index) =>
                // combine all users' email together with commas, but do not put comma for last user's email
                `${prev} ${curr.userEmail}${
                  index !== users.length - 1 ? "," : ""
                }`,
              ""
            )
          : STRINGS.NO_USERS}
      </Typography>
    </GeneralDialogComponent>
  );
};

export default ViewEmailsDialog;

import {
  Autocomplete,
  Grid,
  TextField,
  Divider,
  ListItem,
  ListItemText,
} from "@mui/material";
import React, {
  ChangeEvent,
  HTMLInputTypeAttribute,
  SyntheticEvent,
  useEffect,
  useState,
} from "react";
import moment from "moment";
import { REGEX } from "../../../../../constants/regex.constant";
import {
  CreateParentClassForm,
  CreateParentClassFormKeys,
  ParentLiveClass,
  ParentLiveClassForm,
} from "../../../../../interfaces";
import LpService from "../../../../../services/lp.service";
import { useAuth } from "../../../../../utils/providers/AuthProvider";
import { useHistory } from "react-router-dom";
import {
  AutoCompleteOption,
  MakeTextField,
  MakeTextFields,
} from "../../../../../interfaces/TextField.interface";
import VerticalDivider from "../../../../../components/VerticalDivider";
// import CreateClassButtons from '../../CreateLiveClass/components/CreateClassButtons'
import { CreateClassFormTemplate } from "./EmptyFormTemplate";
import { make } from "../../../../../utils/makeAutoCompleteOption";
import { useSnackbar } from "notistack";
import { convertTitleToId } from "../../../../../utils/convertString";
import LiveClassService from "../../../../../services/liveclass.service";
import DraftDialog from "./DraftDialog";
import CreateClassButtons from "./CreateClassButtons";
import InfoComponent from "../../../../../components/InfoComponent";
import CreateCategoryForm from "../../../../Category/CreateCategory/components/CreateCategoryForm";
import RichTextEditor from "../../../../../components/RichTextEditor";
import { checkTextFieldError } from "../../../../../utils/checkTextField";
import cleanValues from "../../../../../utils/cleanValues";
import GeneralAvatarComponent from "../../../../../components/GeneralAvatarComponent";
import { LIVE_CLASS_STATUS } from "../../../../../constants/LIVE_CLASS_STATUS";

const ParentClassCreateForm = (props: CreateParentClassFormProps) => {
  const STRINGS = {
    SUCCESS_SAVE_CLASS: "Successfully saved parent live class as draft",
    FAILED_SAVE_CLASS: "Failed to save live class as draft",
    SUCCESS_PUBLISH_CLASS: "Successfully published parent live class",
    FAILED_PUBLISH_CLASS: "Failed to publish parent live class",
    SOMETHING_WENT_WRONG: "Something went wrong",
    SUBMIT: "Submit",
    ADD_CATEGORY: "Add Category",
    SUCCESS_ADD_CATEGORY: "Successfully added category",
    RICH_EDITOR_LABLE: "Parent Live Class HTML",
  };

  const { handleParentFormPublish, handleParentFormSubmit, isDialog } = props;

  let history = useHistory();
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(false);
  const [drafts, setDrafts] = useState<ParentLiveClass[]>([]);
  const [fromDraft, setFromDraft] = useState(false);

  const [form, setForm] = useState<ParentLiveClassForm>(
    CreateClassFormTemplate as ParentLiveClassForm
  );
  const [errors, setErrors] = useState<FormErrors>({} as FormErrors);
  const [options, setOptions] =
    useState<AutoCompleteOptions>(autoCompleteOptions);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const handleClose = () => setOpen(false);

  // rich text editor
  const [html, setHtml] = useState<string>("");
  const [htmlError, setHtmlError] = useState<boolean>(false);

  // category dialog
  const [categoryDialog, setCategoryDialog] = useState(false);
  const HandleOpenCategoryDialog = () => setCategoryDialog(true);
  const HandleCloseCategoryDialog = () => setCategoryDialog(false);

  const makeTextField = (
    label: string,
    type: HTMLInputTypeAttribute | "textarea" | "autocomplete",
    key: CreateParentClassFormKeys,
    pattern: RegExp,
    required: boolean = false,
    disabled: boolean = false
  ): MakeTextField<CreateParentClassForm> => ({
    label,
    type: type === "autocomplete" ? "autocomplete" : type,
    key,
    pattern,
    disabled,
    value:
      type === "datetime-local"
        ? moment(form[key]).format("YYYY-MM-DDTHH:mm")
        : form[key],
    error: errors[key],
    helperText: errors[key]
      ? required && !form[key]
        ? "This field is required"
        : `Invalid ${label}`
      : "",
    required,
    name: key,
    multiline: type === "textarea",
    rows: 4,
  });

  const textFields: MakeTextFields<CreateParentClassForm> = {
    lcDescHtml: makeTextField(
      "Parent Live Class HTML",
      "textarea",
      "lcDescHtml",
      REGEX.ANY
    ),
    lpTitle: makeTextField(
      "Parent Live Class Title",
      "text",
      "lpTitle",
      REGEX.ANY,
      true
    ),
    lpCategoryId: makeTextField(
      "Parent Live Class Category",
      "autocomplete",
      "lpCategoryId",
      REGEX.ANY,
      true
    ),
    lpImage: makeTextField(
      "Parent Live Class Image",
      "text",
      "lpImage",
      REGEX.IMAGE
    ),
    lpDesc: makeTextField(
      "Parent Live Class Description",
      "textarea",
      "lpDesc",
      REGEX.ANY,
      false,
      false
    ),
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setDisableSubmit(false);
    setErrors({} as FormErrors);

    const { value } = e.target;
    const name = e.target.name as CreateParentClassFormKeys;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = async () => {
    setDisableSubmit(true);

    // validate form
    const hasError = Object.values(textFields).some((textField) => {
      const { name, label } = textField;
      const error: boolean = checkTextFieldError(textField, form);
      if (error) {
        setDisableSubmit(false);
        setErrors({ ...errors, [name]: error });
        enqueueSnackbar(`Invalid ${label} format`, { variant: "error" });
      }
      return error;
    });

    if (hasError) return;

    const massagedForm: ParentLiveClass = cleanValues({
      ...form,
      lpStartDateTs: 0,
      lpEndDateTs: 0,
      lpId: convertTitleToId(form.lpTitle),
      lpCategory:
        options.lpCategoryId.find(
          (category) => category.value === form.lpCategoryId
        )?.title || "",

      createdAt: moment().valueOf(),
      updatedAt: moment().valueOf(),
      updatedBy: user?.email || "",
      lpStatus: LIVE_CLASS_STATUS.DRAFT,
      lcIndex: 99999999, // TODO: 99999999 - ${parent classes count from DB}
      lpIndexAsc: 0,
    });

    if (fromDraft) {
      try {
        await LpService.deleteDraft(massagedForm.lpId);
        console.log("draft deleted from database");
        setDisableSubmit(false);
      } catch (error) {
        console.error("error:", error);
        setDisableSubmit(false);
      }
    }
    try {
      await LpService.createDraft(massagedForm);
      console.log("updated");
      setFromDraft(false);
      enqueueSnackbar(STRINGS.SUCCESS_SAVE_CLASS, {
        variant: "success",
      });
      setDisableSubmit(false);

      if (isDialog && handleParentFormSubmit) {
        // close the form popup once published
        return handleParentFormSubmit();
      }
      history.push("/parent-class");
    } catch (error) {
      console.error("error:", error);
      enqueueSnackbar(STRINGS.FAILED_SAVE_CLASS, {
        variant: "error",
      });
      setDisableSubmit(false);
    }
  };

  const handlePublish = async () => {
    setDisableSubmit(true);

    // validate form
    const hasError = Object.values(textFields).some((textField) => {
      const { name, label } = textField;
      const error: boolean = checkTextFieldError(textField, form);
      if (error) {
        setDisableSubmit(false);
        setErrors({ ...errors, [name]: error });
        enqueueSnackbar(`Invalid ${label} format`, { variant: "error" });
      }
      return error;
    });

    if (hasError) return;
    if (htmlError) {
      enqueueSnackbar("Invalid HTML format", { variant: "error" });
      return;
    }

    const massagedForm: ParentLiveClass = cleanValues({
      ...form,
      lpStartDateTs: (moment(form.lpStartDateTs).valueOf() as number) || "",
      lpEndDateTs: (moment(form.lpEndDateTs).valueOf() as number) || "",
      lpId: convertTitleToId(form.lpTitle),
      lpCategory:
        options.lpCategoryId.find(
          (category) => category.value === form.lpCategoryId
        )?.title || "",
      createdAt: moment().valueOf(),
      updatedAt: moment().valueOf(),
      updatedBy: user?.email || "",
      lpStatus: LIVE_CLASS_STATUS.PUBLISHED,
      // TODO: ask about indexes
      lcIndex: 99999999,
      lpIndexAsc: 0,
      lcDescHtml: html,
    });

    if (fromDraft) {
      try {
        //NOTE:  the draft is deleted from the database only if you publish it without changing the title
        await LpService.deleteDraft(massagedForm.lpId);
        setDisableSubmit(false);
      } catch (error) {
        console.error("error:", error);
        setDisableSubmit(false);
      }
    }
    try {
      await LpService.publishParentLiveClass(massagedForm);

      if (isDialog && handleParentFormPublish) {
        handleParentFormPublish();
      }

      console.log("publish to live class");
      setFromDraft(false);

      if (!isDialog) {
        history.replace(`/parent-class/${massagedForm.lpId}`);
      }
      enqueueSnackbar("Parent class is created successfully", {
        variant: "success",
      });
      setDisableSubmit(false);
    } catch (error) {
      console.error("error:", error);
      setDisableSubmit(false);
    }
  };

  const handleUseDraft = async (lcId: string) => {
    const draft = drafts.find((draft) => draft.lpId === lcId);
    if (!draft) {
      enqueueSnackbar(STRINGS.SOMETHING_WENT_WRONG, { variant: "warning" });
      return;
    }
    setForm(draft as ParentLiveClassForm);
    setFromDraft(true);
    handleClose();
    setDisableSubmit(false);
  };

  const handleDeleteDraft = async (lpId: string) => {
    await LpService.deleteDraft(lpId);
    const draftToDelete = drafts.findIndex((draft) => draft.lpId === lpId);
    if (draftToDelete === -1) return;
    setDrafts([
      ...drafts.slice(0, draftToDelete),
      ...drafts.slice(draftToDelete + 1),
    ]);
  };

  const handleReset = () => {
    setForm(CreateClassFormTemplate);
    handleClose();
    setDisableSubmit(true);
  };

  const handleAutoCompleteChange = (
    e: SyntheticEvent<Element, Event>,
    newValue: AutoCompleteOption | null,
    name: CreateParentClassFormKeys
  ) => {
    e.preventDefault();
    setDisableSubmit(false);
    setErrors({} as FormErrors);

    setForm({ ...form, [name]: newValue?.value });
  };

  const loadOptions = async () => {
    const categories = await LiveClassService.readCategories();

    console.log("categories: ", categories);
    setOptions({
      ...options,
      lpCategoryId: categories.map((cat) => ({
        title: cat.lcCategoryName,
        value: cat.lcCategoryId,
        subtitle: cat.lcCategoryDesc,
      })),
    });
  };

  useEffect(() => {
    (async () => {
      try {
        const drafts = await LpService.readAllDrafts();
        setDrafts(drafts);
        console.log("reading drafts", drafts);
      } catch (e) {
        console.error(e);
      }
      await loadOptions();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} lg={isDialog ? 12 : 8}>
        <Grid container alignItems="center" my="1rem">
          <Grid item xs={12}>
            <GeneralAvatarComponent src={form.lpImage} large />
          </Grid>
          <Grid container spacing={3}>
            {Object.values(textFields)
              .slice(1)
              .map((textField) => (
                <Grid item xs={12} key={textField.key}>
                  <Grid container alignItems="center">
                    <Grid item xs={10}>
                      {textField.type === "autocomplete" ? (
                        <Autocomplete
                          disablePortal
                          fullWidth
                          disabled={textField.disabled}
                          options={
                            options[textField.key as AutoCompleteOptionKeys]
                          }
                          getOptionLabel={(option) => option.title}
                          renderOption={(props, option) => (
                            <ListItem {...props} title={option.title}>
                              <ListItemText
                                primary={option.title}
                                secondary={option.subtitle || ""}
                              />
                            </ListItem>
                          )}
                          isOptionEqualToValue={(option) =>
                            option.value === form[textField.key]
                          }
                          value={options[
                            textField.key as AutoCompleteOptionKeys
                          ]?.find(
                            (option) => option.value === form[textField.key]
                          )}
                          onChange={(e, newValue) =>
                            handleAutoCompleteChange(
                              e,
                              newValue,
                              textField.name
                            )
                          }
                          // random key needs to be used in order to rerender the autocomplete field after async event
                          key={`autocomplete-${textField.key}-${
                            Math.random() * 1000
                          }`}
                          renderInput={(params) => (
                            <TextField
                              margin="normal"
                              {...params}
                              {...textField}
                            />
                          )}
                        />
                      ) : (
                        <TextField
                          fullWidth
                          variant="outlined"
                          margin="normal"
                          onChange={handleChange}
                          InputLabelProps={{ shrink: true }}
                          {...textField}
                        />
                      )}
                    </Grid>
                    <Grid item xs={2}>
                      <InfoComponent
                        section="parent-class"
                        textFieldName={textField.name}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            <Grid item xs={12}>
              <Grid container alignItems="center">
                <Grid item xs={10}>
                  <RichTextEditor
                    fieldLabel={"Parent Live Class HTML"}
                    html={form.lcDescHtml}
                    setFormHtml={setHtml}
                    setHtmlError={setHtmlError}
                  />
                </Grid>

                <Grid item xs={2}>
                  <InfoComponent
                    section="parent-class"
                    textFieldName="lcDescHtml"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Divider sx={{ my: "2rem" }} />
      </Grid>

      {!isDialog ? <VerticalDivider /> : null}

      <CreateClassButtons
        disableSubmit={disableSubmit}
        disableShowDrafts={!drafts.length}
        handleSubmit={handleSubmit}
        handleReset={handleReset}
        handlePublish={handlePublish}
        handleShowDrafts={() => setOpen(true)}
        isDialog={isDialog}
        HandleOpenCategoryDialog={HandleOpenCategoryDialog}
      />

      <DraftDialog
        open={open}
        handleClose={handleClose}
        handleReset={handleReset}
        drafts={drafts}
        handleUseDraft={handleUseDraft}
        handleDeleteDraft={handleDeleteDraft}
      />

      <CreateCategoryForm
        STRINGS={STRINGS}
        handleClose={HandleCloseCategoryDialog}
        open={categoryDialog}
        loadOptions={loadOptions}
      />
    </Grid>
  );
};

export default ParentClassCreateForm;

interface AutoCompleteOptions {
  lpStatus: AutoCompleteOption[];
  lpCategoryId: AutoCompleteOption[];
}

type AutoCompleteOptionKeys = keyof AutoCompleteOptions;

const autoCompleteOptions: AutoCompleteOptions = {
  lpStatus: [
    make(LIVE_CLASS_STATUS.PUBLISHED),
    make(LIVE_CLASS_STATUS.UNPUBLISHED),
  ],
  lpCategoryId: [],
};

type FormErrors = {
  [F in CreateParentClassFormKeys]: boolean;
};

// if the form is displayed in a popup: updates the LiveClass form options after adding a new parent class
export interface CreateParentClassFormProps {
  handleParentFormSubmit?: () => void;
  handleParentFormPublish?: () => void;
  isDialog?: boolean;
}

import React from 'react'
import AppContainer from '../../../../containers/AppContainer'
import { useRouteMatch, useParams } from 'react-router-dom'
import BreadcrumbsComponent from '../../../../components/Breadcrumbs'
import EditParentClassForm from './components/EditParentClassForm'

const EditLiveClass = () => {
	const { url } = useRouteMatch()
	const { lpId }: any = useParams()

	return (
		<AppContainer headerTitle={'Class Details'}>
			<BreadcrumbsComponent url={url} />
			<EditParentClassForm lpId={lpId} />
		</AppContainer>
	)
}

export default EditLiveClass

import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import BreadcrumbsComponent from '../../../../components/Breadcrumbs'
import AppContainer from '../../../../containers/AppContainer'
import ClassParentCreateForm from './components/CreateParentClassForm'

const CreateParentLiveClass = () => {
	const { url } = useRouteMatch()

	return (
		<AppContainer headerTitle={'Create Parent Live Class'}>
			<BreadcrumbsComponent url={url} />
			<ClassParentCreateForm />
		</AppContainer>
	)
}

export default CreateParentLiveClass

import {
  Autocomplete,
  Box,
  Button,
  Grid,
  ListItem,
  ListItemText,
  Stack,
  TextField,
} from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, {
  ChangeEvent,
  HTMLInputTypeAttribute,
  SyntheticEvent,
  useEffect,
  useState,
} from "react";
import { REGEX } from "../../../../../constants/regex.constant";
import {
  MakeTextField,
  MakeTextFields,
  Session,
  SessionErrors,
  SessionKeys,
  Teacher,
} from "../../../../../interfaces";
import { AutoCompleteOption } from "../../../../../interfaces/TextField.interface";
import LiveClassService from "../../../../../services/liveclass.service";
import SessionService from "../../../../../services/session.service";
import { checkTextFieldError } from "../../../../../utils/checkTextField";
import cleanValues from "../../../../../utils/cleanValues";
import { make } from "../../../../../utils/makeAutoCompleteOption";
import { useAuth } from "../../../../../utils/providers/AuthProvider";

interface Props {
  session: Session;
  refreshCallback: () => Promise<void>;
}
export default function SessionDetailsSection(props: Props) {
  const STRINGS = {
    UPDATE: "Update",
    FAILED_FIND_TEACHER: "Failed to find teacher",
    SUCCESS_UPDATE_SESSION: "Successfully updated session!",
    FAILED_UPDATE_SESSION: "Failed to update session!",
    START_MUST_EARLIER: "Start Datetime must be earlier than End Datetime",
    START_DATE_REQUIRED: "Start Datetime is required",
    END_DATE_REQUIRED: "End Datetime is required",
  };

  const { session, refreshCallback } = props;
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const [form, setForm] = useState<Session>({} as Session);
  const [options, setOptions] =
    useState<AutoCompleteOptions>(autoCompleteOptions);
  const [errors, setErrors] = useState<SessionErrors>({} as SessionErrors);
  const [disableSubmit, setDisableSubmit] = useState(true);

  const makeTextField = (
    label: string,
    type: HTMLInputTypeAttribute | "textarea" | "autocomplete",
    key: SessionKeys,
    pattern: RegExp,
    required: boolean = false,
    disabled: boolean = false
  ): MakeTextField<Session> => ({
    label,
    type: type === "autocomplete" ? "autocomplete" : type,
    key,
    pattern,
    required,
    disabled,
    value:
      type === "datetime-local"
        ? moment(form[key]).format("yyyy-MM-DD[T]HH:mm:ss")
        : form[key],
    error: errors[key],
    helperText: errors[key]
      ? required && !form[key]
        ? "This field is required"
        : `Invalid ${label} format`
      : "",
    name: key,
    multiline: type === "textarea",
    rows: 4,
  });

  const textFields: MakeTextFields<Session> = {
    sessionId: makeTextField("", "text", "sessionId", REGEX.WORDS),
    sessionNumber: makeTextField("", "number", "sessionNumber", REGEX.NUMBER),
    teacherName: makeTextField("", "autocomplete", "teacherName", REGEX.NAME),
    teacherEmail: makeTextField("", "text", "teacherEmail", REGEX.EMAIL),
    groupTitle: makeTextField("", "text", "groupTitle", REGEX.WORDS),
    lpId: makeTextField("", "text", "lpId", REGEX.WORDS),
    lcId: makeTextField("Live Class", "text", "lcId", REGEX.WORDS, true, true),
    groupId: makeTextField("Group", "autocomplete", "groupId", REGEX.WORDS),
    createdAt: makeTextField("Created At", "text", "createdAt", REGEX.ANY),
    updatedAt: makeTextField("Updated At", "text", "updatedAt", REGEX.ANY),
    updatedBy: makeTextField("Updated By", "text", "updatedBy", REGEX.WORDS),
    totalSlots: makeTextField(
      "Total Slots",
      "number",
      "totalSlots",
      REGEX.NUMBER
    ),

    sessionTitle: makeTextField(
      "Session Title",
      "text",
      "sessionTitle",
      REGEX.ANY,
      true
    ),
    teacherId: makeTextField(
      "Teacher",
      "autocomplete",
      "teacherId",
      REGEX.WORDS,
      true
    ),
    sessionMaterial: makeTextField(
      "Material",
      "autocomplete",
      "sessionMaterial",
      REGEX.ANY
    ),
    meetingLink: makeTextField(
      "Meeting Button Link URL",
      "text",
      "meetingLink",
      REGEX.ANY
    ),
    sessionStartDateTs: makeTextField(
      "Start Datetime",
      "datetime-local",
      "sessionStartDateTs",
      REGEX.ANY,
      true
    ),
    sessionEndDateTs: makeTextField(
      "End Datetime",
      "datetime-local",
      "sessionEndDateTs",
      REGEX.ANY,
      true
    ),
    lcDetailHtml: makeTextField(
      "Live Class Details HTML",
      "autocomplete",
      "lcDetailHtml",
      REGEX.ANY
    ),
    sessionDetailHtml: makeTextField(
      "Session Details HTML",
      "textarea",
      "sessionDetailHtml",
      REGEX.ANY
    ),
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setDisableSubmit(false);
    const { value } = e.target;
    const name = e.target.name as SessionKeys;
    setForm({ ...form, [name]: value });
  };

  const handleAutoCompleteChange = (
    e: SyntheticEvent<Element, Event>,
    newValue: AutoCompleteOption | null,
    name: SessionKeys
  ) => {
    e.preventDefault();
    setDisableSubmit(false);
    setForm({ ...form, [name]: newValue?.value });
  };

  const handleSubmit = async () => {
    // revalidate form
    const hasError = Object.values(form).some((textField) => {
      const { name, label } = textField;
      const error: boolean = checkTextFieldError(textField, form);
      if (error) {
        setDisableSubmit(false);
        setErrors({ ...errors, [name]: error });
        enqueueSnackbar(`Invalid ${label} format`, { variant: "error" });
      }
      return error;
    });
    if (hasError) return;

    let teacher: Teacher;
    try {
      teacher = await LiveClassService.readOneTeacher(form.teacherId);
      if (!teacher)
        return enqueueSnackbar(STRINGS.FAILED_FIND_TEACHER, {
          variant: "error",
        });
    } catch (error: any) {
      return enqueueSnackbar(error.message || STRINGS.FAILED_FIND_TEACHER, {
        variant: "error",
      });
    }

    // check start and end date
    if (!form.sessionStartDateTs) {
      setErrors({ ...errors, sessionStartDateTs: true });
      return enqueueSnackbar(STRINGS.START_DATE_REQUIRED, { variant: "error" });
    }
    if (!form.sessionEndDateTs) {
      setErrors({ ...errors, sessionEndDateTs: true });
      return enqueueSnackbar(STRINGS.END_DATE_REQUIRED, { variant: "error" });
    }
    if (
      moment(form.sessionStartDateTs).valueOf() >
      moment(form.sessionEndDateTs).valueOf()
    ) {
      return enqueueSnackbar(STRINGS.START_MUST_EARLIER, {
        variant: "error",
      });
    }

    const massagedForm: Session = cleanValues({
      ...form,
      sessionStartDateTs: moment(form.sessionStartDateTs).valueOf(),
      sessionEndDateTs: moment(form.sessionEndDateTs).valueOf(),
      teacherEmail: teacher?.teacherEmail,
      teacherName: teacher?.teacherName,
      updatedAt: new Date().getTime(),
      updatedBy: user?.email || "",
    });

    try {
      await SessionService.updateSession(massagedForm);
      enqueueSnackbar(STRINGS.SUCCESS_UPDATE_SESSION, {
        variant: "success",
      });
      await refreshCallback();
    } catch (error) {
      return enqueueSnackbar(STRINGS.FAILED_UPDATE_SESSION, {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    (async () => {
      // get dropdown options
      const lcDetailHtmls = await LiveClassService.readLiveClassesHtml();

      const teachers = await LiveClassService.readTeachers();
      const materials = await LiveClassService.readMaterials();
      setOptions({
        lcDetailHtml: lcDetailHtmls.map((html) =>
          make(html.lcTitle, html.lcDescHtml, html.lcDescHtml)
        ),
        teacherId: teachers.map((teacher) =>
          make(teacher.teacherName, teacher.teacherId, teacher.teacherEmail)
        ),
        sessionMaterial: materials.map((material) =>
          make(
            material.materialTitle,
            material.materialID,
            material.materialLinksHtml
          )
        ),
      });

      // populate text fields
      setForm(session);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack>
      <Grid container spacing={3}>
        {Object.values(textFields)
          .slice(12)
          .map((textField) =>
            textField.type === "autocomplete" ? (
              <Grid item xs={12} sm={6} md={4} lg={3} key={textField.key}>
                <Autocomplete
                  disablePortal
                  fullWidth
                  disabled={textField.disabled}
                  options={options[textField.key as AutoCompleteOptionKeys]}
                  getOptionLabel={(option) => option.title}
                  renderOption={(props, option) => (
                    <ListItem {...props} title={option.title}>
                      <ListItemText
                        primary={option.title}
                        secondary={option.subtitle}
                      />
                    </ListItem>
                  )}
                  isOptionEqualToValue={(option) =>
                    option.value === form[textField.key]
                  }
                  value={options[textField.key as AutoCompleteOptionKeys].find(
                    (option) => option.value === form[textField.key]
                  )}
                  onChange={(e, newValue) =>
                    handleAutoCompleteChange(e, newValue, textField.name)
                  }
                  // random key needs to be used in order to rerender the autocomplete field after async event
                  key={`autocomplete-${textField.key}-${Math.random() * 1000}`}
                  renderInput={(params) => (
                    <TextField margin="normal" {...params} {...textField} />
                  )}
                />
              </Grid>
            ) : (
              <Grid item xs={12} sm={6} md={4} lg={3} key={textField.key}>
                <TextField
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                  {...textField}
                />
              </Grid>
            )
          )}
      </Grid>
      <Box display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          color="primary"
          disabled={disableSubmit}
          onClick={handleSubmit}
        >
          {STRINGS.UPDATE}
        </Button>
      </Box>
    </Stack>
  );
}

interface AutoCompleteOptions {
  lcDetailHtml: AutoCompleteOption[];
  teacherId: AutoCompleteOption[];
  sessionMaterial: AutoCompleteOption[];
}
type AutoCompleteOptionKeys = keyof AutoCompleteOptions;

const autoCompleteOptions: AutoCompleteOptions = {
  lcDetailHtml: [],
  teacherId: [],
  sessionMaterial: [],
};

import {
  Autocomplete,
  Grid,
  TextField,
  ListItem,
  ListItemText,
  Button,
} from "@mui/material";
import React, {
  ChangeEvent,
  HTMLInputTypeAttribute,
  SyntheticEvent,
  useEffect,
  useState,
} from "react";
import moment from "moment";
import { REGEX } from "../../../../constants/regex.constant";
import {
  CreateClassForm,
  CreateClassFormKeys,
  Group,
  LiveClass,
  ParentLiveClass,
  Session,
} from "../../../../interfaces";
import LiveClassService from "../../../../services/liveclass.service";
import { useAuth } from "../../../../utils/providers/AuthProvider";
import { useHistory } from "react-router-dom";
import {
  AutoCompleteOption,
  MakeTextField,
  MakeTextFields,
} from "../../../../interfaces/TextField.interface";
import VerticalDivider from "../../../../components/VerticalDivider";
import CreateClassButtons from "./CreateClassButtons";
import { CreateClassFormTemplate } from "../../../../mock/CreateClassFormTemplate";
import { useSnackbar } from "notistack";
import GroupService from "../../../../services/group.service";
import { convertTitleToId } from "../../../../utils/convertString";
import { checkTextFieldError } from "../../../../utils/checkTextField";
import SessionService from "../../../../services/session.service";
import DraftDialog from "./DraftDialog";
import GeneralDialogComponent from "../../../../components/GeneralDialogComponent";
import AddTeacherDialog from "../../../Teacher/components/AddTeacherDialog";
import InfoComponent from "../../../../components/InfoComponent";
import ParentClassCreateForm from "../../ParentLiveClass/createParentLiveClass/components/CreateParentClassForm";
import cleanValues from "../../../../utils/cleanValues";
import { make } from "../../../../utils/makeAutoCompleteOption";
import SponsorService from "../../../../services/sponsor.service";
import GeneralAvatarComponent from "../../../../components/GeneralAvatarComponent";
import { LIVE_CLASS_STATUS } from "../../../../constants/LIVE_CLASS_STATUS";

const CreateClassFormComponent = () => {
  const STRINGS = {
    SUCCESS_SAVE_CLASS: "Successfully saved live class as draft",
    FAILED_SAVE_CLASS: "Failed to save live class as draft",
    SUCCESS_PUBLISH_CLASS: "Successfully published live class",
    FAILED_PUBLISH_CLASS: "Failed to publish live class",
    SOMETHING_WENT_WRONG: "Something went wrong",
    ADD_TEACHER: "Add Teacher",
    SUBMIT: "Submit",
    START_MUST_EARLIER: "Start Datetime must be earlier than End Datetime",
    START_DATE_REQUIRED: "Start Datetime is required",
    END_DATE_REQUIRED: "End Datetime is required",
    SUCCESS_ADD_USER: "Successfully added Teacher!",
  };

  let history = useHistory();
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  // drafts
  const [open, setOpen] = useState(false);
  const [drafts, setDrafts] = useState<CreateClassForm[]>([]);
  const [fromDraft, setFromDraft] = useState(false);

  const [form, setForm] = useState<CreateClassForm>(CreateClassFormTemplate);
  const [errors, setErrors] = useState<FormErrors>({} as FormErrors);
  const [options, setOptions] =
    useState<AutoCompleteOptions>(autoCompleteOptions);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [parentLiveClasses, setParentLiveClasses] = useState<ParentLiveClass[]>(
    []
  );

  const handleClose = () => setOpen(false);

  //create parent live class form
  const [ParentFormOpen, setParentFormOpen] = useState(false);
  const handleParentFormClose = () => setParentFormOpen(false);

  const handleParentFormSubmit = () => {
    setParentFormOpen(false);
  };
  const handleParentFormPublish = () => {
    setParentFormOpen(false);
    loadOptions();
  };

  // Teacher
  const [teacherDialogOpen, setTeacherDialogOpen] = useState(false);
  const handleTeacherDialogOpen = () => setTeacherDialogOpen(true);
  const handleTeacherDialogClose = () => {
    setTeacherDialogOpen(false);
    loadOptions();
  };

  const makeTextField = (
    label: string,
    type: HTMLInputTypeAttribute | "textarea" | "autocomplete",
    key: CreateClassFormKeys,
    pattern: RegExp,
    required: boolean = false,
    disabled: boolean = false
  ): MakeTextField<CreateClassForm> => ({
    label,
    type: type === "autocomplete" ? "autocomplete" : type,
    key,
    pattern,
    disabled,
    value:
      type === "datetime-local"
        ? moment(form[key]).format("YYYY-MM-DDTHH:mm")
        : form[key],
    error: errors[key],
    helperText: errors[key]
      ? required && !form[key]
        ? "This field is required"
        : `Invalid ${label}`
      : "",
    required,
    name: key,
    multiline: type === "textarea",
    rows: 4,
  });

  const textFields: MakeTextFields<CreateClassForm> = {
    lcId: makeTextField("Live Class ID", "text", "lcId", REGEX.ANY, true),
    lpId: makeTextField(
      "Parent Live Class",
      "autocomplete",
      "lpId",
      REGEX.ANY,
      true
    ),
    lcCategoryId: makeTextField(
      "Live Class Category",
      "autocomplete",
      "lcCategoryId",
      REGEX.ANY,
      true,
      true
    ),
    lcTitle: makeTextField(
      "Live Class Title",
      "text",
      "lcTitle",
      REGEX.ANY,
      true
    ),
    teacherId: makeTextField(
      "Teacher",
      "autocomplete",
      "teacherId",
      REGEX.ANY,
      true
    ),
    lcCourseLength: makeTextField(
      "Live Class Course Length",
      "text",
      "lcCourseLength",
      REGEX.ANY,
      true
    ),
    lcSupportId: makeTextField(
      "Sponsor / Support",
      "autocomplete",
      "lcSupportId",
      REGEX.ANY,
      true
    ),
    lcTargetLang: makeTextField(
      "Target Language",
      "autocomplete",
      "lcTargetLang",
      REGEX.ANY,
      true
    ),
    lcAge: makeTextField("Age Group", "autocomplete", "lcAge", REGEX.ANY, true),
    totalSlots: makeTextField(
      "First Session Total Slots",
      "number",
      "totalSlots",
      REGEX.NUMBER,
      true
    ),
    lcStartDateTs: makeTextField(
      "First Session Start Date",
      "datetime-local",
      "lcStartDateTs",
      REGEX.ANY,
      true
    ),
    lcEndDateTs: makeTextField(
      "First Session End Date",
      "datetime-local",
      "lcEndDateTs",
      REGEX.ANY,
      true
    ),
    meetingLink: makeTextField(
      "First Session Meeting Link",
      "text",
      "meetingLink",
      REGEX.ANY,
      true
    ),
    lcImage: makeTextField(
      "Live Class Image",
      "text",
      "lcImage",
      REGEX.IMAGE,
      true
    ),
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setDisableSubmit(false);
    setErrors({} as FormErrors);

    const { value } = e.target;
    const name = e.target.name as CreateClassFormKeys;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = async () => {
    setDisableSubmit(true);

    // validate form
    const hasError = Object.values(textFields).some((textField) => {
      const { name, label } = textField;
      const error: boolean = checkTextFieldError(textField, form);
      if (error) {
        setDisableSubmit(false);
        setErrors({ ...errors, [name]: error });
        enqueueSnackbar(`Invalid ${label}`, { variant: "error" });
      }
      return error;
    });

    if (hasError) return;

    const massagedForm: CreateClassForm = cleanValues({
      ...form,
      lcId: convertTitleToId(
        `${form.lcTitle}-${moment(form.lcStartDateTs).valueOf()}-${moment(
          form.lcEndDateTs
        ).valueOf()}`
      ),
    });

    if (fromDraft) {
      try {
        await LiveClassService.deleteDraft(massagedForm.lcId);
        setDisableSubmit(false);
      } catch (error) {
        console.error("error:", error);
        setDisableSubmit(false);
      }
    }
    try {
      await LiveClassService.createDraft(massagedForm);
      setFromDraft(false);
      history.push("/live-class");
      enqueueSnackbar(STRINGS.SUCCESS_SAVE_CLASS, {
        variant: "success",
      });
      setDisableSubmit(false);
    } catch (error) {
      console.error("error:", error);
      enqueueSnackbar(STRINGS.FAILED_SAVE_CLASS, {
        variant: "error",
      });
      setDisableSubmit(false);
    }
  };

  const handlePublish = async () => {
    setDisableSubmit(true);

    // validate form
    const hasError = Object.values(textFields).some((textField) => {
      const { name, label } = textField;
      const error: boolean = checkTextFieldError(textField, form);
      if (error) {
        setDisableSubmit(false);
        setErrors({ ...errors, [name]: error });
        enqueueSnackbar(`Invalid ${label}`, { variant: "error" });
      }
      return error;
    });

    if (hasError) return;

    // check start and end date
    if (!form.lcStartDateTs) {
      setErrors({ ...errors, lcStartDateTs: true });
      return enqueueSnackbar(STRINGS.START_DATE_REQUIRED, { variant: "error" });
    }
    if (!form.lcEndDateTs) {
      setErrors({ ...errors, lcEndDateTs: true });
      return enqueueSnackbar(STRINGS.END_DATE_REQUIRED, { variant: "error" });
    }
    if (
      moment(form.lcStartDateTs).valueOf() > moment(form.lcEndDateTs).valueOf()
    ) {
      return enqueueSnackbar(STRINGS.START_MUST_EARLIER, {
        variant: "error",
      });
    }

    const massagedForm: LiveClass = cleanValues({
      lpId: form.lpId,
      lcTitle: form.lcTitle,
      lcImage: form.lcImage,
      lcCourseLength: form.lcCourseLength,
      lcAge: form.lcAge,
      lcCategoryId: form.lcCategoryId,
      lcTargetLength: form.lcTargetLang,
      lcSupportId: form.lcSupportId,
      teacherId: form.teacherId,
      totalSlots: +form.totalSlots,
      lcCategory:
        options.lcCategoryId.find(
          (category) => category.value === form.lcCategoryId
        )?.title || "",
      lcDesc:
        options.lpId.find((liveClass) => liveClass.value === form.lpId)
          ?.subtitle || "",
      lcId: convertTitleToId(
        `${form.lcTitle}-${moment(form.lcStartDateTs).valueOf()}-${moment(
          form.lcEndDateTs
        ).valueOf()}`
      ),
      teacherName:
        options.teacherId.find((teacher) => teacher.value === form.teacherId)
          ?.title || "",
      teacherEmail:
        options.teacherId.find((teacher) => teacher.value === form.teacherId)
          ?.subtitle || "",
      lcStartDateTs: moment(form.lcStartDateTs).valueOf() as number,
      lcEndDateTs: moment(form.lcEndDateTs).valueOf() as number,
      createdAt: moment().valueOf(),
      updatedAt: moment().valueOf(),
      updatedBy: user?.email || "",
      lcStatus: LIVE_CLASS_STATUS.PUBLISHED,
      lcIndex: 99999999,
      lcIndexAsc: 0,
      lcType: "",
      lcCourseType: "",
      lcCourseId: "",
      lcDescHtml: "",
      lcDataA: "",
      lcDataB: "",
      lcLevel: "",
      lcPrerequisite: "",
      lcSessionsDates: "",
      lcTagId: "",
      lcUserGroup: "",
      lcProgramId: "",
      programTitle: "",
      supportTitle: "",
      targetGroup: "",
    });

    // automatically creates a group
    const groupTitle = `${massagedForm.lcTitle} Group`;
    const groupId = convertTitleToId(
      `${groupTitle}-${massagedForm.lcStartDateTs}-${massagedForm.lcEndDateTs}`
    );
    const group: Group = {
      groupTitle,
      groupId,
      totalSlots: massagedForm.totalSlots,
      slotsAvailable: massagedForm.totalSlots,
    };
    massagedForm.lcUserGroup = groupId;

    // automatically creates a session
    const sessionTitle = `${massagedForm.lcTitle} Session 1`;
    const sessionId = convertTitleToId(
      `${sessionTitle}-${massagedForm.lcStartDateTs}-${massagedForm.lcEndDateTs}`
    );
    const session: Session = {
      sessionTitle,
      sessionId,
      lcDetailHtml: "",
      meetingLink: form.meetingLink.trim(),
      sessionDetailHtml: "",
      sessionMaterial: "",
      sessionNumber: 0,
      groupId,
      groupTitle,
      lcId: massagedForm.lcId,
      lpId: massagedForm.lpId,

      sessionEndDateTs: massagedForm.lcEndDateTs as number,
      sessionStartDateTs: massagedForm.lcStartDateTs as number,
      teacherEmail: massagedForm.teacherEmail,
      teacherId: massagedForm.teacherId,
      teacherName: massagedForm.teacherName,
      totalSlots: massagedForm.totalSlots,

      createdAt: moment().valueOf(),
      updatedAt: moment().valueOf(),
      updatedBy: user?.email || "",
    };

    if (fromDraft) {
      try {
        await LiveClassService.deleteDraft(massagedForm.lcId);
        setDisableSubmit(false);
      } catch (error) {
        console.error("error:", error);
        setDisableSubmit(false);
      }
    }
    try {
      await GroupService.createGroup(group);
      await LiveClassService.publishLiveClass(massagedForm);
      await SessionService.createSession(session);
      setFromDraft(false);
      history.push(`/live-class/edit-class/${massagedForm.lcId}`);
      enqueueSnackbar(STRINGS.SUCCESS_PUBLISH_CLASS, {
        variant: "success",
      });
      setDisableSubmit(false);
    } catch (error: any) {
      console.error("error:", error);
      enqueueSnackbar(error.message || STRINGS.FAILED_PUBLISH_CLASS, {
        variant: "error",
      });
      setDisableSubmit(false);
    }
  };

  const handleUseDraft = async (lcId: string) => {
    const draft = drafts.find((draft) => draft.lcId === lcId);
    if (!draft) {
      enqueueSnackbar(STRINGS.SOMETHING_WENT_WRONG, { variant: "warning" });
      return;
    }
    setForm(draft as CreateClassForm);
    setFromDraft(true);
    handleClose();
    setDisableSubmit(false);
  };

  const handleDeleteDraft = async (lcId: string) => {
    await LiveClassService.deleteDraft(lcId);
    const draftToDelete = drafts.findIndex((draft) => draft.lcId === lcId);
    if (draftToDelete === -1) return;
    setDrafts([
      ...drafts.slice(0, draftToDelete),
      ...drafts.slice(draftToDelete + 1),
    ]);
  };

  const handleReset = () => {
    setForm(CreateClassFormTemplate);
    handleClose();
    setDisableSubmit(true);
  };

  const handleAutoCompleteChange = async (
    e: SyntheticEvent<Element, Event>,
    newValue: AutoCompleteOption | null,
    name: CreateClassFormKeys
  ) => {
    e.preventDefault();
    setDisableSubmit(false);
    setErrors({} as FormErrors);

    switch (name) {
      case "lpId":
        const parentLiveClass = parentLiveClasses.find(
          (lp) => lp.lpId === newValue?.value
        );
        if (!parentLiveClass) return;
        const changes = {
          lcTitle: newValue?.title || "",
          lcCategoryId: parentLiveClass.lpCategoryId,
          lcCategory: parentLiveClass.lpCategory,
          lcImage: parentLiveClass.lpImage,
          lcId: convertTitleToId(
            `${form.lcTitle}-${moment(form.lcStartDateTs).valueOf()}-${moment(
              form.lcEndDateTs
            ).valueOf()}`
          ),
        };
        setForm({
          ...form,
          ...changes,
          [name]: newValue?.value,
        });
        break;
      default:
        setForm({
          ...form,
          [name]: newValue?.value,
        });
        break;
    }
  };

  const loadOptions = async () => {
    const categories = await LiveClassService.readCategories();
    const parentLiveClassesOptions =
      await LiveClassService.readParentLiveClasses();
    const teachers = await LiveClassService.readTeachers();
    const sponsors = await SponsorService.readSponsors();

    setParentLiveClasses(parentLiveClassesOptions);

    setOptions({
      ...options,
      lcCategoryId: categories.map((cat) => ({
        title: cat.lcCategoryName,
        value: cat.lcCategoryId,
        subtitle: cat.lcCategoryDesc,
      })),
      lpId: parentLiveClassesOptions.map((lp) => ({
        title: lp.lpTitle,
        value: lp.lpId,
        subtitle: lp.lpDesc,
      })),
      teacherId: teachers.map((teacher) => ({
        title: teacher.teacherName,
        value: teacher.teacherId,
        subtitle: teacher.teacherEmail,
      })),
      lcSupportId: sponsors.map((sponsor) => ({
        title: sponsor.lcSupportTitle,
        value: sponsor.lcSupportId,
        subtitle: sponsor.lcSupportId,
      })),
    });
  };

  useEffect(() => {
    (async () => {
      try {
        const drafts = await LiveClassService.readAllDrafts();
        setDrafts(drafts);
      } catch (e) {
        console.error(e);
      }

      loadOptions();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} lg={8}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <GeneralAvatarComponent src={form.lcImage} large />
          </Grid>
          {Object.values(textFields)
            .slice(1)
            .map((textField) => (
              <Grid item xs={12} md={12} key={textField.key}>
                <Grid container spacing={3} alignItems="center">
                  <Grid item xs={10}>
                    {textField.type === "autocomplete" ? (
                      <Autocomplete
                        disablePortal
                        fullWidth
                        disabled={textField.disabled}
                        options={
                          options[textField.key as AutoCompleteOptionKeys]
                        }
                        getOptionLabel={(option) => option.title}
                        renderOption={(props, option) => (
                          <ListItem {...props} title={option.title}>
                            <ListItemText
                              primary={option.title}
                              secondary={option.subtitle || ""}
                            />
                          </ListItem>
                        )}
                        isOptionEqualToValue={(option) =>
                          option.value === form[textField.key]
                        }
                        value={options[
                          textField.key as AutoCompleteOptionKeys
                        ]?.find(
                          (option) => option.value === form[textField.key]
                        )}
                        onChange={(e, newValue) =>
                          handleAutoCompleteChange(e, newValue, textField.name)
                        }
                        // random key needs to be used in order to rerender the autocomplete field after async event
                        key={`autocomplete-${textField.key}-${
                          Math.random() * 1000
                        }`}
                        renderInput={(params) => (
                          <TextField
                            margin="normal"
                            {...params}
                            {...textField}
                          />
                        )}
                      />
                    ) : (
                      <TextField
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        onChange={handleChange}
                        InputLabelProps={{ shrink: true }}
                        {...textField}
                      />
                    )}
                  </Grid>

                  <Grid item xs={2}>
                    <InfoComponent
                      section="live-class"
                      textFieldName={textField.name}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ))}
        </Grid>
      </Grid>
      <VerticalDivider />
      <CreateClassButtons
        disableSubmit={disableSubmit}
        disableShowDrafts={!drafts.length}
        handleSubmit={handleSubmit}
        handleReset={handleReset}
        handlePublish={handlePublish}
        handleShowDrafts={() => setOpen(true)}
        setParentFormOpen={setParentFormOpen}
        handleTeacherDialogOpen={handleTeacherDialogOpen}
      />
      <DraftDialog
        open={open}
        handleClose={handleClose}
        handleReset={handleReset}
        drafts={drafts}
        handleUseDraft={handleUseDraft}
        handleDeleteDraft={handleDeleteDraft}
      />
      <GeneralDialogComponent
        title="Create Parent Live Class"
        open={ParentFormOpen}
        children={
          <ParentClassCreateForm
            handleParentFormPublish={handleParentFormPublish}
            handleParentFormSubmit={handleParentFormSubmit}
            isDialog
          />
        }
        onClose={handleParentFormClose}
        actions={
          <Button
            variant="contained"
            color="primary"
            onClick={handleParentFormClose}
          >
            Cancel
          </Button>
        }
      />
      <AddTeacherDialog
        open={teacherDialogOpen}
        handleClose={handleTeacherDialogClose}
        STRINGS={STRINGS}
        isFromOtherPage
      />
    </Grid>
  );
};

export default CreateClassFormComponent;

interface AutoCompleteOptions {
  lcCategoryId: AutoCompleteOption[];
  lpId: AutoCompleteOption[];
  teacherId: AutoCompleteOption[];
  lcSupportId: AutoCompleteOption[];
  lcAge: AutoCompleteOption[];
  lcTargetLang: AutoCompleteOption[];
}

type AutoCompleteOptionKeys = keyof AutoCompleteOptions;

const autoCompleteOptions: AutoCompleteOptions = {
  lcCategoryId: [],
  lpId: [],
  teacherId: [],
  lcAge: [
    make("9 - 12 years old"),
    make("13 - 21 years old"),
    make("15 years and above"),
    make("18 years and above"),
    make("20 years and above"),
  ],
  lcSupportId: [],
  lcTargetLang: [make("English"), make("Bahasa Malaysia")],
};

type FormErrors = {
  [F in CreateClassFormKeys]: boolean;
};

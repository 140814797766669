import React, {
  ChangeEvent,
  HTMLInputTypeAttribute,
  SyntheticEvent,
  useState,
} from "react";
import { Autocomplete, Button, Grid, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import GeneralDialogComponent from "../../../components/GeneralDialogComponent";
import { MakeTextField, MakeTextFields } from "../../../interfaces";
import TeacherService from "../../../services/teacher.service";
import { REGEX } from "../../../constants/regex.constant";
import { useHistory } from "react-router-dom";
import {
  AddTeacher,
  AddTeacherErrors,
  AddTeacherKeys,
  Teacher,
  TeacherKeys,
} from "../../../interfaces/Teacher.interface";
import { AutoCompleteOption } from "../../../interfaces/TextField.interface";
import { make } from "../../../utils/makeAutoCompleteOption";
import moment from "moment";
import { convertTitleToId } from "../../../utils/convertString";
import InfoComponent from "../../../components/InfoComponent";
import { checkTextFieldError } from "../../../utils/checkTextField";
import cleanValues from "../../../utils/cleanValues";
import GeneralAvatarComponent from "../../../components/GeneralAvatarComponent";

const AddTeacherDialog = (props: Props) => {
  const { open, handleClose, STRINGS, isFromOtherPage } = props;
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [form, setForm] = useState<AddTeacher>({} as AddTeacher);
  const [errors, setErrors] = useState<AddTeacherErrors>(
    {} as AddTeacherErrors
  );
  const [disableSubmit, setDisableSubmit] = useState(true);

  const makeTextField = (
    label: string,
    type: HTMLInputTypeAttribute | "textarea" | "autocomplete",
    key: AddTeacherKeys,
    pattern: RegExp,
    required: boolean = false,
    disabled: boolean = false
  ): MakeTextField<AddTeacher> => ({
    label,
    type: type === "autocomplete" ? "autocomplete" : type,
    key,
    pattern,
    required,
    disabled: disabled,
    value:
      type === "datetime-local"
        ? // TODO: why do we need unknown here
          moment(form[key] as unknown as number).format("D MMM yyyy HH:mm:ss ")
        : form[key],
    error: errors[key],
    helperText: errors[key]
      ? required && !form[key]
        ? "This field is required"
        : `Invalid ${label} format`
      : "",
    name: key,
    multiline: type === "textarea",
    rows: 4,
  });

  const textFields: MakeTextFields<AddTeacher> = {
    teacherId: makeTextField("Teacher ID", "text", "teacherId", REGEX.ANY),
    teacherEmail: makeTextField(
      "Email Address",
      "email",
      "teacherEmail",
      REGEX.EMAIL,
      true
    ),
    teacherName: makeTextField(
      "Full Name",
      "text",
      "teacherName",
      REGEX.NAME,
      true
    ),
    teacherImage: makeTextField(
      "Profile Image",
      "text",
      "teacherImage",
      REGEX.IMAGE,
      true
    ),
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setDisableSubmit(false);
    const { required, value } = e.target;
    const name = e.target.name as AddTeacherKeys;
    setForm({ ...form, [name]: value });

    // this is to check if the text field has error
    // 1. if field is '', return TRUE if field is required
    // 2. if field has value, return TRUE if pattern is wrong
    const pattern = textFields[name].pattern;
    const error: boolean = !value
      ? required
      : !new RegExp(pattern)?.test(value);
    setErrors({ ...errors, [name]: error });
  };

  const handleSubmit = async () => {
    setDisableSubmit(true);

    // validate form
    const hasError = Object.values(textFields).some((textField) => {
      const { name, label } = textField;
      const error: boolean = checkTextFieldError(textField, form);
      if (error) {
        setDisableSubmit(false);
        setErrors({ ...errors, [name]: error });
        enqueueSnackbar(`Invalid ${label} format`, { variant: "error" });
      }
      return error;
    });

    if (hasError) return;

    const massagedForm: Teacher = cleanValues({
      ...form,
      teacherId: convertTitleToId(form.teacherName),
      teacherSlug: convertTitleToId(form.teacherName),
      upComingClasses: 0,
      teacherUid: "",
      teacherStatus: "active",
      teacherBio: "",
      teacherRole: "teacher",
      teacherRoleAccess: 20,
      teacherHtml: "",
    });
    try {
      await TeacherService.createTeacher(massagedForm);

      // setTimeout(async () => {
      handleClose();
      enqueueSnackbar(STRINGS.SUCCESS_ADD_USER, { variant: "success" });
      if (!isFromOtherPage) history.push(`/teacher/${massagedForm.teacherId}`);
      // }, 2000)
    } catch (error: any) {
      setDisableSubmit(false);
      console.error("error:", error);
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  return (
    <GeneralDialogComponent
      open={open}
      onClose={() => {
        handleClose();
        setErrors({} as AddTeacherErrors);
      }}
      title={STRINGS.ADD_TEACHER}
      actions={
        <Button
          variant="contained"
          onClick={handleSubmit}
          // find if there is any errors
          disabled={
            Object.values(errors).find((error) => error === true) ||
            disableSubmit
          }
        >
          {STRINGS.SUBMIT}
        </Button>
      }
    >
      <GeneralAvatarComponent src={form.teacherImage} large />
      {Object.values(textFields)
        .slice(1)
        .map((textField) => (
          <Grid container alignItems="center" key={textField.name}>
            <Grid item xs={10}>
              <TextField
                fullWidth
                variant="outlined"
                margin="normal"
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
                {...textField}
              />
            </Grid>

            <Grid item xs={2}>
              <InfoComponent section="teacher" textFieldName={textField.name} />
            </Grid>
          </Grid>
        ))}
    </GeneralDialogComponent>
  );
};

interface Props {
  open: boolean;
  handleClose: () => void;
  STRINGS: any;
  isFromOtherPage?: boolean;
}

export default AddTeacherDialog;

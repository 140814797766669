import React from 'react'
import { Switch, useRouteMatch } from 'react-router-dom'
import PrivateRoute from '../../layouts/Router/PrivateRoute'
import SponsorPage from './components'
import SponsorDetailsPage from './SponsorDetails'

const Sponsor = () => {
	const { path } = useRouteMatch()

	return (
		<Switch>
			<PrivateRoute exact path={path} component={SponsorPage} />
			<PrivateRoute exact path={`${path}/:lcSupportId`} component={SponsorDetailsPage} />
		</Switch>
	)
}

export default Sponsor
import {
  Autocomplete,
  Grid,
  TextField,
  Typography,
  Divider,
  ListItem,
  ListItemText,
} from "@mui/material";
import React, {
  ChangeEvent,
  FC,
  HTMLInputTypeAttribute,
  SyntheticEvent,
  useEffect,
  useState,
} from "react";
import moment from "moment";
import { REGEX } from "../../../../constants/regex.constant";
import {
  EditClassForm,
  EditClassFormKeys,
  LiveClass,
  ParentLiveClass,
} from "../../../../interfaces";
import LiveClassService from "../../../../services/liveclass.service";
import { useAuth } from "../../../../utils/providers/AuthProvider";
import { useHistory } from "react-router-dom";
import {
  AutoCompleteOption,
  MakeTextField,
  MakeTextFields,
} from "../../../../interfaces/TextField.interface";
import VerticalDivider from "../../../../components/VerticalDivider";
import EditClassButtons from "./EditClassButtons";
import { make } from "../../../../utils/makeAutoCompleteOption";
import GroupService from "../../../../services/group.service";
import { convertTitleToId } from "../../../../utils/convertString";
import { checkTextFieldError } from "../../../../utils/checkTextField";
import { useSnackbar } from "notistack";
import { EditClassFormTemplate } from "../../../../mock/EditClassFormTemplate";
import InfoComponent from "../../../../components/InfoComponent";
import cleanValues from "../../../../utils/cleanValues";
import SponsorService from "../../../../services/sponsor.service";
import GeneralAvatarComponent from "../../../../components/GeneralAvatarComponent";
import { LIVE_CLASS_STATUS } from "../../../../constants/LIVE_CLASS_STATUS";

const EditClassFormComponent: FC<Props> = ({ lcId }) => {
  const STRINGS = {
    SUCCESS_UPDATE: "Successfully Updated Class",
    FAILED_UPDATE: "Failed to Update Class",
  };
  let history = useHistory();
  const [form, setForm] = useState<EditClassForm>(EditClassFormTemplate);
  const { enqueueSnackbar } = useSnackbar();

  const [errors, setErrors] = useState<FormErrors>({} as FormErrors);
  const [options, setOptions] =
    useState<AutoCompleteOptions>(autoCompleteOptions);
  const { user } = useAuth();
  const [currentGroup, setCurrentGroup] = useState("");
  const [parentLiveClasses, setParentLiveClasses] = useState<ParentLiveClass[]>(
    []
  );
  const [disableSubmit, setDisableSubmit] = useState(true);

  const makeTextField = (
    label: string,
    type: HTMLInputTypeAttribute | "textarea" | "autocomplete",
    key: EditClassFormKeys,
    pattern: RegExp,
    required: boolean = false,
    disabled: boolean = false
  ): MakeTextField<EditClassForm> => ({
    label,
    type: type === "autocomplete" ? "autocomplete" : type,
    key,
    pattern,
    disabled,
    value: form[key],
    error: errors[key],
    helperText: errors[key]
      ? required && !form[key]
        ? "This field is required"
        : `Invalid ${label}`
      : "",
    required,
    name: key,
    multiline: type === "textarea",
    rows: 4,
  });

  const textFields: MakeTextFields<EditClassForm> = {
    // not displayed
    totalSlots: makeTextField(
      "Total Slots",
      "number",
      "totalSlots",
      REGEX.NUMBER
    ),
    // displayed
    lcId: makeTextField("", "text", "lcId", REGEX.ANY, true, true),
    lcTitle: makeTextField(
      "Live Class Title",
      "text",
      "lcTitle",
      REGEX.ANY,
      true
    ),
    lcCategory: makeTextField(
      "Live Class Category Name",
      "text",
      "lcCategory",
      REGEX.ANY,
      true,
      true
    ),
    lpId: makeTextField(
      "Parent Live Class",
      "autocomplete",
      "lpId",
      REGEX.ANY,
      true
    ),
    lcUserGroup: makeTextField(
      "User Group",
      "autocomplete",
      "lcUserGroup",
      REGEX.ANY,
      true
    ),
    lcCourseId: makeTextField(
      "Linked Course",
      "autocomplete",
      "lcCourseId",
      REGEX.ANY
    ),
    lcDescHtml: makeTextField(
      "Live Class HTML",
      "autocomplete",
      "lcDescHtml",
      REGEX.ANY
    ),
    lcCourseLength: makeTextField(
      "Live Class Course Length",
      "text",
      "lcCourseLength",
      REGEX.ANY
    ),
    lcImage: makeTextField("Live Class Image", "text", "lcImage", REGEX.IMAGE),
    lcCategoryId: makeTextField(
      "Live Class Category",
      "autocomplete",
      "lcCategoryId",
      REGEX.ANY,
      true,
      true
    ),
    lcType: makeTextField(
      "Live Class Type",
      "autocomplete",
      "lcType",
      REGEX.ANY
    ),
    lcCourseType: makeTextField(
      "Live Class Course Type",
      "autocomplete",
      "lcCourseType",
      REGEX.ANY
    ),
    teacherName: makeTextField(
      "Teacher Name",
      "autocomplete",
      "teacherName",
      REGEX.ANY,
      true
    ),
    teacherEmail: makeTextField(
      "Teacher Email",
      "text",
      "teacherEmail",
      REGEX.ANY,
      true,
      true
    ),
    lcDataA: makeTextField("Additional Data", "textarea", "lcDataA", REGEX.ANY),
    lcDataB: makeTextField("Additional Data", "textarea", "lcDataB", REGEX.ANY),
    lcDesc: makeTextField(
      "Live Class Description",
      "textarea",
      "lcDesc",
      REGEX.ANY,
      false
    ),
    lcAge: makeTextField("Age Group", "autocomplete", "lcAge", REGEX.ANY, true),
    lcLevel: makeTextField(
      "Live Class Level",
      "autocomplete",
      "lcLevel",
      REGEX.ANY
    ),
    lcPrerequisite: makeTextField(
      "Prerequisite",
      "textarea",
      "lcPrerequisite",
      REGEX.ANY
    ),
    lcSessionsDates: makeTextField(
      "Sessions Dates",
      "text",
      "lcSessionsDates",
      REGEX.ANY
    ),
    lcTagId: makeTextField("Tag ID", "text", "lcTagId", REGEX.ANY),
    lcTargetLang: makeTextField(
      "Target Language",
      "autocomplete",
      "lcTargetLang",
      REGEX.ANY,
      true
    ),
    lcProgramId: makeTextField(
      "Live Class Program",
      "autocomplete",
      "lcProgramId",
      REGEX.ANY
    ),
    lcSupportId: makeTextField(
      "Live Class Support",
      "autocomplete",
      "lcSupportId",
      REGEX.ANY,
      true
    ),
    targetGroup: makeTextField(
      "Target Group",
      "text",
      "targetGroup",
      REGEX.ANY
    ),
    lcStartDateTs: makeTextField(
      "Live Class Start Date",
      "datetime-local",
      "lcStartDateTs",
      REGEX.ANY,
      false,
      true
    ),
    lcEndDateTs: makeTextField(
      "Live Class End Date",
      "datetime-local",
      "lcEndDateTs",
      REGEX.ANY,
      false,
      true
    ),
    createdAt: makeTextField(
      "Live Class Created At",
      "datetime-local",
      "createdAt",
      REGEX.ANY,
      false,
      true
    ),
    updatedAt: makeTextField(
      "Live Class Updated At",
      "datetime-local",
      "updatedAt",
      REGEX.ANY,
      false,
      true
    ),
    updatedBy: makeTextField(
      "Live Class Updated By",
      "text",
      "updatedBy",
      REGEX.ANY,
      false,
      true
    ),
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { value } = e.target;
    const name = e.target.name as EditClassFormKeys;
    setForm({ ...form, [name]: value });
    setDisableSubmit(false);
  };

  const handleAutoCompleteChange = async (
    e: SyntheticEvent<Element, Event>,
    newValue: AutoCompleteOption | null,
    name: EditClassFormKeys
  ) => {
    e.preventDefault();
    setDisableSubmit(false);

    setForm({ ...form, [name]: newValue?.value });
    if (name === "lpId") {
      const lpCategory = parentLiveClasses.find(
        (lp) => lp.lpId === newValue?.value
      )?.lpCategory;

      const lcDesHtml = options.lcDescHtml.find(
        (option) => option.title === newValue?.title
      )?.value;

      setForm({
        ...form,
        [name]: newValue?.value,
        lcDesc: newValue?.subtitle as string,
        lcDescHtml: lcDesHtml,
        lcTitle: newValue?.title as string,
        lcCategoryId: convertTitleToId(lpCategory as string),
        lcCategory: lpCategory as string,
      });
    }
    if (name === "teacherName") {
      setForm({
        ...form,
        [name]: newValue?.value,
        teacherEmail: newValue?.subtitle as string,
      });
    }
  };

  const handleUpdate = async () => {
    setDisableSubmit(true);
    // validate form
    const hasError = Object.values(textFields).some((textField) => {
      const { name, label } = textField;
      const error: boolean = checkTextFieldError(textField, form);
      if (error) {
        setErrors({ ...errors, [name]: error });
        enqueueSnackbar(`Invalid ${label}`, { variant: "error" });
      }
      return error;
    });

    if (hasError) return;

    const massagedForm: LiveClass = cleanValues({
      ...form,
      lcStartDateTs: (moment(form.lcStartDateTs).valueOf() as number) || "",
      lcEndDateTs: (moment(form.lcEndDateTs).valueOf() as number) || "",
      programTitle: form.lcProgramId ? form.lcProgramId : "",
      supportTitle: form.lcSupportId ? form.lcSupportId : "",
      teacherId: convertTitleToId(form.teacherName),
      createdAt: moment(form.createdAt).valueOf(),
      updatedAt: moment().valueOf(),
      updatedBy: user?.email || "",
      lcStatus: LIVE_CLASS_STATUS.PUBLISHED,
      lcIndex: 99999999,
      lcIndexAsc: 0,
    });

    try {
      await LiveClassService.updateLiveClass(massagedForm, currentGroup);
      enqueueSnackbar(STRINGS.SUCCESS_UPDATE, { variant: "success" });
      // history.push(`/live-class/${lcId}`);
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar(error.message || STRINGS.FAILED_UPDATE, {
        variant: "error",
      });
    }
  };

  const handleDelete = async () => {
    try {
      await LiveClassService.deleteLiveClass(lcId);
      history.push(`/live-class`);
    } catch (error) {
      console.error("error: ", error);
    }
  };

  useEffect(() => {
    (async () => {
      const lcRes = (await LiveClassService.readOneLiveClass(
        lcId
      )) as LiveClass;
      setForm({
        ...lcRes,
        lcStartDateTs: moment(lcRes.lcStartDateTs).format("YYYY-MM-DDTHH:mm"),
        lcEndDateTs: moment(lcRes.lcEndDateTs).format("YYYY-MM-DDTHH:mm"),
        createdAt: moment(lcRes.createdAt).format("YYYY-MM-DDTHH:mm"),
        updatedAt: moment(lcRes.updatedAt).format("YYYY-MM-DDTHH:mm"),
      });
      setCurrentGroup(lcRes.lcUserGroup);

      const courseIds = await LiveClassService.readCourses();
      const classHtmls = await LiveClassService.readLiveClassesHtml();
      const parentLiveClassesOptions =
        await LiveClassService.readParentLiveClasses();
      const userGroups = await GroupService.readAllGroup();
      const teachers = await LiveClassService.readTeachers();
      const sponsors = await SponsorService.readSponsors();

      setParentLiveClasses(parentLiveClassesOptions);

      setOptions({
        ...options,
        lcCourseId: courseIds.map((id) => ({
          title: id.courseTitle,
          value: id.courseId,
          subtitle: id.courseDesc,
        })),
        lcDescHtml: classHtmls.map((html) => ({
          title: html.lcTitle,
          value: html.lcDescHtml,
          subtitle: html.lcDescHtml,
        })),
        lpId: parentLiveClassesOptions.map((lp) => ({
          title: lp.lpTitle,
          value: lp.lpId,
          subtitle: lp.lpDesc,
        })),
        lcUserGroup: userGroups.map((group) => ({
          title: group.groupTitle,
          value: group.groupId,
          subtitle: group.groupId,
        })),
        teacherName: teachers.map((teacher) => ({
          title: teacher.teacherName,
          value: teacher.teacherName,
          subtitle: teacher.teacherEmail,
        })),
        lcSupportId: sponsors.map((sponsor) => ({
          title: sponsor.lcSupportTitle,
          value: sponsor.lcSupportId,
          subtitle: sponsor.lcSupportId,
        })),
      });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} lg={8}>
        <Grid item xs={12}>
          <Typography variant="h5" sx={{ mt: "3rem" }}>
            Main Details
          </Typography>
        </Grid>
        {/* <Grid container alignItems="center" my="1rem">
          <ClassImage form={form} setForm={setForm} />
        </Grid> */}
        <Grid item xs={12}>
          <GeneralAvatarComponent src={form.lcImage} large />
        </Grid>
        <Grid container spacing={3}>
          {Object.values(textFields)
            .slice(1, 10)
            .map((textField) => (
              <Grid item xs={12} key={textField.key}>
                <Grid container alignItems="center">
                  <Grid item xs={10}>
                    {textField.type === "autocomplete" ? (
                      <Autocomplete
                        disablePortal
                        fullWidth
                        disabled={textField.disabled}
                        options={
                          options[textField.key as AutoCompleteOptionKeys]
                        }
                        getOptionLabel={(option) => option.title}
                        renderOption={(props, option) => (
                          <ListItem {...props} title={option.title}>
                            <ListItemText
                              primary={option.title}
                              secondary={option.subtitle || ""}
                            />
                          </ListItem>
                        )}
                        isOptionEqualToValue={(option) =>
                          option.value === form[textField.key]
                        }
                        value={options[
                          textField.key as AutoCompleteOptionKeys
                        ]?.find(
                          (option) => option.value === form[textField.key]
                        )}
                        onChange={(e, newValue) =>
                          handleAutoCompleteChange(e, newValue, textField.name)
                        }
                        // random key needs to be used in order to rerender the autocomplete field after async event
                        key={`autocomplete-${textField.key}-${
                          Math.random() * 1000
                        }`}
                        renderInput={(params) => (
                          <TextField
                            margin="normal"
                            {...params}
                            {...textField}
                          />
                        )}
                      />
                    ) : (
                      <TextField
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        onChange={handleChange}
                        InputLabelProps={{ shrink: true }}
                        {...textField}
                      />
                    )}
                  </Grid>

                  <Grid item xs={2}>
                    <InfoComponent
                      section="live-class"
                      textFieldName={textField.name}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ))}
        </Grid>

        <Divider sx={{ my: "2rem" }} />

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5">Additional Details</Typography>
          </Grid>
          {Object.values(textFields)
            .slice(13)
            .map((textField) => (
              <Grid item xs={12} md={6} key={textField.key}>
                <Grid container alignItems="center">
                  <Grid item xs={10}>
                    {textField.type === "autocomplete" ? (
                      <Autocomplete
                        disablePortal
                        fullWidth
                        disabled={textField.disabled}
                        options={
                          options[textField.key as AutoCompleteOptionKeys]
                        }
                        getOptionLabel={(option) => option.title}
                        renderOption={(props, option) => (
                          <ListItem {...props} title={option.title}>
                            <ListItemText
                              primary={option.title}
                              secondary={option.subtitle || ""}
                            />
                          </ListItem>
                        )}
                        isOptionEqualToValue={(option) =>
                          option.value === form[textField.key]
                        }
                        value={options[
                          textField.key as AutoCompleteOptionKeys
                        ]?.find(
                          (option) => option.value === form[textField.key]
                        )}
                        onChange={(e, newValue) =>
                          handleAutoCompleteChange(e, newValue, textField.name)
                        }
                        // random key needs to be used in order to rerender the autocomplete field after async event
                        key={`autocomplete-${textField.key}-${
                          Math.random() * 1000
                        }`}
                        renderInput={(params) => (
                          <TextField
                            margin="normal"
                            {...params}
                            {...textField}
                          />
                        )}
                      />
                    ) : (
                      <TextField
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        onChange={handleChange}
                        InputLabelProps={{ shrink: true }}
                        {...textField}
                      />
                    )}
                  </Grid>

                  <Grid item xs={2}>
                    <InfoComponent
                      section="live-class"
                      textFieldName={textField.name}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ))}
        </Grid>
      </Grid>
      <VerticalDivider />
      <EditClassButtons
        handleUpdate={handleUpdate}
        handleDelete={handleDelete}
        liveClass={form as LiveClass}
        disableSubmit={disableSubmit}
      />
    </Grid>
  );
};

export default EditClassFormComponent;

interface Props {
  lcId: string;
}

interface AutoCompleteOptions {
  lcStatus: AutoCompleteOption[];
  lcCourseId: AutoCompleteOption[];
  lcDescHtml: AutoCompleteOption[];
  lcAge: AutoCompleteOption[];
  lcType: AutoCompleteOption[];
  lcCourseType: AutoCompleteOption[];
  lpId: AutoCompleteOption[];
  lcLevel: AutoCompleteOption[];
  lcUserGroup: AutoCompleteOption[];
  lcProgramId: AutoCompleteOption[];
  lcSupportId: AutoCompleteOption[];
  teacherName: AutoCompleteOption[];
  lcTargetLang: AutoCompleteOption[];
}

type AutoCompleteOptionKeys = keyof AutoCompleteOptions;

const autoCompleteOptions: AutoCompleteOptions = {
  lcStatus: [
    make(LIVE_CLASS_STATUS.PUBLISHED),
    make(LIVE_CLASS_STATUS.UNPUBLISHED),
  ],
  lcCourseId: [],
  lcDescHtml: [],
  lcAge: [
    make("9 - 12 years old"),
    make("13 - 21 years old"),
    make("15 years and above"),
    make("18 years and above"),
    make("20 years and above"),
  ],
  lcType: [make("Long", "long"), make("Short", "short")],
  lcCourseType: [make("Long", "long"), make("Short", "short")],
  lpId: [],
  lcLevel: [make("Beginner")],
  lcUserGroup: [],
  lcProgramId: [make("Food Panda", "foodPanda")],
  lcSupportId: [],
  teacherName: [],
  lcTargetLang: [make("English"), make("Bahasa Malaysia")],
};

type FormErrors = {
  [F in EditClassFormKeys]: boolean;
};

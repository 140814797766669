import React, { useState } from "react";
import {
  Typography,
  Button,
  ListItem,
  ListItemText,
  List,
  IconButton,
  Tooltip,
} from "@mui/material";
import GeneralDialogComponent from "../../../../components/GeneralDialogComponent";
import { Delete, Edit, Visibility } from "@mui/icons-material";
import moment from "moment";
import { CreateClassForm } from "../../../../interfaces";
import ViewDraftDialog from "./ViewDraftDialog";

const DraftDialog = (props: Props) => {
  const STRINGS = {
    CANCEL: "Cancel",
    CREATE_FROM_START: "Create From Start",
    NO_DRAFT: "No drafts available.",
  };

  const {
    open,
    handleClose,
    handleReset,
    drafts,
    handleUseDraft,
    handleDeleteDraft,
  } = props;

  const [viewDraft, setViewDraft] = useState<CreateClassForm | null>();

  const handleViewDraft = (draft: CreateClassForm) => {
    setViewDraft(draft);
  };
  const handleCloseView = () => {
    setViewDraft(null);
  };

  return (
    <GeneralDialogComponent
      open={open}
      onClose={handleClose}
      title={"Drafts Available"}
      actions={
        <>
          <Button variant="outlined" color="inherit" onClick={handleClose}>
            {STRINGS.CANCEL}
          </Button>
          <Button variant="contained" onClick={handleReset}>
            {STRINGS.CREATE_FROM_START}
          </Button>
        </>
      }
    >
      <List>
        {drafts.length ? (
          drafts.map((draft) => (
            <ListItem key={draft.lcId}>
              <ListItemText
                primary={
                  <Typography variant="body1" color="textPrimary">
                    {draft.lcTitle}
                  </Typography>
                }
                secondary={`${moment(draft.lcStartDateTs).format(
                  "DD MMM y, HH:mm:ss A"
                )}${" - "}${moment(draft.lcEndDateTs).format(
                  "DD MMM y, HH:mm:ss A"
                )}`}
              />
              <Tooltip title="Use Draft">
                <IconButton
                  edge="end"
                  onClick={() => handleUseDraft(draft.lcId)}
                >
                  <Edit />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton
                  edge="end"
                  onClick={() => handleDeleteDraft(draft.lcId)}
                >
                  <Delete />
                </IconButton>
              </Tooltip>
              <Tooltip title="View">
                <IconButton edge="end" onClick={() => handleViewDraft(draft)}>
                  <Visibility />
                </IconButton>
              </Tooltip>
              <ViewDraftDialog onClose={handleCloseView} draft={viewDraft} />
            </ListItem>
          ))
        ) : (
          <Typography variant="body1" color="textSecondary">
            {STRINGS.NO_DRAFT}
          </Typography>
        )}
      </List>
    </GeneralDialogComponent>
  );
};

interface Props {
  open: boolean;
  handleClose: () => void;
  drafts: CreateClassForm[];
  handleReset: () => void;
  handleUseDraft: (lcId: string) => void;
  handleDeleteDraft: (lcId: string) => void;
}

export default DraftDialog;

import React, { FC, useState } from "react";
import { Button, Divider, Grid, Stack, Typography } from "@mui/material";
import DeleteClassDialog from "./DeleteClassDialog";
import { SideDetailsBox } from "../../../../utils/styledComponents";
import ViewSessionDialog from "./ViewSessionDialog";
import { LiveClass } from "../../../../interfaces";
import AddSessionDialog from "../../../../components/AddSessionDialog";
import ViewExternalLinkDialog from "./AddExternalLinkDialog";
import { Delete, Edit } from "@mui/icons-material";

const EditClassButtons: FC<Props> = (props) => {
  const STRINGS = {
    ACTION_BUTTONS: "Action Buttons",
    UPDATE_CLASS: "Update Class",
    DELETE_CLASS: "Unpublish Class",
    BACK: "Back",
    SESSION: "Session",
    ADD_SESSION: "Add Session",
    VIEW_SESSION: "View Sessions",
    OK: "Okay",
    PLEASE_ADD_SESSION:
      "Please kindly add a new session for a freshly created live class.",
    HINT_UPDATE_CLASS: "Hint: Update the class",
    EXTERNAL_LINK: "External Link",
    ADD_EXTERNAL_LINK: "Add External Link",
  };

  const { liveClass, handleUpdate, handleDelete, disableSubmit } = props;

  const [prompt, setPrompt] = useState<DialogTypes>("");
  const handleClose = () => setPrompt("");

  const DialogComponent = () => {
    switch (prompt) {
      case "delete class":
        return (
          <DeleteClassDialog
            onClose={handleClose}
            handleDelete={handleDelete}
          />
        );
      case "add session":
        return <AddSessionDialog handleClose={handleClose} />;
      case "view session":
        return (
          <ViewSessionDialog liveClass={liveClass} handleClose={handleClose} />
        );
      case "add external link":
        return (
          <ViewExternalLinkDialog
            handleClose={handleClose}
            lcId={liveClass.lcId}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Grid item xs={12} lg={3}>
      <Stack divider={<Divider />}>
        <SideDetailsBox>
          <Typography variant="h5" sx={{ my: "1rem" }}>
            {STRINGS.ACTION_BUTTONS}
          </Typography>
          <Stack spacing={3}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={handleUpdate}
              disabled={disableSubmit}
              startIcon={<Edit />}
            >
              {STRINGS.UPDATE_CLASS}
            </Button>
            <Typography variant="caption">
              {STRINGS.HINT_UPDATE_CLASS}
            </Typography>

            <Button
              variant="outlined"
              color="error"
              fullWidth
              onClick={() => setPrompt("delete class")}
              startIcon={<Delete />}
            >
              {STRINGS.DELETE_CLASS}
            </Button>
          </Stack>
        </SideDetailsBox>

        <SideDetailsBox>
          <Typography variant="h5" sx={{ my: "1rem" }}>
            {STRINGS.SESSION}
          </Typography>
          <Stack spacing={3}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => setPrompt("add session")}
            >
              {STRINGS.ADD_SESSION}
            </Button>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              onClick={() => setPrompt("view session")}
            >
              {STRINGS.VIEW_SESSION}
            </Button>
          </Stack>
        </SideDetailsBox>

        <SideDetailsBox>
          <Typography variant="h5" sx={{ my: "1rem" }}>
            {STRINGS.EXTERNAL_LINK}
          </Typography>
          <Stack spacing={3}>
            <Button
              variant="contained"
              fullWidth
              onClick={() => setPrompt("add external link")}
            >
              {STRINGS.ADD_EXTERNAL_LINK}
            </Button>
          </Stack>
        </SideDetailsBox>
      </Stack>

      <DialogComponent />
    </Grid>
  );
};

export default EditClassButtons;

interface Props {
  handleUpdate: () => void;
  disableSubmit: boolean;
  handleDelete: () => void;
  liveClass: LiveClass;
}

type DialogTypes =
  | "update class"
  | "delete class"
  | "add session"
  | "view session"
  | "add external link"
  | "";

import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { UserProgress } from "../../../../interfaces";
import {
  BoldText,
  BorderLinearProgress,
} from "../../../../utils/styledComponents";

const Progress = (props: Props) => {
  const STRINGS = {
    NO_PROGRESS: "No Progress Recorded",
  };

  const { userProgress } = props;

  const displayPercentage = (number: number) => {
    if (number === 0) return "N/A";
    else return `${(number * 100).toFixed(2)}%`;
  };

  return (
    <Grid container sx={{ mt: "1rem" }}>
      {userProgress.length ? (
        userProgress.map(({ userFullname, userUid, userCourseProgress }) => (
          <Grid item xs={12} sx={{ pb: "1rem" }} key={userUid}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />} id={userUid}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={5}>
                    <BoldText>{userFullname}</BoldText>
                  </Grid>
                  <Grid item xs={5}>
                    <BorderLinearProgress
                      color="success"
                      variant="determinate"
                      sx={{ width: "100%" }}
                      value={userCourseProgress.course.courseScore * 100}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Typography textAlign="center">
                      {displayPercentage(userCourseProgress.course.courseScore)}
                    </Typography>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                {Object.values(userCourseProgress.module).map(
                  ({ moduleId, moduleScore, moduleTitle }) => (
                    <Accordion
                      sx={{ bgcolor: "grey.100" }}
                      key={userUid + moduleId}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        id={moduleId}
                      >
                        <Grid container spacing={2} alignItems="center">
                          <Grid item xs={5}>
                            <BoldText>{moduleTitle}</BoldText>
                          </Grid>
                          <Grid item xs={5}>
                            <BorderLinearProgress
                              color="success"
                              variant="determinate"
                              sx={{ width: "100%" }}
                              value={moduleScore * 100}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <Typography textAlign="center">
                              {displayPercentage(moduleScore)}
                            </Typography>
                          </Grid>
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails>
                        {Object.values(userCourseProgress.lesson)
                          .filter((lesson) => lesson.moduleId === moduleId)
                          .map(({ lessonId, lessonTitle, lessonScore }) => (
                            <Accordion key={userUid + lessonId}>
                              <AccordionSummary
                                expandIcon={<ExpandMore />}
                                id={lessonId}
                              >
                                <Grid container spacing={2} alignItems="center">
                                  <Grid item xs={5}>
                                    <BoldText>{lessonTitle}</BoldText>
                                  </Grid>
                                  <Grid item xs={5}>
                                    <BorderLinearProgress
                                      color="success"
                                      variant="determinate"
                                      sx={{ width: "100%" }}
                                      value={lessonScore * 100}
                                    />
                                  </Grid>
                                  <Grid item xs={2}>
                                    <Typography textAlign="center">
                                      {displayPercentage(lessonScore)}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </AccordionSummary>
                              <AccordionDetails>
                                {Object.values(userCourseProgress.card)
                                  .filter((card) => card.lessonId === lessonId)
                                  .map(
                                    (
                                      { cardId, cardScore, cardTitle },
                                      index
                                    ) => (
                                      <Grid
                                        container
                                        spacing={2}
                                        alignItems="center"
                                        key={userUid + cardId}
                                      >
                                        <Grid item xs={5}>
                                          <Typography>
                                            {index + 1}. {cardTitle}
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={5}>
                                          <BorderLinearProgress
                                            color="success"
                                            variant="determinate"
                                            sx={{ width: "100%" }}
                                            value={cardScore * 100}
                                          />
                                        </Grid>
                                        <Grid item xs={2}>
                                          <Typography textAlign="center">
                                            {displayPercentage(cardScore)}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    )
                                  )}
                              </AccordionDetails>
                            </Accordion>
                          ))}
                      </AccordionDetails>
                    </Accordion>
                  )
                )}
              </AccordionDetails>
            </Accordion>
          </Grid>
        ))
      ) : (
        <Typography sx={{ mx: 3 }}>{STRINGS.NO_PROGRESS}</Typography>
      )}
    </Grid>
  );
};

export default Progress;

interface Props {
  userProgress: UserProgress[];
}

import React from 'react'
import { Switch, useRouteMatch } from 'react-router-dom'
import PrivateRoute from '../../layouts/Router/PrivateRoute'
import TeacherPage from './components'
import TeacherDetailsPage from './TeacherDetails'

const Teacher = () => {
	const { path } = useRouteMatch()

	return (
		<Switch>
			<PrivateRoute exact path={path} component={TeacherPage} />
			<PrivateRoute path={`${path}/:teacherId`} component={TeacherDetailsPage} />
		</Switch>
	)
}

export default Teacher

import React, { FC } from 'react'
import Form from './components/Form';
import {Grid} from "@mui/material";
import Box from "@mui/material/Box";


const Login: FC = () => {

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid
        container spacing={2} direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          <Form/>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Login

import React, { useEffect, useState } from 'react'
import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	Button,
} from '@mui/material'
import { Add } from '@mui/icons-material'
import { Link, useHistory, useRouteMatch } from 'react-router-dom'
import { Category, Cell } from '../../../interfaces'
import { BoxRow } from '../../../utils/styledComponents'
import GeneralTableComponent from '../../../components/GeneralTableComponent'
import GeneralAvatarComponent from '../../../components/GeneralAvatarComponent'
import CategoryService from '../../../services/category.service'

export default function CategoryTable() {
	const { path } = useRouteMatch()

	const [categories, setCategories] = useState<Category[]>([])
	const [loading, setLoading] = useState(false)

	const cells: Cell[] = [{ name: 'Category', align: 'left' }]

	const call = async () => {
		setLoading(true)
		const categories = await CategoryService.readCategories()
		setCategories(categories)
		setLoading(false)
	}

	useEffect(() => {
		call()
	}, [])

	return (
		<Box sx={{ mt: '1rem' }}>
			<GeneralTableComponent onRefresh={call} loading={loading}>
				<Table>
					<TableHead>
						<TableRow>
							{cells.map((cell) => (
								<TableCell align={cell.align} key={cell.name}>
									{cell.name}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{categories.map((row) => (
							<TableRow key={row.lcCategoryId}>
								<TableCell>
									<Box display='flex'>
										<GeneralAvatarComponent src={row.lcCategoryImage} />
										<Box display='flex' flexDirection='column' justifyContent='center'>
											<Typography
												variant='subtitle2'
												component={Link}
												to={`${path}/${row.lcCategoryId}`}
												sx={{ textDecoration: 'none' }}
												color='primary.main'>
												{row.lcCategoryName}
											</Typography>
											<Typography variant='caption' color='textSecondary'>
												{row.lcCategoryDesc}
											</Typography>
											<Typography variant='caption' color='textSecondary'>
												{row.lcCategoryId}
											</Typography>
										</Box>
									</Box>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</GeneralTableComponent>
		</Box>
	)
}

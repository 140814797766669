import React, { useEffect, useState } from "react";
import { useParams, useRouteMatch } from "react-router-dom";
import BreadcrumbsComponent from "../../../components/Breadcrumbs";
import AppContainer from "../../../containers/AppContainer";
import { LiveClass, UserProgress } from "../../../interfaces";
import LiveClassService from "../../../services/liveclass.service";
import { ProgressService } from "../../../services/progress.service";
import UserService from "../../../services/user.service";
import Details from "./components/LiveClassDetail";
import Progress from "./components/Progress";

const LiveClassProgress = () => {
  const { url } = useRouteMatch();
  const { lcId }: any = useParams();

  const [liveClass, setLiveClass] = useState<LiveClass>({} as LiveClass);
  const [userProgress, setUserProgress] = useState<UserProgress[]>([]);

  const fetchUserProductLiveClasses = async (liveClass: LiveClass) => {
    // setUserProgress([]);

    const users = await UserService.readAllUserAsObject();
    const userScores = await ProgressService.getAllUserScores();
    const userProgress: UserProgress[] = [];

    for (let user in userScores) {
      if (!users[user] || !userScores[user][liveClass.lpId]) continue;
      const { full_name, userName, email, userUid } = users[user];

      userProgress.push({
        userFullname: full_name || userName,
        userEmail: email,
        userUid,
        userCourseProgress: userScores[user][liveClass.lpId],
      });
    }
    console.log(userProgress);
    setUserProgress(userProgress);
  };

  useEffect(() => {
    (async () => {
      const lcRes = (await LiveClassService.readOneLiveClass(
        lcId
      )) as LiveClass;
      setLiveClass(lcRes || ({} as LiveClass));
      await fetchUserProductLiveClasses(lcRes);
    })();
  }, []);

  return (
    <AppContainer headerTitle={"Class Details"}>
      <BreadcrumbsComponent url={url} />
      <Details
        liveClass={liveClass}
        onRefresh={() => fetchUserProductLiveClasses(liveClass)}
      />
      <Progress userProgress={userProgress} />
    </AppContainer>
  );
};

export default LiveClassProgress;

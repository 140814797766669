import React, { useEffect, useState } from 'react'
import {
	Avatar,
	IconButton,
	TableBody,
	TableCell,
	TableFooter,
	TableHead,
	TablePagination,
	TableRow,
	Typography,
} from '@mui/material'
import { RemoveRedEyeOutlined } from '@mui/icons-material'

import { BoxColumn } from '../../../utils/styledComponents'
import TeacherService from '../../../services/teacher.service'
import { Teacher, User } from '../../../interfaces'
import { Link, useHistory } from 'react-router-dom'
import GeneralTableComponent from '../../../components/GeneralTableComponent'
import { makeAvatarName } from '../../../utils/makeAvatarName'
import Moment from 'react-moment'

const TeacherTable = () => {
	const history = useHistory()

	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(50)
	const [teachers, setTeachers] = useState<Teacher[]>([])
	const [loading, setLoading] = useState(false)

	const avatarDisplay = (teacher: Teacher) => {
		return (
			<Avatar src={teacher.teacherImage || ''}>
				{makeAvatarName(teacher.teacherName || teacher.teacherSlug)}
			</Avatar>
		)
	}

	const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		setRowsPerPage(parseInt(event.target.value, 10))
		setPage(0)
	}

	const call = async () => {
		setLoading(true)
		const results = await TeacherService.readTeachers()
		setTeachers(results)
		setLoading(false)
	}

	useEffect(() => {
		call()
	}, [])

	return (
		<GeneralTableComponent onRefresh={call} loading={loading}>
			<TableHead>
				<TableRow>
					<TableCell>Name</TableCell>
					{/* <TableCell align='center'>Last Login</TableCell> */}
					<TableCell>View</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{(rowsPerPage > 0
					? teachers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
					: teachers
				).map((teacher) => (
					<TableRow key={teacher.teacherId}>
						<TableCell sx={{ display: 'flex', alignItems: 'center' }}>
							{avatarDisplay(teacher)}
							<BoxColumn sx={{ pl: '1rem' }}>
								{teacher.teacherName || teacher.teacherSlug}
								<Typography variant='caption'>{teacher.teacherEmail}</Typography>
							</BoxColumn>
						</TableCell>
						{/* <TableCell align='center'> */}
						{/* todo: change to dynamic online checker */}
						{/* {teacher.lastLogin ? (
								<Moment format='DD MMM, yyyy HH:mm:ss A'>{teacher.lastLogin}</Moment>
							) : (
								'-'
							)} */}
						{/* </TableCell> */}
						<TableCell>
							<Link to={`/teacher/${teacher.teacherId}`}>
								<IconButton>
									<RemoveRedEyeOutlined />
								</IconButton>
							</Link>
						</TableCell>
					</TableRow>
				))}
			</TableBody>
			<TableFooter>
				<TableRow>
					<TablePagination
						rowsPerPageOptions={[50, 100, 250]}
						count={teachers.length}
						page={page}
						rowsPerPage={rowsPerPage}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				</TableRow>
			</TableFooter>
		</GeneralTableComponent>
	)
}

export default TeacherTable

import React, { useEffect, useState } from 'react'
import { Button, Divider, Grid, Stack, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { SideDetailsBox } from '../../../../utils/styledComponents'
import BackdropComponent from '../../../../components/BackDropComponent'
import GeneralDialogComponent from '../../../../components/GeneralDialogComponent'

const SponsorActionButtons = (props: Props) => {
	const STRINGS = {
		ACTION_BUTTONS: 'Action Buttons',
		UPDATE_SPONSOR: 'update sponsor',
		ADD: 'Add',
		CANCEL: 'cancel',
		DELETE_SPONSOR: 'delete sponsor',
	}

	const { uid }: any = useParams()
	const { handleUpdate, disabled, handleDelete } = props
	const { enqueueSnackbar } = useSnackbar()
	const [showBackdrop, setShowBackDrop] = useState<boolean>(false)

	const [promptDelete, setPromptDelete] = useState(false)
	const handleOpenPrompt = () => setPromptDelete(true)
	const handleClosePrompt = () => setPromptDelete(false)

	async function reloadPage() {
		if (window) {
			await window.location.reload()
		}
	}

	useEffect(() => {}, [])

	return (
		<Grid item xs={12} lg={3}>
			<Stack divider={<Divider />}>
				<SideDetailsBox>
					<Stack spacing={3}>
						<Button
							disabled={disabled}
							variant='contained'
							color={'secondary'}
							fullWidth
							onClick={handleUpdate}>
							{STRINGS.UPDATE_SPONSOR}
						</Button>
						<Button variant='outlined' color='error' fullWidth onClick={handleOpenPrompt}>
							{STRINGS.DELETE_SPONSOR}
						</Button>
					</Stack>
				</SideDetailsBox>
			</Stack>

			{/* delete confirmatoin  */}
			<GeneralDialogComponent
				open={promptDelete}
				onClose={handleClosePrompt}
				title={'Delete Sponsor'}
				children={<Typography> Are you sure you want to delete this Sponsor? </Typography>}
				actions={
					<>
						<Button
							variant='contained'
							color='inherit'
							onClick={handleClosePrompt}
							sx={{ mr: '1rem' }}>
							Cancel
						</Button>
						<Button
							variant='contained'
							color='error'
							onClick={() => {
								handleDelete()
								handleClosePrompt()
							}}>
							Delete
						</Button>
					</>
				}></GeneralDialogComponent>

			{showBackdrop && <BackdropComponent />}
		</Grid>
	)
}

interface Props {
	handleUpdate: () => void
	handleDelete: () => void
	disabled: boolean
}

export default SponsorActionButtons

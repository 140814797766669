import { get, ref, update } from "firebase/database";
import { LiveClassExtras } from "../interfaces";
import { db } from "./firebaseApp";

export const LiveClassExtrasService = {
  async readExtras(lcId: string): Promise<LiveClassExtras> {
    const extrasRef = ref(
      db,
      `/sepContent/liveClass/lcListExtras/english/${lcId}`
    );
    const snapshot = await get(extrasRef);
    return snapshot.val();
  },
  async updateExternalLink(externalLink: string, lcId: string) {
    const extrasRef = ref(
      db,
      `/sepContent/liveClass/lcListExtras/english/${lcId}`
    );
    const values: LiveClassExtras = {
      externalLink,
      external: true,
      extraHtml: "",
    };
    return await update(extrasRef, values);
  },
};

import { Button, Typography } from "@mui/material";
import React from "react";
import GeneralDialogComponent from "../../../../components/GeneralDialogComponent";

const DeleteClassDialog = (props: Props) => {
  const { onClose, handleDelete } = props;

  return (
    <GeneralDialogComponent
      open
      onClose={onClose}
      title={"Unpublish Class"}
      actions={
        <>
          <Button
            variant="contained"
            color="inherit"
            onClick={onClose}
            sx={{ mr: "1rem" }}
          >
            Cancel
          </Button>
          <Button variant="contained" color="error" onClick={handleDelete}>
            Unpublish
          </Button>
        </>
      }
    >
      <Typography sx={{ pt: "1.5rem", pb: "1.5rem" }}>
        Are you sure? You can't undo this action afterwards.
      </Typography>
    </GeneralDialogComponent>
  );
};

interface Props {
  onClose: () => void;
  handleDelete: () => void;
}

export default DeleteClassDialog;

import { Box, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams, useRouteMatch } from "react-router-dom";
import BreadcrumbsComponent from "../../../../components/Breadcrumbs";
import { TabPanel } from "../../../../components/TabPanel";
import AppContainer from "../../../../containers/AppContainer";
import { Session, SessionUser } from "../../../../interfaces";
import SessionService from "../../../../services/session.service";
import AddUserToSessionBox from "./components/AddUserToSessionBox";
import SessionDetailsSection from "./components/SessionDetailsSection";
import SessionUserTable from "./components/SessionUserTable";

export default function SessionDetails() {
  const { url } = useRouteMatch();
  const { sessionId }: any = useParams();
  const [session, setSession] = useState<Session>();
  const [sessionUsers, setSessionUsers] = useState<SessionUser[]>([]);
  const [loading, setLoading] = useState(false);
  const [tabIndex, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const call = async () => {
    setLoading(true);
    const session = await SessionService.readOneSession(sessionId);
    setSession(session);

    const sessionUsers = await SessionService.readAllSessionUsers(sessionId);
    setSessionUsers(sessionUsers);
    setLoading(false);
  };

  useEffect(() => {
    call();
  }, []);

  return (
    <AppContainer headerTitle="Session Details">
      <BreadcrumbsComponent url={url} />
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider", m: "1rem" }}>
          <Tabs
            value={tabIndex}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Session Details" />
            <Tab label="Users" />
          </Tabs>
        </Box>
        <TabPanel value={tabIndex} index={0}>
          {session ? (
            <SessionDetailsSection session={session} refreshCallback={call} />
          ) : null}
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          {/* <AddUserToSessionBox sessionUsers={sessionUsers} /> */}
          <SessionUserTable
            sessionUsers={sessionUsers}
            call={call}
            loading={loading}
          />
        </TabPanel>
      </Box>
    </AppContainer>
  );
}

import { EditClassForm } from "../interfaces";

export const EditClassFormTemplate: EditClassForm = {
  // Main Details
  lcTitle: "",
  lcId: "",
  lcImage: "",
  lcType: "",
  lcCourseType: "",
  lcStartDateTs: "",
  lcEndDateTs: "",
  lcCourseLength: "",
  lcCourseId: "",
  lcDescHtml: "",
  lpId: "",

  // Additional Details
  lcDataA: "",
  lcDataB: "",
  lcDesc: "",
  lcAge: "",
  lcCategory: "",
  lcCategoryId: "",
  lcLevel: "",
  lcPrerequisite: "",
  lcSessionsDates: "",
  lcTagId: "",
  lcTargetLang: "English",
  lcUserGroup: "",
  lcProgramId: "",
  lcSupportId: "",
  targetGroup: "",
  teacherEmail: "",
  teacherName: "",
  totalSlots: 0,
  createdAt: undefined,
  updatedAt: "",
  updatedBy: "",
};

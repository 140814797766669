import React, { useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import BreadcrumbsComponent from '../../../components/Breadcrumbs'
import AppContainer from '../../../containers/AppContainer'
import AddSponsorBox from './AddSponsorBox'
import AddSponsorDialog from './AddSponsorDialog'
import SponsorTable from './SponsorTable'

const SponsorPage = () => {
	const STRINGS = {
		TITLE: 'Sponsors',
		ADD_SPONSOR: 'Add Sponsor',
		SUBMIT: 'Submit',
		SUCCESS_ADD_SPONSOR: 'Successfully added Sponsor!',
		ERROR_ADD_SPONSOR: 'Failed to add Sponsor!',
		RICH_EDITOR_LABLE: 'Sponsor HTML',

	}

	const { url } = useRouteMatch()
	const [open, setOpen] = useState(false)
	const handleOpen = () => setOpen(true)
	const handleClose = () => setOpen(false)

	return (
		<AppContainer headerTitle={STRINGS.TITLE}>
			<BreadcrumbsComponent url={url} />
			<AddSponsorBox handleOpen={handleOpen} STRINGS={STRINGS} />
			<AddSponsorDialog open={open} handleClose={handleClose} STRINGS={STRINGS} />
			<SponsorTable />
		</AppContainer>
	)
}

export default SponsorPage

import React from "react";
import {useRouteMatch} from "react-router-dom";
import BreadcrumbsComponent from "../../../components/Breadcrumbs";
import AppContainer from "../../../containers/AppContainer";
import CourseTable from "./CourseTable";

const CoursePage = () => {
  const {url} = useRouteMatch();
  return (
    <AppContainer headerTitle={"Courses"}>
      <BreadcrumbsComponent url={url}/>
      <CourseTable/>
    </AppContainer>
  );
};

export default CoursePage;

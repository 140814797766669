import React, { FC, useState, useEffect } from "react";
import { Box, Breadcrumbs, Button, Divider, Typography } from "@mui/material";
import { ArrowBack, NavigateNext } from "@mui/icons-material";
import { Link } from "react-router-dom";

const BreadcrumbsComponent: FC<Props> = ({ url }) => {
  const STRINGS = {
    BACK: "Back",
  };
  const [links, setLinks] = useState<Links[]>([]);

  // list down unwanted links here that navigates to nowhere
  const unwantedLinks = [
    "class details",
    "user details",
    "edit class",
    "progress",
  ];

  const removeLinks = (array: Array<Links>, name: string) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].name === name) {
        array.splice(i, 1);
      }
    }
  };

  useEffect(() => {
    let params = url.split("/");
    params.shift();

    let url_list: Links[] = [];
    params.forEach((url, id) => {
      url_list.push({
        to: id > 0 ? `${url_list[id - 1]?.to}/${url}` : url,
        name: url.split("-").join(" "),
      });
    });

    unwantedLinks.forEach((name) => {
      removeLinks(url_list, name);
    });

    setLinks(url_list);
    return () => {
      setLinks([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  return (
    <Box>
      <Breadcrumbs
        separator={<NavigateNext fontSize="small" />}
        sx={{ pl: "1rem", pb: "1rem", textTransform: "capitalize" }}
      >
        <Link to="/" style={{ textDecoration: "none", color: "black" }}>
          Dashboard
        </Link>
        {links.map((link, id) =>
          id !== links.length - 1 ? (
            <Link
              to={`/${link.to}`}
              style={{ textDecoration: "none", color: "black" }}
              key={link.to}
            >
              {link.name}
            </Link>
          ) : (
            <Typography key={link.to}>{link.name}</Typography>
          )
        )}
      </Breadcrumbs>
      <Divider variant="middle" />
      {links.length > 1 ? (
        <Link to={`/${links[links.length - 2].to}`}>
          <Button
            startIcon={<ArrowBack />}
            sx={{
              mt: "2rem",
              color: "grey.600",
              ":hover": { backgroundColor: "grey.200" },
            }}
          >
            {STRINGS.BACK}
          </Button>
        </Link>
      ) : null}
    </Box>
  );
};

export default BreadcrumbsComponent;

interface Props {
  url: string;
}

interface Links {
  to: string;
  name: string;
}

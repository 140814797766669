import React, {useEffect, useState} from "react";
import {useParams, useRouteMatch} from "react-router-dom";
import BreadcrumbsComponent from "../../../components/Breadcrumbs";
import AppContainer from "../../../containers/AppContainer";
import {LiveClass, UserProgress} from "../../../interfaces";
import {CourseService} from "../../../services/course.service";
import {ProgressService} from "../../../services/progress.service";
import UserService from "../../../services/user.service";
import Progress from "./components/Progress";
import BackdropComponent from "../../../components/BackDropComponent";

const CourseProgress = () => {
  const {url} = useRouteMatch();
  const {courseId}: any = useParams();

  const [liveClass, setLiveClass] = useState<LiveClass>({} as LiveClass);
  const [userProgress, setUserProgress] = useState<UserProgress[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const fetchUserProductCourses = async () => {
    setUserProgress([]);

    // First get the users who have this course
    const users = await CourseService.getUserIdPerCourse(courseId);
    // we got the user ids for this course
    console.log(users);
    // Now get the progress for each user
    // const progress = await CourseService.getUserProgressPerCourse(courseId);

    for (const userId of users) {
      const progress = await ProgressService.getOneUserScore(userId, courseId);
      console.log(progress);
      //get user details
      const userDetail = await UserService.readOneUser(userId);
      // console.log(userDetail);
      if (progress && userDetail) {
        userProgress.push({
          userFullname: userDetail.full_name || userDetail.userName,
          userEmail: userDetail.email,
          userUid: userDetail.userUid,
          userCourseProgress: progress,
        });
      }
    }

    console.log(userProgress)
    setUserProgress(userProgress);
    setIsLoading(false);
  };

  useEffect(() => {
    (async () => {

      await fetchUserProductCourses();
    })();
  }, []);

  return (
    <AppContainer headerTitle={"Class Details"}>
      <BreadcrumbsComponent url={url}/>
      {/*<Details liveClass={liveClass} onRefresh={fetchUserProductLiveClasses}/>*/}
      {isLoading && <BackdropComponent/>}
      {userProgress ? <Progress userProgress={userProgress}/> : null}
    </AppContainer>
  );
};

export default CourseProgress;

export const REGEX = {
  NAME: /^[a-zA-Z ,.'-]+$/i,
  WORDS: /^[a-zA-Z]+$/i,
  EMAIL: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
  MALAYSIAN_NRIC: /^[0-9]{12,}$/,
  ADDRESS: /^[a-zA-Z0-9\s,'-]*$/,
  MALAYSIAN_PHONE: /^(\+?6?01)[0-46-9]-*[0-9]{7,8}$/,
  NUMBER: /^[0-9]+$/,
  ANY: /^[\s\S]*$/,
  IMAGE: /^https?:\/\/.+\.(jpg|jpeg|png|webp|avif|gif|svg)$/,
};

import React from 'react'
import { Switch, useRouteMatch } from 'react-router-dom'
import PrivateRoute from '../../layouts/Router/PrivateRoute'
import CategoryDetailsPage from './CategoryDetails'
import CategoryPage from './components'

const Category = () => {
	const { path } = useRouteMatch()

	return (
		<Switch>
			<PrivateRoute exact path={path} component={CategoryPage} />
			<PrivateRoute exact path={`${path}/:lcCategoryId`} component={CategoryDetailsPage} />
		</Switch>
	)
}

export default Category

import moment from "moment";
import { CreateClassForm } from "../interfaces";

export const CreateClassFormTemplate: CreateClassForm = {
  lcId: "",
  lcCategoryId: "",
  lpId: "",
  lcTitle: "",
  teacherId: "",
  lcCourseLength: "",
  lcSupportId: "",
  lcTargetLang: "English",
  lcAge: "",
  totalSlots: 120,
  lcStartDateTs: moment().valueOf(),
  lcEndDateTs: moment().valueOf(),
  lcImage: "",
  meetingLink: "",
};

import {
  Add,
  AddToQueue,
  Category,
  OndemandVideo,
  School,
} from "@mui/icons-material";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Stack,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const QuickLinks = () => {
  const STRINGS = {
    QUICK_LINKS: "Quick Links",
    OTHER_LINKS: "Other Links",
  };
  return (
    <Box component="nav">
      <Stack direction="row" spacing={3}>
        <List
          subheader={<ListSubheader>{STRINGS.QUICK_LINKS}</ListSubheader>}
          sx={{ backgroundColor: "background.paper" }}
        >
          {QUICK_LINKS.map((link) => (
            <ListItem disablePadding key={link.url}>
              <Box component={Link} to={link.url} sx={{ width: "100%" }}>
                <ListItemButton>
                  {link.icon ? <ListItemIcon>{link.icon}</ListItemIcon> : null}
                  <ListItemText primary={link.title} />
                </ListItemButton>
              </Box>
            </ListItem>
          ))}
        </List>
        <List
          subheader={<ListSubheader>{STRINGS.OTHER_LINKS}</ListSubheader>}
          sx={{ backgroundColor: "background.paper" }}
        >
          {OTHER_LINKS.map((link) => (
            <ListItem disablePadding key={link.url}>
              <Box component={Link} to={link.url} sx={{ width: "100%" }}>
                <ListItemButton>
                  {link.icon ? <ListItemIcon>{link.icon}</ListItemIcon> : null}
                  <ListItemText primary={link.title} />
                </ListItemButton>
              </Box>
            </ListItem>
          ))}
        </List>
      </Stack>
    </Box>
  );
};

const makeLink = (title: string, url: string, icon?: JSX.Element) => ({
  title,
  url,
  icon,
});
const QUICK_LINKS = [
  makeLink("View Live Classes", "/live-class", <OndemandVideo />),
  makeLink("Create New Live Class", "/live-class/create-class", <Add />),
  makeLink("View Parent Live Classes", "/parent-class", <AddToQueue />),
  makeLink(
    "Create New Parent Live Class",
    "/parent-class/create-parent-class",
    <Add />
  ),
];
const OTHER_LINKS = [
  makeLink("View Teachers", "/teacher", <School />),
  makeLink("Create New Teacher", "/teacher#add", <Add />),
  makeLink("View Categories", "/category", <Category />),
  makeLink("Create New Category", "/category#add", <Add />),
];

export default QuickLinks;

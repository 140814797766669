import React, { useState } from "react";
import { useLocation, useRouteMatch } from "react-router-dom";
import BreadcrumbsComponent from "../../../components/Breadcrumbs";
import AppContainer from "../../../containers/AppContainer";
import AddCategoryBox from "../CreateCategory/components/AddCategoryBox";
import CreateCategoryForm from "../CreateCategory/components/CreateCategoryForm";
import CategoryTable from "./CategoryTable";

const CategoryPage = () => {
  const STRINGS = {
    TITLE: "Categories",
    ADD_CATEGORY: "Add Category",
    SUBMIT: "Submit",
    SUCCESS_ADD_CATEGORY: "Successfully added Category!",
    ERROR_ADD_CATEGORY: "Failed to add Category!",
    RICH_EDITOR_LABLE: "Category HTML",
  };

  const { url } = useRouteMatch();
  const { hash } = useLocation();
  const [open, setOpen] = useState(hash === "#add");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <AppContainer headerTitle="Category">
      <BreadcrumbsComponent url={url} />
      <AddCategoryBox handleOpen={handleOpen} STRINGS={STRINGS} />
      <CreateCategoryForm
        open={open}
        handleClose={handleClose}
        STRINGS={STRINGS}
      />
      <CategoryTable />
    </AppContainer>
  );
};

export default CategoryPage;

import React from "react";
import { Switch } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import Login from "../../pages/Login";
import Home from "../../pages/Home";
import UserPage from "../../pages/User";
import UserDetails from "../../pages/ClassDetails/UserDetails";
import LiveClass from "../../pages/LiveClass";
import CoursePage from "../../pages/Course";
import CertificatePage from "../../pages/Certificate";
import GroupPage from "../../pages/Group";
import ParentLiveClass from '../../pages/LiveClass/ParentLiveClass'
import TeacherPage from '../../pages/Teacher'
import Category from '../../pages/Category'
import Sponsor from '../../pages/Sponsor'

const Routes = () => {
	return (
		<Switch>
			{/* admin */}
			<PrivateRoute exact path='/' component={Home} />
			<PrivateRoute path='/user' component={UserPage} />
			<PrivateRoute path='/live-class' component={LiveClass} />
			<PrivateRoute path='/parent-class' component={ParentLiveClass} />
			<PrivateRoute path='/course' component={CoursePage} />
			<PrivateRoute path='/teacher' component={TeacherPage} />
			<PrivateRoute path='/category' component={Category} />
			<PrivateRoute path='/sponsor' component={Sponsor} />

			<PrivateRoute path='/class-details/:classId/user-details/:UserId' component={UserDetails} />
			<PrivateRoute path='/group' component={GroupPage} />
			<PrivateRoute path='/certificate' component={CertificatePage} />

			{/* authentication */}
			<PublicRoute path='/login' component={Login} />

			{/*<ComingSoon/>*/}
		</Switch>
	)
}

export default Routes;

import { ref, set, get, remove, update } from "firebase/database";
import { ParentLiveClass } from "../interfaces";
import { db } from "./firebaseApp";

const LpService = {
  // Parent live class

  async readAllDrafts(): Promise<ParentLiveClass[]> {
    const draftRef = ref(db, `/sepContent/liveClass/lcDraft/lpDraftList`);
    const snapshot = await get(draftRef);
    const draftList: ParentLiveClass[] = [];
    snapshot.forEach((snap) => {
      draftList.push(snap.val());
    });
    return draftList;
  },

  async createDraft(data: ParentLiveClass) {
    data.lcDescHtml = "";
    const lpRef = ref(
      db,
      `/sepContent/liveClass/lcDraft/lpDraftList/${data.lpId}`
    );
    return await set(lpRef, data);
  },

  async deleteDraft(id: string) {
    const lpRef = ref(db, `/sepContent/liveClass/lcDraft/lpDraftList/${id}`);
    return await remove(lpRef);
  },

  async publishParentLiveClass(data: ParentLiveClass) {
    const lcDescHtml = {
      lcTitle: data.lpTitle,
      lcDescHtml: data.lcDescHtml,
      lpId: data.lpId,
    };
    console.log("first");

    // clear the html from the data to prevent duplication
    data.lcDescHtml = "";

    const lpRef = ref(db, `/sepContent/liveClass/lpList/english/${data.lpId}`);
    await set(lpRef, data);

    const htmlRef = ref(
      db,
      `/sepContent/liveClass/lcDetailHtml/english/${data.lpId}`
    );
    await set(htmlRef, lcDescHtml);
  },

  async readParentLiveClasses(): Promise<ParentLiveClass[]> {
    const lcRef = ref(db, `/sepContent/liveClass/lpList/english`);
    const snapshot = await get(lcRef);
    const parentLiveClasses: ParentLiveClass[] = [];
    snapshot.forEach((snap) => {
      parentLiveClasses.push(snap.val());
    });
    return parentLiveClasses;
  },
  async readOneParentLiveClass(id: string): Promise<ParentLiveClass> {
    const lpRef = ref(db, `/sepContent/liveClass/lpList/english/${id}`);
    const snapshot = await get(lpRef);
    return snapshot.val();
  },

  async updateParentLiveClass(data: ParentLiveClass, currentLpId: string) {
    const lcDescHtml = {
      lcTitle: data.lpTitle,
      lcDescHtml: data.lcDescHtml,
      lpId: data.lpId,
    };

    // clear the html from the data to prevent duplication
    data.lcDescHtml = "";

    if (currentLpId !== data.lpId) {
      // update parent live class
      const newlpRef = ref(
        db,
        `/sepContent/liveClass/lpList/english/${data.lpId}`
      );
      const currentlpRef = ref(
        db,
        `/sepContent/liveClass/lpList/english/${currentLpId}`
      );
      await set(newlpRef, data);
      await remove(currentlpRef);

      // update parent live class html
      const newhtmlRef = ref(
        db,
        `/sepContent/liveClass/lcDetailHtml/english/${data.lpId}`
      );
      const currenthtmlRef = ref(
        db,
        `/sepContent/liveClass/lcDetailHtml/english/${currentLpId}`
      );
      await set(newhtmlRef, lcDescHtml);
      await remove(currenthtmlRef);
    } else {
      const lpRef = ref(
        db,
        `/sepContent/liveClass/lpList/english/${data.lpId}`
      );
      await update(lpRef, data);

      const newhtmlRef = ref(
        db,
        `/sepContent/liveClass/lcDetailHtml/english/${data.lpId}`
      );
      await update(newhtmlRef, lcDescHtml);
    }
  },

  async readParentLiveClassHtml(lpId: string): Promise<ParentLiveClass> {
    const lpHtmlRef = ref(
      db,
      `/sepContent/liveClass/lcDetailHtml/english/${lpId}`
    );
    const snapshot = await get(lpHtmlRef);
    return snapshot.val();
  },

  // async deleteParentLiveClass(id: string) {
  // 	const lpRef = ref(db, `/sepContent/liveClass/lpList/english/${id}`)
  // 	await remove(lpRef)
  // },
};

export default LpService;

import React, { FC, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import GeneralDialogComponent from "../../../../components/GeneralDialogComponent";
import { BoldText, BoxRow } from "../../../../utils/styledComponents";
import { Add } from "@mui/icons-material";

const CreateClassButtons: FC<Props> = ({
  disableSubmit,
  handleSubmit,
  handleReset,
  handlePublish,
  handleShowDrafts,
  disableShowDrafts,
  setParentFormOpen,
  handleTeacherDialogOpen,
}) => {
  const [promptPublish, setPromptPublish] = useState(false);
  const handleOpenPrompt = () => setPromptPublish(true);
  const handleClosePrompt = () => setPromptPublish(false);

  return (
    <Grid item xs={12} lg={3}>
      <Box sx={{ margin: "3rem 1rem 0rem" }}>
        <Typography variant="h5" sx={{ my: "3rem" }}>
          Action Buttons
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={handleSubmit}
              disabled={disableSubmit}
            >
              Save Draft
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              color="secondary"
              fullWidth
              onClick={handleShowDrafts}
              disabled={disableShowDrafts}
            >
              Show Drafts
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleOpenPrompt}
              disabled={disableSubmit}
            >
              Publish class
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              color="error"
              fullWidth
              onClick={handleReset}
              disabled={disableSubmit}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ margin: "3rem 1rem 0rem" }}>
        <Typography variant="h5" sx={{ my: "3rem" }}>
          Parent Class
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={() => setParentFormOpen(true)}
              startIcon={<Add />}
            >
              Add Parent Class
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ margin: "3rem 1rem 0rem" }}>
        <Typography variant="h5" sx={{ my: "3rem" }}>
          Teachers
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={() => handleTeacherDialogOpen(true)}
              startIcon={<Add />}
            >
              Add Teacher
            </Button>
          </Grid>
        </Grid>
      </Box>
      <GeneralDialogComponent
        open={promptPublish}
        onClose={handleClosePrompt}
        title={"Publish Class"}
        actions={
          <>
            <Button
              variant="contained"
              color="inherit"
              onClick={handleClosePrompt}
              sx={{ mr: "1rem" }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handlePublish}
              disabled={disableSubmit}
            >
              Publish
            </Button>
          </>
        }
      >
        <BoldText variant="h6">Publish Class</BoldText>
        <Typography sx={{ pt: "1.5rem", pb: "1.5rem" }}>
          Are you sure? You can't undo this action afterwards.
        </Typography>
        <BoxRow sx={{ justifyContent: "flex-end" }}></BoxRow>
      </GeneralDialogComponent>
    </Grid>
  );
};

export default CreateClassButtons;

interface Props {
  disableSubmit: boolean;
  disableShowDrafts: boolean;
  handleSubmit: () => void;
  handleReset: () => void;
  handlePublish: () => void;
  handleShowDrafts: () => void;
  setParentFormOpen: (value: boolean) => void;
  handleTeacherDialogOpen: (value: boolean) => void;
}

import { EditParentClassForm } from "../../../../../interfaces/ParentLiveClass.interface";

export const EditParentClassFormTemplate: EditParentClassForm = {
  lpEndDateTs: "",
  lpStartDateTs: "",
  lpImage: "",
  lpTitle: "",
  lpDesc: "",
  lpCategoryId: "",
  createdAt: "",
  updatedAt: "",
  updatedBy: "",
  lcDescHtml: "",
};

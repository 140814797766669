import React, { useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { Grid } from '@mui/material'
import BreadcrumbsComponent from '../../../components/Breadcrumbs'
import AppContainer from '../../../containers/AppContainer'
import VerticalDivider from '../../../components/VerticalDivider'
import CategoryDetailsForm from './components/CategoryDetailsForm'

const CategoryDetailsPage = () => {
	const { url } = useRouteMatch()
	const [updating, setUpdating] = useState(false)

	return (
		<AppContainer headerTitle='Create Category'>
			<BreadcrumbsComponent url={url} />
			<CategoryDetailsForm />
		</AppContainer>
	)
}

export default CategoryDetailsPage

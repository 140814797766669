import React from "react";
import { useRouteMatch } from "react-router-dom";
import BreadcrumbsComponent from "../../../../components/Breadcrumbs";
import AppContainer from "../../../../containers/AppContainer";
import ParentLiveClassTable from "./ParentLiveClassTable";

const ParentLiveClassPage = () => {
  const { url } = useRouteMatch();
  return (
    <AppContainer headerTitle={"Parent Live Classes"}>
      <BreadcrumbsComponent url={url} />
      <ParentLiveClassTable />
    </AppContainer>
  );
};

export default ParentLiveClassPage;

import { Delete } from "@mui/icons-material";
import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import GeneralDialogComponent from "../../../../components/GeneralDialogComponent";
import { Group, GroupUser } from "../../../../interfaces";
import GroupService from "../../../../services/group.service";

const ViewUserInGroup = (props: Props) => {
  const STRINGS = {
    TITLE: "View Users",
    CANCEL: "Cancel",
    DELETED_USER:
      "Deleted User, kindly refresh the page to retrieve the latest available slots",
    DELETED_USER_FAILED: "Failed to Delete User",
    NO_USER: "No User Added",
  };

  const { group, handleClose } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [users, setUsers] = useState<GroupUser[]>([]);

  const handleRemove = (uid: string) => {
    // find the user's index to remove from 'users'
    const userIndex = users.findIndex((user) => user.userUid === uid);
    if (userIndex === -1) return;

    try {
      GroupService.deleteUserFromGroup(group.groupId, users[userIndex].userUid);
      enqueueSnackbar(STRINGS.DELETED_USER, { variant: "success" });
    } catch (e) {
      enqueueSnackbar(STRINGS.DELETED_USER_FAILED, { variant: "error" });
    }
    // remove from results' users
    const newUsers = [
      ...users.slice(0, userIndex),
      ...users.slice(userIndex + 1),
    ];
    setUsers(newUsers);
  };

  const call = async () => {
    const users = await GroupService.readAllGroupUser(group.groupId);
    setUsers(users);
  };

  useEffect(() => {
    call();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GeneralDialogComponent
      open
      onClose={handleClose}
      title={STRINGS.TITLE}
      actions={
        <Button variant="contained" fullWidth onClick={handleClose}>
          {STRINGS.CANCEL}
        </Button>
      }
    >
      <List dense>
        {users.length > 0 ? (
          users.map(({ userUid, userName, userEmail }) => (
            <ListItem
              key={userUid}
              secondaryAction={
                <IconButton edge="end" onClick={() => handleRemove(userUid)}>
                  <Delete />
                </IconButton>
              }
            >
              <ListItemText primary={userName} secondary={userEmail} />
            </ListItem>
          ))
        ) : (
          <Typography variant="body1">{STRINGS.NO_USER}</Typography>
        )}
      </List>
    </GeneralDialogComponent>
  );
};

interface Props {
  group: Group;
  handleClose: () => void;
}

export default ViewUserInGroup;

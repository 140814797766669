import { Add } from '@mui/icons-material'
import { Box, Button } from '@mui/material'
import React from 'react'

const AddStudentBox = (props: Props) => {
  const { STRINGS, handleOpen } = props;

  return (
    <Box width="100%" mt="1rem" display="flex" justifyContent="flex-end">
      <Button variant="contained" onClick={handleOpen} startIcon={<Add />}>
        {STRINGS.ADD_USER}
      </Button>
    </Box>
  )
}

interface Props {
  STRINGS: any
  handleOpen: () => void
}

export default AddStudentBox

import React, { SyntheticEvent, useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import GeneralDialogComponent from "../../../../components/GeneralDialogComponent";
import { BoxRow } from "../../../../utils/styledComponents";
import { Course, User, UserProductCourse } from "../../../../interfaces";
import { CourseService } from "../../../../services/course.service";
import GeneralAvatarComponent from "../../../../components/GeneralAvatarComponent";

export default function AddUserToCourseDialog(props: Props) {
  const STRINGS = {
    COURSE: "Course",
    ADD_USER_TO_COURSE: "Add User to Course",
    CANCEL: "Cancel",
    ADD: "Add",
    SUCCESS_ADD_USER_TO_COURSE: "Successfully Added User to Course",
    FAILED_ADD_USER_TO_COURSE: "Failed to Add User to Course",
    PLEASE_SELECT_COURSE: "Please Select a Course",
  };

  const { handleClose, user } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [courses, setCourses] = useState<Course[]>([]);
  const [selectedCourse, setSelectedCourse] = useState<Course>();

  // methods
  const handleAutoCompleteChange = (
    e: SyntheticEvent<Element, Event>,
    newValue: Course | null
  ) => {
    e.preventDefault();
    if (newValue) setSelectedCourse(newValue);
  };
  const handleAddUserToClass = async () => {
    if (!selectedCourse)
      return enqueueSnackbar(STRINGS.PLEASE_SELECT_COURSE, {
        variant: "warning",
      });

    const { courseTitle, courseId, courseImage, courseDesc, courseType } =
      selectedCourse;
    const massagedData: UserProductCourse = {
      courseTitle,
      courseId,
      courseImage,
      courseDesc,
      courseType,
    };
    try {
      await CourseService.addUserToCourse(massagedData, user.userUid);
      enqueueSnackbar(STRINGS.SUCCESS_ADD_USER_TO_COURSE, {
        variant: "success",
      });
      handleClose();
    } catch (error: any) {
      enqueueSnackbar(STRINGS.FAILED_ADD_USER_TO_COURSE, { variant: "error" });
    }
  };

  useEffect(() => {
    (async () => {
      const userCourses = await CourseService.readOneUserAllProductCourse(
        user.userUid
      );

      const courses = (await CourseService.readAllCourse()).filter(
        (course) =>
          userCourses.findIndex(
            (userCourse) => userCourse.courseId === course.courseId
          ) === -1
      );
      setCourses(courses);

      console.log("course:", courses);
    })();
  }, [user]);

  return (
    <GeneralDialogComponent
      open
      onClose={handleClose}
      title={STRINGS.ADD_USER_TO_COURSE}
      actions={
        <>
          <Button
            variant="contained"
            color="info"
            onClick={handleClose}
            sx={{ mr: "1rem" }}
          >
            {STRINGS.CANCEL}
          </Button>
          <Button
            variant="contained"
            onClick={handleAddUserToClass}
            disabled={!selectedCourse}
          >
            {STRINGS.ADD}
          </Button>
        </>
      }
    >
      <Autocomplete
        fullWidth
        options={courses}
        getOptionLabel={(option) => option.courseTitle}
        onChange={(e, newValue) => handleAutoCompleteChange(e, newValue)}
        renderOption={(props, option) => (
          <Box component="li" key={option.courseId} {...props}>
            <BoxRow p="0.5rem" display="flex" alignItems="center">
              <GeneralAvatarComponent src={option.courseImage} />
              <Typography>{option.courseTitle}</Typography>
            </BoxRow>
          </Box>
        )}
        renderInput={(params) => (
          <TextField margin="normal" label={STRINGS.COURSE} {...params} />
        )}
      />
    </GeneralDialogComponent>
  );
}

interface Props {
  user: User;
  handleClose: () => void;
}

import React, { useEffect, useState } from 'react'
import { Button, Divider, Grid, Stack, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { SideDetailsBox } from '../../../../utils/styledComponents'
import BackdropComponent from '../../../../components/BackDropComponent'

const TeacherActionButtons = (props: Props) => {
	const STRINGS = {
		ACTION_BUTTONS: 'Action Buttons',
		UPDATE_TEACHER: 'update teacher',
		ADD: 'Add',
		CANCEL: 'cancel',
		INACTIVATE_USER: 'Inactivate User',
		ACTIVATE_USER: 'Activate Teacher',
		SEND_WELCOME_USER: 'Send Welcome User Email',
		SEND_WELCOME_USER_FORCE: 'Force Send Welcome User Email',
		SEND_RESET_PASSWORD_LINK: 'Send Reset Password Link',
		SEND_RESET_PASSWORD_LINK_FORCE: 'Force Reset Password Link',
		SUCCESS_SEND_RESET_PASSWORD_LINK: 'Succesfully Sent Reset Password Link',
		FAILED_SEND_RESET_PASSWORD_LINK: 'Failed to Send Reset Password Link',
		LIVE_CLASSES: 'Live Classes',
		ADD_USER_TO_LIVE_CLASS: 'Add User to Live Class',
		VIEW_LIVE_CLASS: 'View User Live Classes',
		COURSES: 'Courses',
		ADD_USER_TO_COURSE: 'Add User to Course',
		VIEW_COURSE: 'View User Courses',
		GROUPS: 'Groups',
		ADD_USER_TO_GROUP: 'Add User To Group',
		VIEW_GROUP: 'View User Groups',
		MSG_DUPLICATE_PWD_SEND: 'User already has a reset password link sent',
		MSG_DUPLICATE_WLC_SEND: 'User already has a welcome email sent',
		SUMMARY_TITLE: 'Summary',
	}

	const { uid }: any = useParams()
	const { handleUpdate, disabled } = props
	const { enqueueSnackbar } = useSnackbar()
	const [showBackdrop, setShowBackDrop] = useState<boolean>(false)

	async function reloadPage() {
		if (window) {
			await window.location.reload()
		}
	}

	useEffect(() => {}, [])

	return (
		<Grid item xs={12} lg={3}>
			<Stack divider={<Divider />}>
				<SideDetailsBox>
					<Stack spacing={3}>
						<Button
							disabled={disabled}
							variant='contained'
							color={'secondary'}
							fullWidth
							onClick={handleUpdate}>
							{STRINGS.UPDATE_TEACHER}
						</Button>
					</Stack>
				</SideDetailsBox>
			</Stack>

			{showBackdrop && <BackdropComponent />}
		</Grid>
	)
}

interface Props {
	handleUpdate: () => void
	disabled: boolean
}

export default TeacherActionButtons

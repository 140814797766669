import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Tabs,
  Tab,
  Badge,
  Stack,
  IconButton,
  Typography,
} from "@mui/material";
import {
  Add,
  Check,
  Delete,
  Edit,
  Pending,
  RemoveRedEye,
} from "@mui/icons-material";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { BoxRow } from "../../../utils/styledComponents";
import { LiveClass } from "../../../interfaces";
import LiveClassService from "../../../services/liveclass.service";
import moment from "moment";
import {
  DataGrid,
  GridColDef,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import { TabPanel } from "../../../components/TabPanel";
import { LIVE_CLASS_STATUS } from "../../../constants/LIVE_CLASS_STATUS";
import { useSnackbar } from "notistack";

export default function LiveClassTable() {
  const STRINGS = {
    TITLE: "Live Classes",
    ADD_CLASS: "Add Class",
    UPCOMING_CLASS: "Upcoming Classes",
    COMPLETED_CLASS: "Completed Classes",
    UNPUBLISHED_CLASS: "Unpublished Classes",
    VIEW_SESSIONS: "View Sessions",
    PUBLISH_CLASS: "Publish Class",
    SUCCESS_PUBLISH_CLASS: "Successfully Published Class",
    FAILED_PUBLISH_CLASS: "Failed to Publish Class",
  };

  const { url } = useRouteMatch();
  const [unpublishedClasses, setUnpublishedClasses] = useState<LiveClass[]>([]);
  const [upcomingClasses, setUpcomingClasses] = useState<LiveClass[]>([]);
  const [completedClasses, setCompletedClasses] = useState<LiveClass[]>([]);

  const history = useHistory();
  const [tabIndex, setValue] = useState(0);
  const { enqueueSnackbar } = useSnackbar();

  const columns: GridColDef<LiveClass>[] = useMemo(
    () => [
      {
        field: "lcTitle",
        headerName: "Title",
        width: 400,
        renderCell: (params) => {
          return params.row.lcStatus === LIVE_CLASS_STATUS.PUBLISHED ? (
            <Typography
              component={Link}
              color="info.main"
              to={`/live-class/${params.row.lcId}`}
            >
              {params.value}
            </Typography>
          ) : (
            <Typography>{params.value}</Typography>
          );
        },
      },
      {
        field: "lcStartDateTs",
        headerName: "Start Date",
        width: 300,
        valueFormatter: (params: GridValueFormatterParams<string>) =>
          moment(params.value).format("DD MMM y, HH:mm:ss A"),
      },
      {
        field: "teacherName",
        headerName: "Teacher",
        width: 300,
      },
      {
        field: "actions",
        headerName: "",
        width: 400,
        renderCell: (params) => {
          const republishClass = async () => {
            const publishedClass: LiveClass = {
              ...params.row,
              lcStatus: LIVE_CLASS_STATUS.PUBLISHED,
            };
            try {
              console.log(publishedClass);
              await LiveClassService.republishLiveClass(publishedClass);
              enqueueSnackbar(STRINGS.SUCCESS_PUBLISH_CLASS, {
                variant: "success",
              });
              history.go(0);
            } catch (error: any) {
              enqueueSnackbar(error.message || STRINGS.FAILED_PUBLISH_CLASS, {
                variant: "error",
              });
              console.error(error);
            }
          };
          return params.row.lcStatus === LIVE_CLASS_STATUS.PUBLISHED ? (
            <Stack direction="row" spacing={1} alignItems="center">
              <Link to={`/live-class/${params.row.lcId}`}>
                <IconButton>
                  <RemoveRedEye />
                </IconButton>
              </Link>
              <Link to={`/live-class/edit-class/${params.row.lcId}`}>
                <IconButton>
                  <Edit />
                </IconButton>
              </Link>
              <Link to={`/live-class/${params.row.lcId}/session`}>
                <Button variant="outlined" size="small">
                  {STRINGS.VIEW_SESSIONS}
                </Button>
              </Link>
            </Stack>
          ) : (
            <Stack direction="row" spacing={1} alignItems="center">
              <Button
                variant="outlined"
                color="info"
                size="small"
                onClick={republishClass}
              >
                {STRINGS.PUBLISH_CLASS}
              </Button>
            </Stack>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [upcomingClasses, completedClasses, unpublishedClasses]
  );

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const call = async () => {
    const liveClasses = await LiveClassService.readLiveClasses();
    const unpublishedClasses = await LiveClassService.readUnpublishedClasses();
    const upcomingClasses: LiveClass[] = [];
    const completedClasses: LiveClass[] = [];
    liveClasses.forEach((liveClass) => {
      if (moment().valueOf() < liveClass.lcEndDateTs)
        return upcomingClasses.push(liveClass);
      else return completedClasses.push(liveClass);
    });
    setUnpublishedClasses(unpublishedClasses);
    setUpcomingClasses(upcomingClasses);
    setCompletedClasses(completedClasses);
  };

  useEffect(() => {
    call();
  }, []);

  return (
    <Box sx={{ mt: "1rem" }}>
      <BoxRow sx={{ justifyContent: "flex-end" }}>
        {/* 
        <Typography variant={"h5"} gutterBottom>
          {STRINGS.TITLE}
        </Typography>
        */}
        <Button
          variant="contained"
          onClick={() => history.push(`${url}/create-class`)}
          startIcon={<Add />}
        >
          {STRINGS.ADD_CLASS}
        </Button>
      </BoxRow>

      <Box sx={{ borderBottom: 1, borderColor: "divider", m: "1rem" }}>
        <Tabs
          value={tabIndex}
          onChange={handleChange}
          aria-label="classes tabs"
        >
          <Tab
            icon={
              <Badge badgeContent={upcomingClasses.length} color="warning">
                <Pending />
              </Badge>
            }
            iconPosition="end"
            label={STRINGS.UPCOMING_CLASS}
          />
          <Tab
            icon={
              <Badge badgeContent={completedClasses.length} color="info">
                <Check />
              </Badge>
            }
            iconPosition="end"
            label={STRINGS.COMPLETED_CLASS}
          />
          <Tab
            icon={
              <Badge badgeContent={unpublishedClasses.length} color="error">
                <Delete />
              </Badge>
            }
            iconPosition="end"
            label={STRINGS.UNPUBLISHED_CLASS}
          />
        </Tabs>
      </Box>
      <TabPanel value={tabIndex} index={0}>
        <Box sx={{ height: 500, backgroundColor: "white" }}>
          <DataGrid
            rows={upcomingClasses}
            columns={columns}
            pageSize={50}
            rowsPerPageOptions={[50, 100, 250]}
            getRowId={(row) => row.lcId}
            onRowDoubleClick={(params) => {
              history.push(`/live-class/${params.id}`);
            }}
          />
        </Box>
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <Box sx={{ height: 500, backgroundColor: "white" }}>
          <DataGrid
            rows={completedClasses}
            columns={columns}
            pageSize={50}
            rowsPerPageOptions={[50, 100, 250]}
            getRowId={(row) => row.lcId}
            onRowDoubleClick={(params) => {
              history.push(`/live-class/${params.id}`);
            }}
          />
        </Box>
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        <Box sx={{ height: 500, backgroundColor: "white" }}>
          <DataGrid
            rows={unpublishedClasses}
            columns={columns}
            pageSize={50}
            rowsPerPageOptions={[50, 100, 250]}
            getRowId={(row) => row.lcId}
          />
        </Box>
      </TabPanel>
      {/* <GeneralTableComponent onRefresh={call} loading={loading}>
        <Table>
          <TableHead>
            <TableRow>
              {cells.map((cell) => (
                <TableCell align={cell.align} key={cell.name}>
                  {cell.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {liveClasses.map((row) => (
              <TableRow key={row.lcId}>
                <TableCell>
                  <Box display="flex">
                    <GeneralAvatarComponent src={row.lcImage} />
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                    >
                      <Typography
                        variant="subtitle2"
                        component={Link}
                        to={`${url}/${row.lcId}`}
                        sx={{ textDecoration: "none" }}
                        color="primary.main"
                      >
                        {row.lcTitle} (
                        {moment(row.lcStartDateTs).format(
                          "DD MMM y, HH:mm:ss A"
                        )}
                        {" - "}
                        {moment(row.lcEndDateTs).format("DD MMM y, HH:mm:ss A")}
                        )
                      </Typography>
                      <Typography variant="caption" color="textSecondary">
                        {row.lcDesc}
                      </Typography>
                      <Typography variant="caption" color="textSecondary">
                        {row.lcId}
                      </Typography>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell align="center">
                  <Link to={`${path}/${row.lcId}/session`}>
                    <IconButton>
                      <RemoveRedEyeOutlined />
                    </IconButton>
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </GeneralTableComponent> */}
    </Box>
  );
}

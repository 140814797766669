import React, { ChangeEvent, HTMLInputTypeAttribute, useState } from "react";
import { Button, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import GeneralDialogComponent from "../../../components/GeneralDialogComponent";
import {
  MakeTextField,
  MakeTextFields,
  AddUser,
  AddUserErrors,
  AddUserKeys,
} from "../../../interfaces";
import UserService from "../../../services/user.service";
import { REGEX } from "../../../constants/regex.constant";
import { useHistory } from "react-router-dom";
import { setTimeout } from "timers";
import { checkTextFieldError } from '../../../utils/checkTextField'
import cleanValues from '../../../utils/cleanValues'

const AddUserDialog = (props: Props) => {
  const { open, handleClose, STRINGS } = props
	const history = useHistory()
	const { enqueueSnackbar } = useSnackbar()
	const [form, setForm] = useState<AddUser>({} as AddUser)
	const [errors, setErrors] = useState<AddUserErrors>({} as AddUserErrors)
	const [disableSubmit, setDisableSubmit] = useState(true)

	const makeTextField = (
		label: string,
		type: HTMLInputTypeAttribute | 'textarea' | 'autocomplete',
		key: AddUserKeys,
		pattern: RegExp,
		required: boolean = false,
		disabled: boolean = false
	): MakeTextField<AddUser> => ({
		label,
		type: type === 'autocomplete' ? 'autocomplete' : type,
		key,
		pattern,
		required,
		disabled,
		value: form[key],
		error: errors[key],
		helperText: errors[key]
			? required && !form[key]
				? 'This field is required'
				: `Invalid ${label} format`
			: '',
		name: key,
		multiline: type === 'textarea',
		rows: 4,
	})

	const textFields: MakeTextFields<AddUser> = {
		full_name: makeTextField('Full Name', 'text', 'full_name', REGEX.NAME, true),
		email: makeTextField('Email Address', 'email', 'email', REGEX.EMAIL, true),
	}

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		e.preventDefault()
		setDisableSubmit(false)
		const { required, value } = e.target
		const name = e.target.name as AddUserKeys
		setForm({ ...form, [name]: value })

		// this is to check if the text field has error
		// 1. if field is '', return TRUE if field is required
		// 2. if field has value, return TRUE if pattern is wrong
		const pattern = textFields[name].pattern
		const error: boolean = !value ? required : !new RegExp(pattern)?.test(value)
		setErrors({ ...errors, [name]: error })
	}

	const handleSubmit = async () => {
		setDisableSubmit(true)

		setForm(cleanValues({ ...form }))

		// validate form
		const hasError = Object.values(textFields).some((textField) => {
			const { name, label } = textField
			const error: boolean = checkTextFieldError(textField, form)
			if (error) {
				setDisableSubmit(false)
				setErrors({ ...errors, [name]: error })
				enqueueSnackbar(`Invalid ${label} format`, { variant: 'error' })
			}
			return error
		})

		if (hasError) return

		try {
			const { uid }: any = await UserService.createUser(form)

			setTimeout(async () => {
				handleClose()
				enqueueSnackbar(STRINGS.SUCCESS_ADD_USER, { variant: 'success' })
				history.push(`/user/${uid}`)
			}, 2000)
		} catch (error: any) {
			setDisableSubmit(false)
			console.error('error:', error)
			enqueueSnackbar(error.message, { variant: 'error' })
		}
	}

	return (
		<GeneralDialogComponent
			open={open}
			onClose={() => {
				handleClose()
				setErrors({} as AddUserErrors)
			}}
			title={STRINGS.ADD_USER}
			actions={
				<Button
					variant='contained'
					onClick={handleSubmit}
					// find if there is any errors
					disabled={Object.values(errors).find((error) => error === true) || disableSubmit}>
					{STRINGS.SUBMIT}
				</Button>
			}>
			{Object.values(textFields).map((textField) => (
				<TextField
					fullWidth
					variant='outlined'
					margin='normal'
					onChange={handleChange}
					{...textField}
					InputLabelProps={{ shrink: true }}
				/>
			))}
		</GeneralDialogComponent>
	)
}

interface Props {
  open: boolean;
  handleClose: () => void;
  STRINGS: any;
}

export default AddUserDialog;

//Standard Style Constants
import {grey} from "@mui/material/colors";

export const PaperRadius = '1rem';
export const PaperPadding = '1rem';
export const PaperMargin = '1rem';


//Please add all group style below and constants on top
// Group Styles
export const PaperStyles = {
  mt: PaperMargin, p: PaperPadding, borderRadius: PaperRadius
}
export const SiteContainerStyle = {
  backgroundColor: grey[100]
}

import { Stack } from "@mui/material";
import React from "react";
import AppContainer from "../../containers/AppContainer";
import QuickLinks from "./components/QuickLinks";
import Statistics from "./components/Statistics";

const Home = () => {
  return (
    <AppContainer headerTitle={"Dashboard"}>
      <Stack spacing={5}>
        <Statistics />
        <QuickLinks />
      </Stack>
      {/* <Paper sx={{padding: '1rem'}}>
        <b>Instructions</b>
        <div>If the user groups are already ready then below are the step.</div>
        <br/>
        <div>1. Create a new user or update a user</div>
        <div>2. Assign group to the user</div>
        <div>3. Create/add all the users based on the group's total slots</div>
        <div>4. If a group is full then only proceed to add the group to the live class.</div>
        <div>5. Go to live class edit or create a new live class</div>
        <div>6. Add the group and fill the fields then update the class.</div>
        <div>7. Then on the same page, add a new session via a popup to add the first session.</div>
        <br/><br/><br/>
        <b>Note:</b>
        <div>Make sure you have already created the group.</div>
        <div>When you add the user to a group make sure you have added all the users.</div>
        <div>Adding users to a live class should only be done if all users are added to the respective group.</div>
        <div>otherwise, automation will fail.</div>
      </Paper> */}
    </AppContainer>
  );
};

export default Home;

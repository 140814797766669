import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import GeneralDialogComponent from "../../../../components/GeneralDialogComponent";
import { SessionUser } from "../../../../interfaces";
import SessionService from "../../../../services/session.service";
import SessionUserTable from "./SessionUserTable";

interface Props {
  sessionId: string;
  handleClose: () => void;
}
const ViewUsersDialog = (props: Props) => {
  const { sessionId, handleClose } = props;
  const STRINGS = {
    USERS: "Users",
    DONE: "Done",
  };

  const [sessionUsers, setSessionUsers] = useState<SessionUser[]>([]);
  const [loading, setLoading] = useState(false);
  const call = async () => {
    setLoading(true);
    const sessionUsers = await SessionService.readAllSessionUsers(sessionId);
    setSessionUsers(sessionUsers);
    setLoading(false);
  };
  useEffect(() => {
    call();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GeneralDialogComponent
      open
      title={STRINGS.USERS}
      onClose={handleClose}
      actions={
        <>
          <Button onClick={handleClose}>{STRINGS.DONE}</Button>
        </>
      }
    >
      <SessionUserTable
        sessionId={sessionId}
        sessionUsers={sessionUsers}
        loading={loading}
        call={call}
      />
    </GeneralDialogComponent>
  );
};

export default ViewUsersDialog;

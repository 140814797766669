import React, { useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { Grid } from "@mui/material";
import BreadcrumbsComponent from "../../../components/Breadcrumbs";
import AppContainer from "../../../containers/AppContainer";
import StudentActionButtons from "./components/UserActionButtons";
import StudentDetailsForm from "./components/UserDetailsForm";
import VerticalDivider from "../../../components/VerticalDivider";

const StudentDetailsPage = () => {
  const { url } = useRouteMatch();
  const [updating, setUpdating] = useState(false);

  const handleUpdate = () => {
    setUpdating(!updating);
  };

  return (
    <AppContainer headerTitle="Create Student">
      <BreadcrumbsComponent url={url} />
      <Grid container>
        <StudentDetailsForm updating={updating} />
        <VerticalDivider />
        <StudentActionButtons updating={updating} handleUpdate={handleUpdate} />
      </Grid>
    </AppContainer>
  );
};

export default StudentDetailsPage;
